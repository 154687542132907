import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ThemeContext } from 'shared/providers';

import { useDeviceDetector, useNavigate } from 'shared/hooks';
import { DetectorHelper, LanguageHelper, ParseHelper } from 'shared/helpers';

import { DropdownTriangle } from '../DropdownTriangle/DropdownTriangle';

import enIcon from 'assets/images/languages/en.svg';
import deIcon from 'assets/images/languages/de.svg';
import frIcon from 'assets/images/languages/fr.svg';
import itIcon from 'assets/images/languages/it.svg';

import styles from './LanguageSwitcher.module.scss';
import { Icon } from '../Icon/Icon';
import { IconsId } from 'shared/types/icons';

const icons: { [key in string]: string } = {
    en: enIcon,
    de: deIcon,
    fr: frIcon,
    it: itIcon,
};

type LanguageSwitcherProps = {
    className?: string;
    fullNames?: boolean;
    dropdownIcon?: IconsId;
    showFlags?: boolean;
};

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
    className,
    fullNames = false,
    dropdownIcon = null,
    showFlags = true,
}) => {
    const { themeSettings } = useContext(ThemeContext);
    const [isOpen, changeState] = useState(false);
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { device } = useDeviceDetector();

    const selectLang = (lang: string) => {
        const url = LanguageHelper.change(lang);
        navigate(url);
        changeState(false);
    };

    const onOpen = () => {
        changeState(true);
    };

    if (DetectorHelper.isPreview() || i18n.languages.length <= 1) return null;

    return (
        <div
            className={clsx(styles.root, className, isOpen && 'switcher-open', isOpen && styles['is-open'])}
            {...(device !== 'desktop' ? { onClick: changeState.bind(null, !isOpen) } : { onMouseEnter: onOpen })}
            onMouseLeave={changeState.bind(null, false)}
            style={ParseHelper.prepareStyles({
                color: themeSettings.colors?.text.heroAndNavigation,
                fontFamily: themeSettings.typography?.paragraphBody.fontFamily,
                fontSize: themeSettings.typography?.paragraphBody.fontSize,
            })}
        >
            <div className={clsx(styles.current, 'switcher-wrapper')}>
                <span className="switcher-label">{i18n.language}</span>
                {dropdownIcon ? (
                    <Icon className={clsx(styles.customIcon, 'switcher-icon')} name={dropdownIcon} />
                ) : (
                    <div className={clsx(styles.icon, 'switcher-arrow')} />
                )}
            </div>
            <ul
                className={clsx(styles.list, 'language-list')}
                {...(themeSettings.colors && {
                    style: {
                        backgroundColor: themeSettings.colors.background.primary,
                    },
                })}
            >
                <DropdownTriangle
                    className={styles.triangle}
                    {...(themeSettings.colors && {
                        bgColor: themeSettings.colors.background.primary,
                    })}
                />

                {i18n.languages.map(
                    (i: string) =>
                        i !== i18n.language && (
                            <li key={i} onClick={selectLang.bind(null, i)}>
                                {showFlags && <img src={icons[i]} alt="icon" />}
                                {fullNames ? LanguageHelper.getFullName(i) : i}
                            </li>
                        ),
                )}
            </ul>
        </div>
    );
};
