const fromGMTtoUTC = (data:Date):Date => new Date(data.getTime() + data.getTimezoneOffset() * 60000);

const prepareDateValue = (value:number) => {
    return value <= 9 ? `0${value}` : value;
}

const getDate = (date:Date):string => {
    const day = prepareDateValue(date.getDate());
    const month = prepareDateValue(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

export const DateHelper = {
    fromGMTtoUTC,
    getDate
}