import { MediaSizes } from 'shared/hooks';

const isPreview = () => {
    if (process.env.NODE_ENV === 'production')
        return window.location !== window.parent.location || window.location.hostname.includes('preview.');
    return false;
};

const isMobile = () => {
    return MediaSizes.tablet > window.innerWidth;
};

const isExternalLink = (url: string) => url.includes('http');

export const DetectorHelper = {
    isMobile,
    isPreview,
    isExternalLink,
};
