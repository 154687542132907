import { http } from 'api/http';

import { GAResponseType, ResponseDataType, StripeSettingsDataType } from 'shared/types';

const getStripeAccountData = async (): Promise<ResponseDataType<StripeSettingsDataType>> => {
    return http.get<StripeSettingsDataType>(`/payments/stripe/account/credentials/`);
};

const getMailChimpIntegration = async (): Promise<ResponseDataType<StripeSettingsDataType>> => {
    return http.get<StripeSettingsDataType>(`/third_party/MAIL_CHIMP`);
};

const getGoogleAnalyticsID = async (host: string): Promise<GAResponseType> => {
    const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/v1/third_party/GOOGLE_ANALYTICS/`, {
        method: 'GET',
        headers: {
            'X-CUSTOM-DOMAIN': host,
            Accept: 'application/json',
        },
    }).catch(() => {
        return null;
    });
    return response?.json();
    // return await http.get<GAResponseType>('/third_party/GOOGLE_ANALYTICS/')
};

export const createSettingService = () => ({
    getStripeAccountData,
    getMailChimpIntegration,
    getGoogleAnalyticsID,
});
