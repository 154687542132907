import React, { useRef, useState } from 'react';
import { Container } from 'shared/components/grid';
import { Icon, Typography } from 'shared/components/ui';
import { AuctionItemType } from 'shared/types';
import Slider from 'react-slick';
import { useDeviceDetector } from 'shared/hooks';
import styles from './EstateAuctionsSlider.module.scss';
import { EstateAuctionItem } from 'shared/components/themes/Estate/_components/EsateAuctionItem/EstateAuctionItem';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type EstateAuctionsSliderProps = {
    data: AuctionItemType[] | null;
};

export const EstateAuctionsSlider: React.FC<EstateAuctionsSliderProps> = ({ data }) => {
    const slider: any = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const { t } = useTranslation();
    const {device} = useDeviceDetector();
    // If there are no auctions to show, return nothing
    if (!data) return null;

    const next = () => {
        if (activeSlide !== data.length - 3) slider.current.slickNext();
    };
    const prev = () => {
        if (activeSlide !== 0) slider.current.slickPrev();
    };

    return (
        <section className={styles.root}>
            <Container>
                <div className={styles.header}>
                    <Typography.Title htmlElement="h2" className={styles.title}>
                        {t('other-auctions')}
                    </Typography.Title>
                    {data.length > 3 && device !== 'mobile' && (
                        <div className={styles.arrows}>
                            <Icon
                                name="arrow-left"
                                onClick={prev}
                                className={clsx(styles.sliderBtn, activeSlide === 0 && styles.inactive)}
                            />
                            <Icon
                                name="arrow-right"
                                onClick={next}
                                className={clsx(styles.sliderBtn, activeSlide === data.length - 3 && styles.inactive)}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.sliderBox}>
                    <Slider
                        className={styles.slider}
                        ref={slider}
                        arrows={false}
                        slidesToShow={3}
                        slidesToScroll={1}
                        infinite={false}
                        responsive={[
                            {
                                breakpoint: 799, //width from css breakpoints
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    dots: true
                                }
                            },
                        ]}
                        beforeChange={(prev: any, next: any) => setActiveSlide(next)}
                    >
                        {data.map(auction => (
                            <EstateAuctionItem key={auction.id} data={auction} />
                        ))}
                    </Slider>
                    {
                        data.length > 3 && device === 'mobile' && (
                            <>
                                <Icon
                                    name="arrow-left"
                                    onClick={prev}
                                    className={clsx(styles.sliderBtn, activeSlide === 0 && styles.inactive)}
                                />
                                <Icon
                                    name="arrow-right"
                                    onClick={next}
                                    className={clsx(styles.sliderBtn, activeSlide === data.length - 1 && styles.inactive)}
                                />
                            </>
                        )
                    }
                </div>
            </Container>
        </section>
    );
};
