import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';

import { CustomizationHomeUspItemType, CustomizationImageType } from 'shared/types';
import { ParseHelper } from 'shared/helpers';

import { Icon, Typography } from '../../ui';

import styles from './USPSection.module.scss';
import { ThemeContext } from 'shared/providers';
import { IconsId } from 'shared/types/icons';

type IntroductionItemProps = {
    data?: CustomizationHomeUspItemType;
    defaultData: CustomizationHomeUspItemType;
    index: number;
};

const USPSectionItem: React.FC<IntroductionItemProps> = ({ data, defaultData, index }) => {
    const [image, setImage] = useState('');
    const { themeSettings } = useContext(ThemeContext);

    useEffect(() => {
        (async () => {
            if (data && data.icon) setImage(await ParseHelper.perpareImageData(data.icon));
        })();
    }, [data]);

    return (
        <div className={clsx(styles.item, 'usp-item')}>
            {themeSettings.template !== 'minimal' && (!data || (data && !data.icon)) && (
                <Icon className={styles.icon} name={(defaultData.icon as CustomizationImageType).src as IconsId} />
            )}
            {themeSettings.template !== 'minimal' && data && data.icon && <img src={image} alt="" />}

            {themeSettings.template === 'minimal' && <div className="usp-number">{`#${index}`}</div>}

            <Typography.Title className="usp-title" htmlElement="h4">
                {data?.title || defaultData.title}
            </Typography.Title>
            <Typography.Text className="usp-text">{data?.description || defaultData.description}</Typography.Text>
        </div>
    );
};

export default USPSectionItem;
