import { AuctionItemType } from 'shared/types';

import image from 'assets/images/product-preview.jpg';
import estateImage from 'assets/images/estate-preview.jpg';

const now = new Date();
now.setHours(now.getHours() + 4);

const prepareAuction = (length: number = 1): AuctionItemType[] => {
    return Array.from(Array(length), (_, i) => ({
        id: i.toString(),
        product: {
            id: i.toString(),
            product_info: {
                en: {
                    SEO: {
                        page_body: '',
                        page_title: '',
                    },
                    USP: {
                        USP_1: '666',
                        USP_2: '777',
                        USP_3: '888',
                    },
                    description: [
                        {
                            description_body:
                                '{"blocks":[{"key":"m87a","text":"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odi","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":279,"style":"color-rgb(0,0,0)"},{"offset":0,"length":279,"style":"bgcolor-rgb(255,255,255)"},{"offset":0,"length":279,"style":"fontsize-14"},{"offset":0,"length":279,"style":"fontfamily-Open Sans\\", Arial, sans-serif"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                            description_type: 'Sed ut ',
                        },
                    ],
                    product_name: 'EN, Used since the 1500s',
                    product_catch_phrase: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                },
                fr: {
                    SEO: {
                        page_body: '',
                        page_title: '',
                    },
                    USP: {
                        USP_1: '666',
                        USP_2: '777',
                        USP_3: '888',
                    },
                    description: [
                        {
                            description_body:
                                '{"blocks":[{"key":"m87a","text":"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odi","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":279,"style":"color-rgb(0,0,0)"},{"offset":0,"length":279,"style":"bgcolor-rgb(255,255,255)"},{"offset":0,"length":279,"style":"fontsize-14"},{"offset":0,"length":279,"style":"fontfamily-Open Sans\\", Arial, sans-serif"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                            description_type: 'Sed ut ',
                        },
                    ],
                    product_name: 'FR, Used since the 1500s',
                    product_catch_phrase: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                },
                it: {
                    SEO: {
                        page_body: '',
                        page_title: '',
                    },
                    USP: {
                        USP_1: '666',
                        USP_2: '777',
                        USP_3: '888',
                    },
                    description: [
                        {
                            description_body:
                                '{"blocks":[{"key":"m87a","text":"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odi","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":279,"style":"color-rgb(0,0,0)"},{"offset":0,"length":279,"style":"bgcolor-rgb(255,255,255)"},{"offset":0,"length":279,"style":"fontsize-14"},{"offset":0,"length":279,"style":"fontfamily-Open Sans\\", Arial, sans-serif"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                            description_type: 'Sed ut ',
                        },
                    ],
                    product_name: 'IT, Used since the 1500s',
                    product_catch_phrase: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                },
                de: {
                    SEO: {
                        page_body: '',
                        page_title: '',
                    },
                    USP: {
                        USP_1: '666',
                        USP_2: '777',
                        USP_3: '888',
                    },
                    description: [
                        {
                            description_body:
                                '{"blocks":[{"key":"m87a","text":"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odi","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":279,"style":"color-rgb(0,0,0)"},{"offset":0,"length":279,"style":"bgcolor-rgb(255,255,255)"},{"offset":0,"length":279,"style":"fontsize-14"},{"offset":0,"length":279,"style":"fontfamily-Open Sans\\", Arial, sans-serif"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
                            description_type: 'Sed ut ',
                        },
                    ],
                    product_name: 'DE, Used since the 1500s',
                    product_catch_phrase: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                },
            },
            images: [
                {
                    product_id: '',
                    id: 'c587be92-0f03-4f87-8081-2ba9ed8ce737',
                    image: image,
                },
            ],
            published: true,
            name: 'Used since the 1500s',
            quantity: 120,
            uvp: '999.00',
            shipping_cost: '33.00',
            days_for_delivery: 11,
            company: 'f37bcc5c-a246-4ac7-8f8b-7535a8cd4cd1',
        },
        type: {
            value: 2,
            display_name: 'Buy now',
        },
        status: {
            value: 3,
            display_name: 'Active',
        },
        discount: {
            type: 2,
            value: '50.00',
        },
        is_archived: false,
        starts_at: '2021-03-26T08:40:17.194934Z',
        ends_at: '2022-03-26T08:40:17.194934Z',
        created_at: '2021-03-26T08:40:17.194934Z',
        updated_at: '2021-03-26T08:41:06.679478Z',
        uvp: '100.00',
        offer_minimum: null,
        offer_interval: null,
        minimum_product_pcs: null,
        minimum_order_price: null,
        minimal_winner_bid: null,
    }));
};

const estateAuctions = (length: number = 1) => {
    const auctions = prepareAuction(length);

    auctions.forEach(auction => {
        auction.product.uvp = '1460000';
        auction.product.name = '4.5 Bedroom House in Zürich';
        auction.product.living_space = 150;
        auction.product.address = 'Brunnacherstr';
        auction.product.number_of_rooms = 5.5;
        auction.product.property_type = 'Condominium';
        auction.product.images = [
            {
                product_id: '',
                id: 'c587be92-0f03-4f87-8081-2ba9ed8ce737',
                image: estateImage,
            },
        ];
    });

    return auctions;
};

export const AuctionMock = {
    prepareAuction,
    estateAuctions,
};
