import {AuctionProductDescriptionType} from "../../../../types";
import React, {useState} from "react";
import {TabsMenu} from "../../../ui";
import DescriptionTab from "./DescriptionTab";
import styles from "../AuctionDetail.module.scss";

type InfoBlockProps = {
    data?:AuctionProductDescriptionType[];
}

const InfoBlock:React.FC<InfoBlockProps> = ({ data }) => {
    const [activeTab, setActiveTab] = useState(0);

    if(!data) return null;

    return (
        <div className={styles.tabs}>
            <TabsMenu className={styles['tabs-menu']} data={data?.map(item => item.description_type)} active={activeTab} onChange={setActiveTab} />
            {data.map(({ description_body }, index) => activeTab === index && <DescriptionTab key={`InfoBlock-${index}`} data={description_body} />)}
        </div>
    )
}

export default InfoBlock;