import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'shared/providers';

import { ParseHelper } from 'shared/helpers';

import { AuctionItemType, CustomizationHomeType } from 'shared/types';

import {
    AuctionItem,
    MoreText,
    Preloader,
    Typography,
} from 'shared/components/ui';

import { Container } from 'shared/components/grid';

import styles from './AuctionsList.module.scss';

type AuctionsListProps = {
    data?: AuctionItemType[];
    loadMore?: () => void;
    canLoadMore: boolean;
};

export const AuctionsListSection: React.FC<AuctionsListProps> = ({
    loadMore,
    data,
    canLoadMore,
}) => {
    const { customization } = useContext(ThemeContext);
    const { i18n } = useTranslation();

    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationHomeType>(
                customization?.homepage?.settings,
                'productCards.sectionTitle',
                i18n.language
            ),
        [customization, i18n.language]
    );

    if (!data) return <Preloader.Content height={700} />;

    if (!data.length) return null;

    return (
        <Container
            className={clsx(styles.root, 'auction-list-section')}
            htmlElement="section"
        >
            <Typography.Title
                className={clsx(styles.mainTitle, 'mainTitle')}
                htmlElement="h2"
            >
                {content?.productCards?.sectionTitle || 'Our auctions'}
            </Typography.Title>
            <div className={clsx('grid', styles.grid)}>
                {data.map((item, index) => (
                    <AuctionItem
                        data={item}
                        key={`AuctionsListSection-item-${index}`}
                        buttonText={content?.productCards?.CTAText}
                    />
                ))}
            </div>
            {canLoadMore && (
                <div className={styles['more-wrapper']}>
                    <MoreText onClick={loadMore}>
                        {content?.productCards?.loadMoreLink || '+ LOAD MORE'}
                    </MoreText>
                </div>
            )}
        </Container>
    );
};
