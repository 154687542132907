import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'shared/components/grid';
import { BidsHistoryModal } from 'shared/components/modals';
import { Typography } from 'shared/components/ui';
import { useDeviceDetector, useModal } from 'shared/hooks';
import { AuctionHistoryResponseType } from 'shared/types';
import { EstateHistoryTable } from './EstateHistoryTable';
import { MobileHistoryTable } from './MobileHistoryTable';
import styles from './EstateBiddingHistory.module.scss';

type EstateBiddingHistoryProps = {
    data: AuctionHistoryResponseType;
};
export const EstateBiddingHistory: React.FC<EstateBiddingHistoryProps> = ({ data }) => {
    const { openModal } = useModal();
    const { t } = useTranslation();

    const { device } = useDeviceDetector();
    const isMobile = device === 'mobile';

    // If there is an error, user has no financial proof approved, so hide the component
    // Authorized users will have access to data, or they can obtain access to data using token and secret(password) - this is a use-case for the owner of the property
    if (!data || data?.error || data.count === 0) return null;

    return (
        <section className={styles.root}>
            {isMobile ? (
                <Container>
                    <Typography.Title htmlElement="h3" className={styles.title}>
                        {t('auction-history')}
                    </Typography.Title>
                    <MobileHistoryTable data={data.results.slice(0, 5)} />
                    <Typography.Title htmlElement="h3" className={styles.fullHistory}>
                        <div onClick={() => openModal('#bids-history')}>{t('full-history-button')}</div>
                    </Typography.Title>
                </Container>
            ) : (
                <Container>
                    <div className={styles.history}>
                        <div className={styles.historyHeader}>
                            <Typography.Title htmlElement="h3">{t('auction-history')}</Typography.Title>
                            <Typography.Title htmlElement="h3" className={styles.fullHistory}>
                                <div onClick={() => openModal('#bids-history')}>{t('full-history-button')}</div>
                            </Typography.Title>
                        </div>
                        <EstateHistoryTable bids={data.results.slice(0, 5)} />
                    </div>
                </Container>
            )}

            <BidsHistoryModal bids={data.results} />
        </section>
    );
};
