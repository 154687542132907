import styles from './EstateHistoryTable.module.scss';

export const UserName = ({ user, userCoverName }: { user: any; userCoverName: string }) => {
    if (!user) {
        return <>{userCoverName}</>;
    }

    return (
        <div className={styles.me}>
            <div>
                {user?.first_name.charAt(0)}
                {user?.last_name.charAt(0)}
            </div>
            Me
        </div>
    );
};
