const prepareHeaderLinks = (lang:string) => [
    {
        text: 'auctions',
        url: `/${lang}`
    },
    {
        text: 'missed-auctions',
        url: `/${lang}/missed-auctions`
    }
];

export const MenuMocks = {
    prepareHeaderLinks
}