import clsx from 'clsx';
import React from 'react';
import { Typography } from '../Typography/Typography';
import styles from './InvertedText.module.scss';

type InvertedTextProps = {
    text: string;
    className?: string;
};

export const InvertedText: React.FC<InvertedTextProps> = ({ text, className }) => {
    return (
        <div className={clsx(styles.textBox, className)}>
            <div className={styles.blackText}>
                <Typography.Title htmlElement="h1">{text}</Typography.Title>
            </div>
            <Typography.Title className={styles.whiteText} htmlElement="h1">
                {text}
            </Typography.Title>
        </div>
    );
};
