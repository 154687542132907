import { useEffect, useState } from 'react';

export const MediaSizes = {
    tablet: 800,
    desktop: 1100
}

type Devices = 'tablet' | 'mobile' | 'desktop';

export const useDeviceDetector = () => {
    const [device, setDevice] = useState<Devices>();

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        let size:Devices = 'mobile';

        if (windowWidth >= MediaSizes.tablet) {
            size = windowWidth >= MediaSizes.desktop ? 'desktop' : 'tablet';
        }

        setDevice(size);
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

	return { device };
};
