import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'shared/hooks';

import { ThemeContext } from 'shared/providers';

import { DetectorHelper, ParseHelper } from 'shared/helpers';

import { Container } from 'shared/components/grid';
import { MoreText, Typography } from 'shared/components/ui';

import { CustomizationHomeType } from 'shared/types';

import styles from './TextBlock.module.scss';

export const TextBlock = () => {
    const { customization } = useContext(ThemeContext);
    const { i18n } = useTranslation();
    const navigation = useNavigate();

    const [picture, setPicture] = useState<string | null>(null);
    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationHomeType>(
                customization?.homepage?.settings,
                'textBlock',
                i18n.language,
            ),
        [customization, i18n.language],
    );

    const onClickMore = () => {
        if (content?.textBlock.textLink.target) {
            const url = content.textBlock.textLink.target;

            const isExternal = DetectorHelper.isExternalLink(url);

            if (isExternal) {
                window.open(url, '_blank');
            } else {
                navigation(`/${i18n.language}${url}`);
            }
        }
    };

    useEffect(() => {
        (async () => {
            if (content) {
                const imageData = content.textBlock.picture;
                if (imageData) setPicture(await ParseHelper.perpareImageData(imageData));
            }
        })();
    }, [content]);

    if (content ? !content.textBlock.isActive : false) return null;

    return (
        <section className={clsx(styles['section'], 'text-block-section')}>
            <Container className={styles['text-wrapper']}>
                <div
                    className={clsx(styles.left, {
                        [styles.hasImg]: content?.textBlock?.renderMode === 'picture',
                    })}
                >
                    {content?.textBlock?.renderMode === 'picture' && <img src={picture || ''} alt="" />}

                    {(!content?.textBlock || content.textBlock.renderMode === 'title') && (
                        <Typography.Title htmlElement="h2">
                            {content?.textBlock?.title || 'Some short introduction about the company or campaign'}
                        </Typography.Title>
                    )}
                </div>

                <div className={clsx(styles['text-body'], 'text-block-text')}>
                    <Typography.Text>
                        {content?.textBlock?.description ||
                            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?'}
                    </Typography.Text>
                    {content?.textBlock?.textLink.isActive && (
                        <MoreText className={clsx(styles.more, 'read-more')} onClick={onClickMore}>
                            {content?.textBlock?.textLink.name || 'Read more'}
                        </MoreText>
                    )}
                </div>
            </Container>
        </section>
    );
};
