import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import styles from './TabsMenu.module.scss';

type TabsMenuProps = {
    className?:string;
    data:string[];
    active?:number;
    onChange?: (active:number) => void;
}

export const TabsMenu:React.FC<TabsMenuProps> = ({ data, onChange, className, active = 0}) => {
    const list = useRef<HTMLUListElement>(null);
    const [indicatorPosition, setIndicatorPosition] = useState(0);

    useEffect(() => {
        if(list.current) {
            const activeItem = list.current.getElementsByClassName(styles['is-active'])[0];
            const menuParams = list.current.getBoundingClientRect();
            if(activeItem) {
                const { width, left } = activeItem.getBoundingClientRect();
                const position = Math.round((width / 2) -3 + (left - menuParams.left));
                setIndicatorPosition(position);
            }
        }
    }, [active]);

    return (
        <div className={clsx(styles.root, className)}>
            <ul ref={list}>
                {data.map((item, index) => (
                    <li className={clsx({ [styles['is-active']]: active === index })} key={item} onClick={onChange?.bind(null, index)}>
                        {item}
                    </li>
                ))}
            </ul>
            {indicatorPosition !== 0 && (
                <div
                    className={clsx(styles.indicator)}
                    style={{ left: indicatorPosition }}
                />
            )}
        </div>
    )
}