import { getClient } from './client';

import { ResponseDataType } from 'shared/types';

const url = process.env.REACT_APP_BASE_API_URL;
const version = '/v1';

const get = <T>(path: string, ignoreVersion?: boolean): Promise<ResponseDataType<T>> =>
    getClient<T>(`${url}${ignoreVersion ? '' : version}${path}`, 'GET');

const post = <T>(path: string, body: object, ignoreVersion?: boolean): Promise<ResponseDataType<T>> =>
    getClient<T>(`${url}${ignoreVersion ? '' : version}${path}`, 'POST', body);

const put = <T>(path: string, body: object, ignoreVersion?: boolean): Promise<ResponseDataType<T>> =>
    getClient<T>(`${url}${ignoreVersion ? '' : version}${path}`, 'PUT', body);

const deleteMethod = <T>(path: string, body?: object, ignoreVersion?: boolean): Promise<ResponseDataType<T>> =>
    getClient<T>(`${url}${ignoreVersion ? '' : version}${path}`, 'DELETE', body);

export const http = {
    get,
    post,
    put,
    deleteMethod,
};
