import { AuthHelper } from "shared/helpers";

export type HeadersType = Record<string, string>;

const DEFAULT_HEADERS:HeadersType = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CUSTOM-DOMAIN': process.env.REACT_APP_HOST || window.location.hostname
  };
  
export const getHeaders = ():HeadersType => {
  const maybeToken = AuthHelper.getAuthToken();
  if (maybeToken) return {...DEFAULT_HEADERS, ...{ Authorization: `Token ${maybeToken}`}};
  return DEFAULT_HEADERS;
};

export const formatPrice = (price: any, locale = 'de-CH') => {
  const formattedPrice = parseInt(price).toLocaleString(locale, {
    style: 'currency',
    currency: 'CHF',
    minimumFractionDigits: 0,
  });
  return formattedPrice;
}