import React, { useRef, useState } from 'react';
import { Button, Icon, Modal, Typography } from 'shared/components/ui';
import { useModal } from 'shared/hooks';
import clsx from 'clsx';
import styles from './FinancialProof.module.scss';
import { api } from 'api';
import { useTranslation } from 'react-i18next';

export const FINANCIAL_PROOF_MODAL_NAME = '#financial-proof';

const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];
const ACCEPTED_FILES = '.jpg,.jpeg,.png,.pdf';

type FinancialProofModalType = {
    auctionId: string;
    onUpload: () => void;
};

export const FinancialProofModal: React.FC<FinancialProofModalType> = ({ auctionId, onUpload }) => {
    const { isOpen, closeModal } = useModal(FINANCIAL_PROOF_MODAL_NAME);
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [wrongFormat, setWrongFormat] = useState(false);
    const [sizeTooBig, setSizeTooBig] = useState(false);
    const [selectedFile, setFile] = useState<File | null>(null);
    const { t } = useTranslation();

    const handleClick = () => {
        if (selectedFile) return;
        if (hiddenFileInput.current) {
            hiddenFileInput.current.value = '';
            hiddenFileInput.current.click();
        }
    };
    const handleChange = (event: any) => {
        const sizeLimit = 15 * 1024 * 1025; // 15MB file size limit
        const fileToUpload = event.target.files[0];
        if (!fileToUpload) return;
        // Check if file size is bigger than the limit
        if (fileToUpload.size > sizeLimit) {
            setSizeTooBig(true);
            return;
        }
        // Check file type and if not allowed display an error
        if (!ALLOWED_TYPES.includes(fileToUpload.type)) {
            setWrongFormat(true);
            return;
        }
        setFile(fileToUpload);
        setWrongFormat(false);
        setSizeTooBig(false);
    };
    const handleCancel = () => {
        setFile(null);
    };
    const uploadFinancialProof = async () => {
        if (!selectedFile) return;

        const formData = new FormData();

        formData.append('file', selectedFile);
        formData.append('auction', auctionId);

        const result = await api.auctionService.uploadFinancialProof(formData);

        if (result) {
            onUpload();
            closeModal();
        }
    };

    return (
        <Modal
            className={styles.financialProof}
            isOpen={isOpen}
            onClose={closeModal.bind(null, FINANCIAL_PROOF_MODAL_NAME)}
            maxWidth={660}
        >
            <Typography.Title htmlElement="h2" className={styles.title} lang>
                {t('fp-upload-title')}
            </Typography.Title>

            <Typography.Text className={styles.text}>{t('fp-upload-text')}</Typography.Text>

            <input type="file" ref={hiddenFileInput} onChange={handleChange} hidden accept={ACCEPTED_FILES} />
            <div className={clsx(styles.uploadBox, (wrongFormat || sizeTooBig) && styles.error)} onClick={handleClick}>
                {selectedFile && (
                    <>
                        <h4>{t('fp-upload-selected-file')}</h4>
                        <p>{selectedFile.name}</p>
                        <Icon name="delete-filled" onClick={handleCancel} />
                    </>
                )}
                {!selectedFile && (
                    <>
                        <h4>{t('fp-upload-file')}</h4>
                        <p>PDF, JPG, PNG max 15mb</p>
                    </>
                )}
            </div>
            {wrongFormat && <div className={styles.wrongFormat}>{t('fp-upload-wrong-format')}</div>}
            {sizeTooBig && <div className={styles.wrongFormat}>{t('fp-upload-file-too-big')}</div>}
            <Button isDisabled={!selectedFile} onClick={uploadFinancialProof}>
                {t('fp-upload-button')}
            </Button>
        </Modal>
    );
};
