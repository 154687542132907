import React, {memo, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";

import {Typography} from "../../../ui";
import {stateToHTML} from "draft-js-export-html";
import {convertFromRaw} from "draft-js";
import styles from "../AuctionDetail.module.scss";

type DescriptionTabProps = {
    data:string;
}

const DescriptionTab:React.FC<DescriptionTabProps> = memo(({ data }) => {
    const [showAll, setShowAll] = useState(false);
    const [showAllBtn, setShowAllBtn] = useState(false);
    const { t } = useTranslation();

    const value = useMemo(() => {
        const value = JSON.parse(data);

        if(!showAll) {
            const fistBlock = value.blocks[0];

            if(fistBlock.type === 'unstyled') {
                if(fistBlock.text.length > 190) {
                    const text = fistBlock.text.slice(0, 190).split(' ').slice(0, -1).join(' ') + ' ...';
                    fistBlock.text = text;
                    setShowAllBtn(true);
                } else {
                    if(value.blocks.length > 1) setShowAllBtn(true);
                }

                value.blocks = [fistBlock];
            }

            if(fistBlock.type === 'unordered-list-item') {
                const lessBlocks:any = [];

                value.blocks.forEach((block:any, index:number) => {
                    if(block.type === 'unordered-list-item' && index < 3) lessBlocks.push(block);
                })

                if(value.blocks.length > lessBlocks.length) setShowAllBtn(true);

                value.blocks = lessBlocks;
            }
        }

        return value;
    }, [data, showAll]);

    if(!data) return null;

    return (
        <>
            <Typography.Text className={styles.description} wysiwyg={stateToHTML(convertFromRaw(value))}/>
            {showAllBtn && <div className={'link'} onClick={setShowAll.bind(null, !showAll)}>{showAll ? t('show-less') : t('read-more')}</div>}
        </>
    )
})

export default DescriptionTab;