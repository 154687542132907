import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';



import styles from "../Subscription.module.scss";
import {Formik, FormikHelpers} from "formik";
import {ValidationHelper} from "../../../../helpers";
import {Button, Input, InputError} from "../../../ui";
import {CompanyLanguages, Subscription} from "../../../../types";
import clsx from "clsx";
import {ThemeContext} from "../../../../providers";

type FormProps = {
    onSubscribe?: (values:Subscription, helpers:FormikHelpers<Subscription>) => void;
}

const formInitialValues = {
    email: '',
}

const Form:React.FC<FormProps> = ({ onSubscribe}) => {
    const { themeSettings, customization } = useContext(ThemeContext);
    const { t, i18n } = useTranslation();

    const data = useMemo(() => {
        if(customization && customization.homepage && customization.homepage.settings[i18n.language as CompanyLanguages]) {
            return customization.homepage?.settings[i18n.language as CompanyLanguages]?.newsletterOption;
        }

        return undefined;
    }, [customization, i18n.language])

    const onSubmit = (values:Subscription, helpers:FormikHelpers<Subscription>) => {
        onSubscribe && onSubscribe(values, helpers);
    }

    return (
        <div className={clsx(styles['form-wrapper'], 'form-wrapper')}>
            <Formik
                initialValues={formInitialValues}
                isInitialValid={false}
                validationSchema={ValidationHelper.schemas.subscription}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid }) => (
                    <>
                        <form onSubmit={handleSubmit}>
                            <Input
                                className={styles.input}
                                haveError={!!(errors.email && touched.email)}
                                name="email"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={data?.fieldPlaceholder || "Your E-Mail"}
                                value={values.email}
                                inputSize='big'
                            />
                            <Button htmlType="submit" size={'big'} type="black" isDisabled={!isValid}>
                                {themeSettings.template === 'traditional' && (
                                    <div className={styles['triangle-right']} {...(themeSettings.colors && { style: { borderLeftColor: themeSettings.colors.forms.background } })} />
                                )}
                                {themeSettings.template !== 'traditional' && 'Subscribe'}
                            </Button>
                        </form>
                        {errors.email && touched.email && <InputError>{`${t('email-address')} ${t(errors.email)}`}</InputError>}
                    </>
                )}
            </Formik>
        </div>
    );
};

export default Form;