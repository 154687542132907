import { RefObject } from 'react';
import { FormikHelpers } from 'formik';

import { api } from 'api';

import { SubscriptionSectionService } from 'shared/components/sections';

import { Subscription } from 'shared/types';

export const useSubscriptionSection = (ref:RefObject<SubscriptionSectionService>) => {
    const onSubmitSubscribe = async(values:Subscription, form:FormikHelpers<Subscription>) => {
        try {
            await api.subscribeService.subscribe(values);
            form.resetForm();
            ref.current?.setIsSuccess(true);
            setTimeout(() => ref.current?.setIsSuccess(false), 3000);
        } catch(e) {}
    }

	return { onSubmitSubscribe };
};
