import { ResponseDataType, ThemeSettingsServerType, ThemeSettingsType, ThemeTemplates } from "shared/types"

const toClientThemeSettingsModel = ({ data, error, status }:ResponseDataType<ThemeSettingsServerType>):ResponseDataType<ThemeSettingsType> => ({
    status,
    error,
    data: data ? {
        template: toClientTemplateModel(data.theme),
        colors: data.settings.colors,
        favicon: data.settings.favicon,
        logo: data.settings.logo,
        typography: data.settings.typography
    } : null
})

const toClientTemplateModel = (data:number):ThemeTemplates => {
    switch (data) {
        case 1:
            return 'traditional';
        case 2:
            return 'modern';
        case 3:
            return 'minimal';
        case 4:
            return 'funky';
        case 5:
            return 'classic';
        case 6:
            return 'estate';
        default:
            return 'traditional';
    }
}

export const ThemeMapper = {
    toClientThemeSettingsModel,
    toClientTemplateModel
}