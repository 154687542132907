import React, { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useNavigate } from 'shared/hooks';

import { CustomizationHomeType } from 'shared/types';

import { ThemeContext } from 'shared/providers';

import { DetectorHelper, ParseHelper } from 'shared/helpers';

import { Icon, Typography } from 'shared/components/ui';

import { Container } from '../Container/Container';

import { FooterMocks } from './Footer.mocks';

import styles from './Footer.module.scss';
import { IconsId } from 'shared/types/icons';
import { EstateFooter } from '../EstateFooter/EstateFooter';

const hideInUrls = ['change-password'];

export const Footer = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigation = useNavigate();

    const { themeSettings, customization } = useContext(ThemeContext);

    const isPreview = useMemo(() => DetectorHelper.isPreview(), []);
    const hide = useMemo(() => !!hideInUrls.find(url => location.pathname.includes(url)), [location]);
    const footerContent = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationHomeType>(
                customization?.homepage?.settings,
                'footer',
                i18n.language,
            ),
        [customization, i18n.language],
    );

    const openLink = (url: string) => {
        if (!isPreview) {
            const isExternal = DetectorHelper.isExternalLink(url);

            if (isExternal) {
                window.open(url, '_blank');
            } else {
                navigation(`/${i18n.language}${url}`);
            }
        }
    };

    if (hide) return null;

    // If theme is Estate, then show Estate footer
    if (themeSettings.template === 'estate') return <EstateFooter />;

    // For other themes return default footer
    return (
        <div
            className={clsx(styles.root, styles[`is-${themeSettings.template}`])}
            {...(themeSettings.colors && {
                style: {
                    backgroundColor: themeSettings.colors.background.footer,
                },
            })}
        >
            <Container htmlElement="footer">
                <div className={styles.main}>
                    <nav className={styles.menu}>
                        <ul>
                            <li>{footerContent?.footer.title || 'Footer'}</li>
                            {footerContent?.footer?.footerLinks?.map(
                                (item, index) =>
                                    item.name &&
                                    item.url && (
                                        <li
                                            key={`Footer-menu-${index}`}
                                            style={ParseHelper.prepareStyles({
                                                fontFamily: themeSettings.typography?.paragraphBody.fontFamily,
                                                fontSize: themeSettings.typography?.paragraphBody.fontSize,
                                            })}
                                            onClick={openLink.bind(null, item.url)}
                                        >
                                            {item.name}
                                        </li>
                                    ),
                            )}
                        </ul>
                        <ul>
                            {footerContent?.footer.staticLinks.disclaimer.url &&
                                footerContent.footer?.staticLinks.disclaimer.name && (
                                    <li
                                        onClick={openLink.bind(
                                            null,
                                            footerContent.footer?.staticLinks.disclaimer.url || '/',
                                        )}
                                    >
                                        {footerContent.footer?.staticLinks.disclaimer.name || 'Impressum'}
                                    </li>
                                )}
                            {footerContent?.footer?.staticLinks.termsAndConditions.url &&
                                footerContent.footer?.staticLinks.termsAndConditions.name && (
                                    <li
                                        onClick={openLink.bind(
                                            null,
                                            footerContent.footer?.staticLinks.termsAndConditions.url || '/',
                                        )}
                                    >
                                        {footerContent.footer?.staticLinks.termsAndConditions.name || 'AGB'}
                                    </li>
                                )}
                            {footerContent?.footer?.staticLinks.dataProtection.url &&
                                footerContent.footer?.staticLinks.dataProtection.name && (
                                    <li
                                        onClick={openLink.bind(
                                            null,
                                            footerContent.footer?.staticLinks.dataProtection.url || '/',
                                        )}
                                    >
                                        {footerContent.footer?.staticLinks.dataProtection.name || 'Data protection'}
                                    </li>
                                )}
                        </ul>
                    </nav>
                    <div className={styles.social}>
                        <Typography.Title
                            htmlElement="h6"
                            style={ParseHelper.prepareStyles({
                                color: '#fff',
                                fontFamily: themeSettings.typography?.paragraphBody.fontFamily,
                                fontSize: themeSettings.typography?.paragraphBody.fontSize,
                            })}
                        >
                            {t('follow-us')}
                        </Typography.Title>
                        <ul>
                            {footerContent &&
                                footerContent.footer.socialLinks &&
                                Object.entries(footerContent.footer.socialLinks).map(
                                    ([key, value], index) =>
                                        value.isActive && (
                                            <li key={`Footer-social-${index}`} onClick={openLink.bind(null, value.url)}>
                                                <Icon name={key as IconsId} />
                                            </li>
                                        ),
                                )}
                            {!footerContent &&
                                FooterMocks.social.map((item, index) => (
                                    <li key={`Footer-social-${index}`}>
                                        <Icon name={item} />
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className={styles.payment}>
                        <Typography.Title
                            htmlElement="h6"
                            style={ParseHelper.prepareStyles({
                                color: '#fff',
                                fontFamily: themeSettings.typography?.paragraphBody.fontFamily,
                                fontSize: themeSettings.typography?.paragraphBody.fontSize,
                            })}
                        >
                            {t('pay-with')}
                        </Typography.Title>
                        <ul>
                            {FooterMocks.payments.map((item, index) => (
                                <li key={`Footer-payment-${index}`} className={styles[item]}>
                                    <Icon name={item} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <div className={styles['made-by']}>
                        {t('made-with')} <Icon name="heart" /> {t('by-mindnow')}
                    </div>
                    <div className={styles.stripe}>
                        <span>{t('secure-pay-with')}</span>
                        <Icon name="stripe-text" />
                    </div>
                </div>
            </Container>
        </div>
    );
};
