import React, { CSSProperties } from 'react';
import clsx from 'clsx';

import styles from './MoreText.module.scss';

type MoreTextProps = {
    className?:string;
    onClick?: () => void;
    style?:CSSProperties;
}

export const MoreText:React.FC<MoreTextProps> = ({ children, className, style, onClick }) => (
    <div className={clsx(styles.root, className, ['more-text'])} onClick={onClick} style={style}>
        {children}
    </div>
)