import * as yup from 'yup';

const emailValidator = yup.string().email('is-invalid').required('is-required');
const newPasswordValidator = yup
    .string()
    .required('Password is required')
    .matches(/[A-Z]/, 'uppercase')
    .matches(/[a-z]/, 'lowercase')
    .matches(/[0-9]/, 'number')
    .matches(/.{8,}/, 'min')
    .matches(/([.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\])/g, 'special'); //eslint-disable-line
const passwordRepeatValidator = yup
    .string()
    .oneOf([yup.ref('password'), null], 'repeat-password-error')
    .required('repeat-password-is-required');

const onlyEmail = yup.object({
    email: emailValidator,
});

const login = yup.object({
    email: emailValidator,
    password: yup.string().required('is-required'),
});

const subscription = yup.object({
    email: emailValidator,
});

const settings = yup.object({
    city: yup.string().required('is-required'),
    country: yup.string().required('is-required'),
    firstName: yup.string().required('is-required'),
    lastName: yup.string().required('is-required'),
    postCode: yup.string().required('is-required'),
    street: yup.string().required('is-required'),
});

const registration = yup
    .object({
        email: emailValidator,
        password: newPasswordValidator,
        passwordRepeat: passwordRepeatValidator,
    })
    .concat(settings);

const changePassword = yup.object({
    password: newPasswordValidator,
    passwordRepeat: passwordRepeatValidator,
});

const priceBid = (minimumOffer: string, maxOffer: string) => {
    const minimum = Number(minimumOffer);
    const maximum = Number(maxOffer);

    return yup.object({
        price: yup.number().required('').min(minimum, 'min-offer').max(maximum, 'max-offer'),
    });
};

const schemas = {
    login,
    registration,
    onlyEmail,
    changePassword,
    settings,
    subscription,
    priceBid,
};

const prepareFormErrors = (data: any) =>
    data.reduce(
        (memo: { [key in string]: string[] }, { path, message }: { path: string; message: string }) => ({
            ...memo,
            [path]: (memo[path] || []).concat(message),
        }),
        {},
    );

const themeParam = (param: string | undefined, expectedData: readonly string[]) => {
    if (param) {
        const exist = expectedData.find(i => i === param);
        if (exist) return exist;
    }

    return expectedData[0];
};

export const ValidationHelper = {
    schemas,
    themeParam,
    prepareFormErrors,
};
