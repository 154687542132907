import { http } from 'api/http';

import {
    AuctionItemType, AuctionLastFinancialProofType,
    OrderItemType,
    OrderType,
    PaginationApiType,
    ResponseDataType,
    StripePaymentDataType,
} from 'shared/types';

const getPaymentData = async (id: string): Promise<ResponseDataType<StripePaymentDataType>> => {
    return http.post<StripePaymentDataType>(`/orders/${id}/checkout_sessions/`, {});
};

const getOrderAuction = async (id: string): Promise<ResponseDataType<OrderType>> => {
    return http.get<OrderType>(`/orders/${id}`);
};

const onBuyNowAuction = async (id: string): Promise<ResponseDataType<OrderType>> => {
    return http.post<OrderType>('/orders/', { auction: id });
};

const getMy = async (page: number): Promise<ResponseDataType<PaginationApiType<OrderItemType>>> => {
    return http.get<PaginationApiType<OrderItemType>>(`/orders/?page=${page}`);
};

const getMyBids = async (): Promise<ResponseDataType<AuctionItemType[]>> => {
    return http.get<AuctionItemType[]>(`/customers/bids`);
};

const getMyProofs = async (): Promise<ResponseDataType<AuctionLastFinancialProofType[]>> => {
    return http.get(`/users/financial-proofs/list/`);
};

const removeMyProof = async (id:number) => {
    return http.deleteMethod(`/users/financial-proofs/delete/${id}` );
};

export const createOrderService = () => ({
    getPaymentData,
    getOrderAuction,
    onBuyNowAuction,
    getMy,
    getMyBids,
    getMyProofs,
    removeMyProof
});
