import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { ThemeContext } from 'shared/providers';

import { Typography } from '../Typography/Typography';
import { Button } from '../Button/Button';
import { Timer } from '../Timer/Timer';
import { AuctionLabel } from '../AuctionLabel/AuctionLabel';

import { DetectorHelper, ParseHelper, DateHelper } from 'shared/helpers';

import {
    AuctionDiscountEnum,
    AuctionItemType,
    AuctionProductInfoItemType,
    AuctionStatusEnum,
    AuctionTypeEnum,
    CustomizationHomeType,
    OrderItemType,
    OrderPaymentStatusEnum,
} from 'shared/types';

import styles from './AuctionItem.module.scss';

type AuctionItemProps = {
    className?: string;
    data: AuctionItemType;
    buttonText?: string;
    orderCard?: boolean;
    order?: OrderItemType;
};

export const AuctionItem: React.FC<AuctionItemProps> = ({ data, className, buttonText, orderCard, order }) => {
    const { customization } = useContext(ThemeContext);
    const isPreview = useMemo(() => DetectorHelper.isPreview(), []);
    const { t, i18n } = useTranslation();

    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationHomeType>(
                customization?.homepage?.settings,
                'hero.timerTitle',
                i18n.language,
            ),
        [customization, i18n.language],
    );
    const productContent = useMemo(
        () =>
            ParseHelper.prepareLanguageData<AuctionProductInfoItemType>(
                data?.product.product_info,
                'product_name',
                i18n.language,
            ),
        [data, i18n.language],
    );

    // const discount = useMemo(() => {
    //     if (!(data && data.minimum_order_price)) return '-0%';
    //
    //     const price = Number(data.uvp || data.product.uvp);
    //     const lowestPrice = Number(data.minimum_order_price);
    //
    //     return `-${Math.abs(Math.round((100 * lowestPrice) / price - 100))}%`;
    // }, [data]);

    const AuctionContent = () => (
        <>
            <AuctionLabel.Item
                hideIcon={data.status.value === AuctionStatusEnum.Ended || orderCard}
                className={styles.label}
            >
                {orderCard &&
                    (order?.payment?.status
                        ? t(OrderPaymentStatusEnum[order?.payment?.status as any].toLowerCase())
                        : t('unpaid'))}
                {!orderCard && data.status.value !== AuctionStatusEnum.Ended && (
                    <>
                        {data.type.value === AuctionTypeEnum.BuyNow && data.discount
                            ? `-${data.discount.value}${
                                  data.discount.type === AuctionDiscountEnum.PERCENTAGE ? '%' : ' CHF'
                              } ${t('discount')}`
                            : `${t('the-highest')} ${t('offer-wins')}`}
                    </>
                )}
                {!orderCard && data.status.value === AuctionStatusEnum.Ended && (
                    <>
                        {t('ended-at')}: {DateHelper.getDate(new Date(data.ends_at))}
                    </>
                )}
            </AuctionLabel.Item>
            <img src={data.product.images[0].image} alt="preview" />
            <div className={clsx(styles.content, 'auction-item__content')}>
                <div className={clsx(styles.uvp, 'auction-item__uvp')}>
                    {data.status.value === AuctionStatusEnum.Ended && (
                        <>
                            {data.minimum_order_price || data.discount ? (
                                <>
                                    <span>{ParseHelper.removeCents(data.uvp || data.product.uvp)}</span>{' '}
                                    {ParseHelper.removeCents(
                                        data.minimum_order_price ||
                                            (data &&
                                                data.discount &&
                                                ParseHelper.discountedPrice(
                                                    data.discount,
                                                    data.uvp || data.product.uvp,
                                                )) ||
                                            '0',
                                    )}
                                    .-
                                </>
                            ) : (
                                `UVP ${ParseHelper.removeCents(data.uvp || data.product.uvp)}.-`
                            )}
                        </>
                    )}
                    {data.status.value === AuctionStatusEnum.Active && (
                        <>
                            {data.type.value === AuctionTypeEnum.BuyNow && data.discount ? (
                                <>
                                    <span>{ParseHelper.removeCents(data.uvp)}</span>{' '}
                                    {ParseHelper.removeCents(
                                        ParseHelper.discountedPrice(data.discount, data.uvp || data.product.uvp),
                                    )}
                                    .-
                                </>
                            ) : (
                                `${t('minimum-next-bid')} ${ParseHelper.removeCents(data.minimal_winner_bid)}.-`
                            )}
                        </>
                    )}
                </div>
                <Typography.Title className={clsx(styles.title, 'auction-item__title')} htmlElement="h4">
                    {productContent?.product_name}
                </Typography.Title>
                <div className={'auction-item__timer-wrap'}>
                    {data.status.value !== AuctionStatusEnum.Ended && (
                        <>
                            {!orderCard && (
                                <Timer
                                    className={clsx(styles.timer, 'auction-item__timer')}
                                    endsIn={new Date(data.ends_at)}
                                    title={content?.hero.timerTitle}
                                />
                            )}
                            {!orderCard && <Button>{buttonText ? buttonText : data.type.display_name}</Button>}
                        </>
                    )}
                    {orderCard && order?.invoice_file && (
                        <Button onClick={() => window.open(order?.invoice_file)} className="invoice-btn">
                            {t('download-invoice')}
                        </Button>
                    )}
                    {/*{!orderCard && data.status.value === AuctionStatusEnum.Ended && (*/}
                    {/*    <div className={clsx(styles.discount, 'dicount')}>*/}
                    {/*        {data.discount &&*/}
                    {/*            `-${data.discount.value}${*/}
                    {/*                data.discount.type === AuctionDiscountEnum.PERCENTAGE ? '%' : ' CHF'*/}
                    {/*            }`}*/}
                    {/*        {!data.discount && `${discount}`}*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            </div>
        </>
    );

    return orderCard ? (
        <div
            className={clsx('auction-item', styles.body, className)}
            {...(isPreview && { onClick: e => e.preventDefault() })}
        >
            <AuctionContent />
        </div>
    ) : (
        <Link
            to={`/${i18n.language}/auction/${data.id}`}
            className={clsx('auction-item', styles.body, className)}
            {...(isPreview && { onClick: e => e.preventDefault() })}
        >
            <AuctionContent />
        </Link>
    );
};
