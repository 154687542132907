import { http } from 'api/http';
import { ThemeMapper } from 'api/mappers/theme';

import {
    CustomizationConfigResponseType,
    ResponseDataType,
    SettingsResponseType,
    ThemeSettingsServerType,
    ThemeSettingsType,
} from 'shared/types';

const getSettings = async (): Promise<ResponseDataType<SettingsResponseType>> => {
    return http.get<SettingsResponseType>('/storefront/company-settings');
};

const getThemeSettings = async (): Promise<ResponseDataType<ThemeSettingsType>> => {
    const result = await http.get<ThemeSettingsServerType>('/cms/published-theme-settings/get-active/');

    return ThemeMapper.toClientThemeSettingsModel(result);
};

const getCustomization = async (): Promise<ResponseDataType<CustomizationConfigResponseType>> => {
    return await http.get<CustomizationConfigResponseType>('/cms/published-page-customization/');
};
export const createThemeService = () => ({
    getSettings,
    getThemeSettings,
    getCustomization,
});
