import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import Slider from 'react-slick';

import { Formik, FormikHelpers } from 'formik';

import { AppSettingsContext, AuthContext, ThemeContext } from 'shared/providers';

import { ParseHelper, ValidationHelper } from 'shared/helpers';
import { Container } from 'shared/components/grid';

import {
    Typography,
    Timer,
    Input,
    Button,
    SliderArrow,
    InputWrapper,
    InputError,
    AuctionLabel,
} from 'shared/components/ui';

import { useDeviceDetector } from 'shared/hooks';
import {
    AuctionPriceBidType,
    AuctionItemType,
    AuctionTypeEnum,
    AuctionBidStatusType,
    AuctionDiscountEnum,
    CompanyLanguages,
    AuctionStatusEnum,
    AuctionProductInfoItemType,
    CustomizationHomeType,
    CustomizationProductDetailType,
} from 'shared/types';
import OfferStatus from './components/OfferStatus';
import InfoBlock from './components/InfoBlock';

import styles from './AuctionDetail.module.scss';

export type AuctionDetailSectionService = {
    setInProgress: (state: boolean) => void;
};

type FormData = {
    price: string;
};

type AuctionDetailSectionProps = {
    ref?: any;
    haveOfferError?: boolean;
    bidData?: AuctionBidStatusType | null;
    data: AuctionItemType;
    onPriceBid?: (data: AuctionPriceBidType, formikHelpers: FormikHelpers<AuctionPriceBidType>) => void;
    onFormChange?: () => void;
    onBuy?: () => void;
    onBuyNow?: () => void;
    isOrder?: boolean;
    disableButton?: boolean;
    isPreview?: boolean;
};

const formInitialValues: FormData = {
    price: '',
};

export const AuctionDetailSection: React.FC<AuctionDetailSectionProps> = forwardRef(
    (
        {
            data,
            disableButton = false,
            bidData,
            haveOfferError,
            onPriceBid,
            onFormChange,
            onBuy,
            onBuyNow,
            isOrder = false,
            isPreview = false,
        },
        ref,
    ) => {
        const mainSlider = useRef<Slider>(null);
        const previewSlider = useRef<Slider>(null);
        const { device } = useDeviceDetector();
        const { t, i18n } = useTranslation();
        const { customization } = useContext(ThemeContext);
        const { shopName } = useContext(AppSettingsContext);
        const { isAuthorized } = useContext(AuthContext);
        const [inProgress, setInProgress] = useState<boolean>(false);

        const homeContent = useMemo(
            () =>
                ParseHelper.prepareLanguageData<CustomizationHomeType>(
                    customization?.homepage?.settings,
                    'hero.timerTitle',
                    i18n.language,
                ),
            [customization, i18n.language],
        );
        const productDetailContent = useMemo(
            () =>
                ParseHelper.prepareLanguageData<CustomizationProductDetailType>(
                    customization?.productDetailPage?.settings,
                    'hero.CTAcopy',
                    i18n.language,
                ),
            [customization, i18n.language],
        );
        const productContent = useMemo(
            () =>
                ParseHelper.prepareLanguageData<AuctionProductInfoItemType>(
                    data?.product.product_info,
                    'product_name',
                    i18n.language,
                ),
            [data, i18n.language],
        );

        useEffect(() => {
            setInProgress(false);
        }, [isAuthorized]);

        const onSubmit = (data: FormData, helpers: FormikHelpers<FormData>) => {
            setInProgress(true);
            onPriceBid && onPriceBid(data, helpers);
            setTimeout(() => setInProgress(false), 3000);
        };

        const onPay = () => {
            setInProgress(true);
            onBuy && onBuy();
        };

        useImperativeHandle(ref, () => ({
            setInProgress,
        }));

        const deliveryDay = useMemo(() => {
            const devlivery = new Date(data.ends_at);
            devlivery.setDate(devlivery.getDate() + data.product.days_for_delivery);
            const date = devlivery.getDate();
            const month = devlivery.getMonth() + 1;

            return `${date < 9 ? `0${date}` : date}.${month < 9 ? `0${month}` : month}.${devlivery.getFullYear()}`;
        }, [data]);

        const showForm = ((bidData && !bidData.is_winner) || !isAuthorized) && !isOrder;

        return (
            <>
                <Helmet>
                    <title>{productContent?.SEO.page_title || `${shopName}: Product page`}</title>
                    <meta name="description" content={productContent?.SEO.page_body || `${shopName}: Product page`} />
                </Helmet>
                <section className={'auction-details-section'}>
                    <Container className={styles.root}>
                        <div className={styles.info}>
                            {customization?.productDetailPage?.settings[i18n.language as CompanyLanguages]?.hero
                                .conversationSticker.isActive && (
                                <AuctionLabel.Hero
                                    className={clsx(styles.label, ['auction-details-section__label'])}
                                    hideIcon={data.type.value === AuctionTypeEnum.BuyNow}
                                >
                                    {data.type.value === AuctionTypeEnum.BuyNow && data.discount && (
                                        <>
                                            <span>
                                                -{data.discount.value}{' '}
                                                {data.discount.type === AuctionDiscountEnum.PERCENTAGE ? '%' : 'CHF'}
                                            </span>
                                            {t('discount').toUpperCase()}
                                        </>
                                    )}
                                    {data.type.value === AuctionTypeEnum.RegularAuction && (
                                        <>
                                            {t('the-highest')}
                                            <br />
                                            {t('offer-wins')}
                                        </>
                                    )}
                                </AuctionLabel.Hero>
                            )}

                            <Slider
                                ref={mainSlider}
                                className={clsx(styles['big-slider'], 'auction-details-section__big-slider')}
                                arrows={false}
                                slidesToShow={1}
                                fade
                            >
                                {data.product.images.map(({ image, id }) => (
                                    <div
                                        className={styles['product-slide']}
                                        key={`AuctionDetailSection-main-slider-${id}`}
                                    >
                                        <img src={image} alt="product" />
                                    </div>
                                ))}
                            </Slider>
                            {data.product.images.length > 1 && (
                                <div className={styles.preview}>
                                    <SliderArrow.Left
                                        className={styles['left-arrow']}
                                        size="small"
                                        onClick={() => previewSlider.current?.slickPrev()}
                                    />
                                    <Slider
                                        className={styles['preview-slider']}
                                        ref={previewSlider}
                                        arrows={false}
                                        slidesToShow={data.product.images.length === 2 || device === 'mobile' ? 2 : 3}
                                        swipeToSlide
                                        centerMode={data.product.images.length === 2}
                                        asNavFor={mainSlider.current || undefined}
                                        dots={device === 'mobile'}
                                        focusOnSelect
                                    >
                                        {data.product.images.map(({ image, id }) => (
                                            <div
                                                className={clsx(styles['preview-slide'], [
                                                    'auction-details-section__preview-slide',
                                                ])}
                                                key={`AuctionDetailSection-preview-slider-${id}`}
                                            >
                                                <img src={image} alt="product" />
                                            </div>
                                        ))}
                                    </Slider>
                                    <SliderArrow.Right
                                        className={styles['right-arrow']}
                                        size="small"
                                        onClick={() => previewSlider.current?.slickNext()}
                                    />
                                </div>
                            )}
                            {device !== 'mobile' && !isOrder && <InfoBlock data={productContent?.description} />}
                        </div>
                        <div className={styles.product}>
                            <div className={styles['text-box']}>
                                <Typography.Title className={styles.title} htmlElement="h1">
                                    <div className={clsx(styles.uvp, 'title-uvp')}>
                                        {data.type.value === AuctionTypeEnum.RegularAuction && (
                                            <>
                                                {data.status.value === AuctionStatusEnum.Ended ? `${t('sold-for')} ${ParseHelper.removeCents(data.highest_bid?.next_bid)} CHF` : `${t('current-price')} ${ParseHelper.removeCents(data.minimal_winner_bid)}.-`}
                                            </>
                                        )}
                                        {data.type.value === AuctionTypeEnum.BuyNow && (
                                            <>
                                                UVP{' '}
                                                <span>
                                                    {ParseHelper.removeCents(data.uvp)}
                                                    .-
                                                </span>{' '}
                                                {data.discount &&
                                                    ParseHelper.removeCents(
                                                        ParseHelper.discountedPrice(data.discount, data.uvp),
                                                    )}
                                                .-
                                            </>
                                        )}
                                    </div>
                                    {productContent?.product_name}
                                </Typography.Title>

                                {!isOrder && (
                                    <Timer
                                        className={styles.timer}
                                        endsIn={new Date(data.ends_at)}
                                        title={homeContent?.hero.timerTitle}
                                    />
                                )}

                                <ul className={styles.infoList}>
                                    <li>{productContent?.USP.USP_1}</li>
                                    <li>{productContent?.USP.USP_2}</li>
                                    <li>{productContent?.USP.USP_3}</li>
                                </ul>

                                {data.status.value !== AuctionStatusEnum.Ended && (
                                    <Typography.Text className={clsx(styles.delivery, 'latest-possible')}>
                                        {t('latest-possible-delivery')} {deliveryDay}
                                        <br />
                                        {t('shipping-cost')} CHF {data.product.shipping_cost}.-
                                        <br />
                                    </Typography.Text>
                                )}


                                {device === 'mobile' && !isOrder && <InfoBlock data={productContent?.description} />}
                            </div>
                            <div className={styles['buy-box']}>
                                {data.type.value === AuctionTypeEnum.RegularAuction &&
                                    data.status.value !== AuctionStatusEnum.Ended && (
                                        <>
                                            {((!isOrder &&
                                                bidData &&
                                                bidData.current_price !== null &&
                                                bidData.user_greatest_offer !== null &&
                                                isAuthorized) ||
                                                isPreview) && (
                                                <OfferStatus data={bidData} interval={data.offer_interval} />
                                            )}
                                            {showForm && (
                                                <>
                                                    <Formik
                                                        validationSchema={ValidationHelper.schemas.priceBid(
                                                            data.offer_minimum || '0',
                                                            data.product.uvp,
                                                        )}
                                                        initialValues={formInitialValues}
                                                        onSubmit={onSubmit}
                                                        validate={onFormChange}
                                                        isInitialValid={false}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange,
                                                            handleBlur,
                                                            values,
                                                            isValid,
                                                            errors,
                                                            touched,
                                                        }) => (
                                                            <form
                                                                className={clsx(
                                                                    styles.form,
                                                                    'auction-details-section__form',
                                                                )}
                                                                onSubmit={handleSubmit}
                                                            >
                                                                <InputWrapper
                                                                    className={clsx(
                                                                        styles['input-wrapper'],
                                                                        'input-wrapper',
                                                                    )}
                                                                >
                                                                    <Input
                                                                        haveError={haveOfferError}
                                                                        placeholder={t('enter-your-bid')}
                                                                        type="text"
                                                                        name="price"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.price}
                                                                        onlyNumbers
                                                                    />
                                                                    <span
                                                                        className={clsx(
                                                                            styles.currency,
                                                                            'form-currency',
                                                                        )}
                                                                    >
                                                                        CHF
                                                                    </span>
                                                                    {errors.price && touched.price && (
                                                                        <InputError>{`${t(errors.price)} ${
                                                                            errors.price === 'max-offer'
                                                                                ? Number(data.product.uvp || '0')
                                                                                : Number(data.offer_minimum || '0')
                                                                        } CHF`}</InputError>
                                                                    )}
                                                                    {haveOfferError && (
                                                                        <InputError>
                                                                            {t('offer-error-begin')}{' '}
                                                                            {data.offer_interval} CHF{' '}
                                                                            {t('offer-error-end')}
                                                                        </InputError>
                                                                    )}
                                                                </InputWrapper>
                                                                <Button
                                                                    htmlType="submit"
                                                                    isDisabled={!isValid || haveOfferError}
                                                                    isLoading={inProgress}
                                                                >
                                                                    {productDetailContent?.hero.CTAcopy ||
                                                                        'Offer your price'}
                                                                </Button>
                                                            </form>
                                                        )}
                                                    </Formik>
                                                </>
                                            )}
                                        </>
                                    )}
                                {!isOrder &&
                                    data.type.value === AuctionTypeEnum.BuyNow &&
                                    data.status.value !== AuctionStatusEnum.Ended && (
                                        <>
                                            {data.product.quantity === 0 && (
                                                <div className={styles.sold}>{t('out-of-stock')}</div>
                                            )}
                                            {data.product.quantity > 0 && (
                                                <Button
                                                    className={styles.buy}
                                                    onClick={onBuyNow}
                                                    isLoading={inProgress}
                                                >
                                                    {t('buy-now')}
                                                </Button>
                                            )}
                                        </>
                                    )}
                                {isOrder && (
                                    <Button
                                        className={styles.buy}
                                        onClick={onPay}
                                        isLoading={inProgress}
                                        isDisabled={disableButton}
                                    >
                                        {t('proceed-checkout')}
                                    </Button>
                                )}

                                {showForm && data.status.value !== AuctionStatusEnum.Ended && (
                                    <Typography.Text className={styles.privacy}>
                                        <span className="privacy-label">
                                            {t('offer-description-1')} “
                                            {productDetailContent?.hero.CTAcopy || 'Offer your price'}
                                            {/* {data.type.value ===
                                                    AuctionTypeEnum.BuyNow
                                                        ? t('buy-now')
                                                        : t('offer-your-price')} */}
                                            ” {t('offer-description-2')}
                                        </span>{' '}
                                        <a href="https://ecatch.io/terms-and-conditions" className={'link'}>
                                            {t('offer-description-3')}
                                        </a>
                                    </Typography.Text>
                                )}
                            </div>

                        </div>
                    </Container>
                </section>
            </>
        );
    },
);
