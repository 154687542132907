import React, { useContext } from 'react';
import { Container } from 'shared/components/grid';
import { Typography } from 'shared/components/ui';
import { AppSettingsContext } from '../../../providers';

type ContactInfoProps = {
    title?: string;
};

export const ContactInfo: React.FC<ContactInfoProps> = ({ title }) => {
    const { contactEmail } = useContext(AppSettingsContext);
    return (
        <div className="contact-wrapper">
            <Container>
                {title && <Typography.Text className="contact-title">{title}</Typography.Text>}
                {contactEmail && (
                    <Typography.Title htmlElement="h2" className="contact-email">
                        <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                    </Typography.Title>
                )}
            </Container>
        </div>
    );
};
