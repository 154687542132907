import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const storageName = 'language';
const stored = localStorage.getItem(storageName);

const init = (list: string[]) => {
    let current = null;
    if (stored) {
        const lang = list.find(lang => lang === stored);
        if (lang) {
            current = lang;
        } else {
            current = list[0];
            saveLang(current);
        }
    } else {
        current = list[0];
    }

    if (
        window.location.pathname.includes('confirm-email') ||
        window.location.pathname.includes('change-password') ||
        window.location.pathname.includes('order')
    ) {
        const result = list.find(i => window.location.pathname.includes(`/${i}`));
        if (!result) window.location.href = `/${current}${window.location.pathname}`;
    }

    if (
        window.location.pathname !== '/' &&
        window.location.pathname !== '/page-not-found' &&
        !window.location.pathname.includes('confirm-email') &&
        !window.location.pathname.includes('change-password') &&
        !window.location.pathname.includes('order')
    ) {
        const result = list.find(i => window.location.pathname.includes(i));
        if (!result) window.location.href = `/${current}/page-not-found`;
    }

    return i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            lng: current,
            fallbackLng: list,
            // keySeparator: false, // Not using for now.
            interpolation: {
                escapeValue: false, // React already safes from xss.
            },
            react: {
                useSuspense: false,
            },
            backend: { loadPath: '/locales/{{lng}}.json' },
        });
};

const initPreview = () =>
    i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            lng: 'en',
            fallbackLng: ['en', 'fr', 'de', 'it'],
            // keySeparator: false, // Not using for now.
            interpolation: {
                escapeValue: false, // React already safes from xss.
            },
            react: {
                useSuspense: false,
            },
            backend: { loadPath: '/locales/{{lng}}.json' },
        });

const change = (lang: string): string => {
    const url = window.location.pathname.replace(i18n.language, lang);
    i18n.changeLanguage(lang);
    saveLang(lang);
    return url;
};

const saveLang = (lang: string) => localStorage.setItem(storageName, lang);

const getFullName = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'English';
        case 'fr':
            return 'Français';
        case 'de':
            return 'Deutsch';
        case 'it':
            return 'Italiano';
        default:
            return 'Deutsch';
    }
};

export const LanguageHelper = {
    storageName,
    stored,
    init,
    change,
    initPreview,
    getFullName,
};
