import { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { api } from 'api';

import { useModal } from 'shared/hooks';

import { AuthContext } from 'shared/providers';

import { Modal, Typography } from 'shared/components/ui';
import { SettingsForm } from 'shared/components/forms';

import { AccountSettingsType } from 'shared/types';

import styles from './AccountData.module.scss';

export const ACCOUNT_DATA_MODAL_NAME = '#account-data';

export const AccountDataModal = () => {
    const { t } = useTranslation();
    const { isOpen, closeModal } = useModal(ACCOUNT_DATA_MODAL_NAME);
    const { setSettings } = useContext(AuthContext);

    const onSubmit = async (data: AccountSettingsType, helpers: FormikHelpers<AccountSettingsType>) => {
        await api.authService.saveAccountSettings(data);
        setSettings(data);
        helpers.resetForm();
        closeModal();
    };

    return (
        <Modal isOpen={isOpen} onClose={closeModal.bind(null, ACCOUNT_DATA_MODAL_NAME)} maxWidth={1170}>
            <Typography.Title htmlElement="h2">{t('account-modal-title')}</Typography.Title>
            <Typography.Text className={styles.text} wysiwyg={t('account-modal-text')} />
            <SettingsForm className={styles.form} onSubmit={onSubmit} buttonText={t('confirm')} />
        </Modal>
    );
};
