import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';

import { ValidationHelper } from 'shared/helpers';

import { Button, Checkbox, Input, InputError, InputWrapper, Radio, Typography } from 'shared/components/ui';

import { AccountSettingsType } from 'shared/types';

import styles from './Settings.module.scss';

const initial: AccountSettingsType = {
    userId: '',
    city: '',
    country: '',
    firstName: '',
    gender: 'none',
    lastName: '',
    postCode: '',
    street: '',
    emailNotification: false,
};

type SettingsFormProps = {
    className?: string;
    initialValues?: AccountSettingsType;
    buttonText: string;
    onSubmit: (data: AccountSettingsType, helpers: FormikHelpers<AccountSettingsType>) => void;
};

export const SettingsForm: React.FC<SettingsFormProps> = ({
    className,
    onSubmit,
    buttonText,
    initialValues = initial,
}) => {
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.root, className)}>
            <Formik<AccountSettingsType>
                initialValues={initialValues}
                validationSchema={ValidationHelper.schemas.settings}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, handleChange, handleBlur, values, isValid, errors, touched }) => (
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <Typography.Title htmlElement="h3">{t('general-information')}</Typography.Title>

                        <div className={styles.separator}></div>

                        <div className={styles.line}>
                            <InputWrapper label={t('first-name')} className={styles['input-wrapper']}>
                                <Input
                                    placeholder={`${t('enter-your')} ${t('first-name').toLowerCase()}`}
                                    type="text"
                                    name="firstName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                    haveError={!!(errors.firstName && touched.firstName)}
                                />
                                {errors.firstName && touched.firstName && (
                                    <InputError className={styles.error}>{`${t('first-name')} ${t(
                                        errors.firstName,
                                    )}`}</InputError>
                                )}
                            </InputWrapper>
                            <InputWrapper label={t('last-name')} className={styles['input-wrapper']}>
                                <Input
                                    placeholder={`${t('enter-your')} ${t('last-name').toLowerCase()}`}
                                    type="text"
                                    name="lastName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                    haveError={!!(errors.lastName && touched.lastName)}
                                />
                                {errors.lastName && touched.lastName && (
                                    <InputError className={styles.error}>{`${t('last-name')} ${t(
                                        errors.lastName,
                                    )}`}</InputError>
                                )}
                            </InputWrapper>
                        </div>

                        <div className={styles.line}>
                            <InputWrapper label={t('country')} className={styles['input-wrapper']}>
                                <Input
                                    placeholder={`${t('enter-your')} ${t('country').toLowerCase()}`}
                                    type="text"
                                    name="country"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.country}
                                    haveError={!!(errors.country && touched.country)}
                                />
                                {errors.country && touched.country && (
                                    <InputError className={styles.error}>{`${t('country')} ${t(
                                        errors.country,
                                    )}`}</InputError>
                                )}
                            </InputWrapper>
                            <div className={styles['small-line']}>
                                <InputWrapper label={t('city')} className={clsx(styles['input-wrapper'], styles.city)}>
                                    <Input
                                        placeholder={`${t('enter-your')} ${t('city').toLowerCase()}`}
                                        type="text"
                                        name="city"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.city}
                                        haveError={!!(errors.city && touched.city)}
                                    />
                                    {errors.city && touched.city && (
                                        <InputError className={styles.error}>{`${t('city')} ${t(
                                            errors.city,
                                        )}`}</InputError>
                                    )}
                                </InputWrapper>
                                <InputWrapper
                                    label={t('post-code')}
                                    className={clsx(styles['input-wrapper'], styles['post-code'])}
                                >
                                    <Input
                                        onlyNumbers
                                        placeholder={t('post-code')}
                                        type="text"
                                        name="postCode"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.postCode}
                                        haveError={!!(errors.postCode && touched.postCode)}
                                    />
                                    {errors.postCode && touched.postCode && (
                                        <InputError className={styles.error}>{`${t('post-code')} ${t(
                                            errors.postCode,
                                        )}`}</InputError>
                                    )}
                                </InputWrapper>
                            </div>
                        </div>

                        <div className={styles.line}>
                            <InputWrapper label={t('street')} className={styles['input-wrapper']}>
                                <Input
                                    placeholder={`${t('enter-your')} ${t('street').toLowerCase()}`}
                                    type="text"
                                    name="street"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.street}
                                    haveError={!!(errors.street && touched.street)}
                                />
                                {errors.street && touched.street && (
                                    <InputError className={styles.error}>{`${t('street')} ${t(
                                        errors.street,
                                    )}`}</InputError>
                                )}
                            </InputWrapper>
                            <InputWrapper label={t('gender')} htmlElement="div" className={styles['input-wrapper']}>
                                <div className={styles['genders-options']}>
                                    <InputWrapper className={styles['radio-wrapper']}>
                                        <Radio
                                            type="radio"
                                            name="gender"
                                            value="male"
                                            checked={values.gender === 'male'}
                                            onChange={handleChange}
                                        >
                                            {t('male')}
                                        </Radio>
                                    </InputWrapper>
                                    <InputWrapper className={styles['radio-wrapper']}>
                                        <Radio
                                            type="radio"
                                            name="gender"
                                            value="female"
                                            checked={values.gender === 'female'}
                                            onChange={handleChange}
                                        >
                                            {t('female')}
                                        </Radio>
                                    </InputWrapper>
                                    <InputWrapper className={styles['radio-wrapper']}>
                                        <Radio
                                            type="radio"
                                            name="gender"
                                            value="none"
                                            checked={values.gender === 'none'}
                                            onChange={handleChange}
                                        >
                                            {t('not-choose')}
                                        </Radio>
                                    </InputWrapper>
                                </div>
                            </InputWrapper>
                        </div>

                        <Typography.Title htmlElement="h3">{t('notifications')}</Typography.Title>

                        <div className={styles.separator}></div>

                        <div className={clsx(styles.line, styles['checkbox-wrapper'])}>
                            <Checkbox
                                name="emailNotification"
                                label={t('email-overbid-notification')}
                                checked={values.emailNotification}
                            />
                        </div>

                        <div className={clsx(styles.line, styles['last-line'])}>
                            <div className={styles['button-wrapper']}>
                                <Button htmlType="submit" isDisabled={!isValid}>
                                    {buttonText}
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};
