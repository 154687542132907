import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDeviceDetector } from 'shared/hooks';

import { ScrollHelper } from 'shared/helpers';

import { MobileHamburger } from 'shared/components/ui';

import { MobileMenu } from '../MobileMenu/MobileMenu';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

import styles from './Layout.module.scss';

const hideInUrls = ['change-password'];

export const Layout: React.FC = ({ children }) => {
    const { device } = useDeviceDetector();
    const [mobileMenuState, changeMobileMenuState] = useState(false);
    const { pathname } = useLocation();
    const hideBurgerMenu = useMemo(() => !!hideInUrls.find(url => pathname.includes(url)), [pathname]);

    useEffect(() => {
        if (mobileMenuState) {
            ScrollHelper.lock();
        } else {
            ScrollHelper.unlock();
        }
    }, [mobileMenuState]);

    return (
        <>
            {device === 'mobile' && !hideBurgerMenu && (
                <>
                    <MobileHamburger
                        isOpen={mobileMenuState}
                        onClick={changeMobileMenuState.bind(null, !mobileMenuState)}
                    />
                    <MobileMenu isOpen={mobileMenuState} onLinkClick={changeMobileMenuState.bind(null, false)} />
                </>
            )}
            <Header />
            <main className={styles.root}>{children}</main>
            <Footer />
        </>
    );
};
