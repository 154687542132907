import WebFont from 'webfontloader';

import { ThemeTemplates } from 'shared/types';

const fonts = (families: string[]) => {
    WebFont.load({
        google: { families },
    });
};

const images = (template: ThemeTemplates): Promise<null> =>
    new Promise(resolve => {
        resolve(null);
    });

export const LoaderHelper = {
    fonts,
    images,
};
