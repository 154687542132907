import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LocalStorageHelper, ScrollHelper } from 'shared/helpers';

import { useNavigate } from './useNavigate';

import {
    LOGIN_MODAL_NAME,
    REGISTRATION_MODAL_NAME,
    RESET_PASSWORD_MODAL_NAME,
    RESET_SUCCESS_MODAL_NAME,
    REGISTRATION_SUCCESS_MODAL_NAME,
    ACCOUNT_DATA_MODAL_NAME,
    BID_WINNING_MODAL_NAME,
    BID_LOSING_MODAL_NAME,
    CONFIRM_BID_MODAL_NAME,
    BID_LOGIN_MODAL_NAME,
    BIDS_HISTORY_MODAL_NAME,
    FINANCIAL_PROOF_MODAL_NAME,
    FINANCIAL_PROOF_PENDING_MODAL_NAME,
    INFO_MODAL_NAME,
    FIRST_TIME_BID_MODAL_NAME,
} from 'shared/components/modals';

const modals = [
    REGISTRATION_MODAL_NAME,
    LOGIN_MODAL_NAME,
    RESET_PASSWORD_MODAL_NAME,
    RESET_SUCCESS_MODAL_NAME,
    REGISTRATION_SUCCESS_MODAL_NAME,
    ACCOUNT_DATA_MODAL_NAME,
    BID_WINNING_MODAL_NAME,
    BID_LOSING_MODAL_NAME,
    CONFIRM_BID_MODAL_NAME,
    BID_LOGIN_MODAL_NAME,
    BIDS_HISTORY_MODAL_NAME,
    FINANCIAL_PROOF_MODAL_NAME,
    FINANCIAL_PROOF_PENDING_MODAL_NAME,
    INFO_MODAL_NAME,
    FIRST_TIME_BID_MODAL_NAME,
] as const;

export type ModalType = typeof modals[number];

export const useModal = (modalName?: ModalType) => {
    const { location } = useHistory();
    const navigate = useNavigate();

    const [isOpen, changeState] = useState(false);

    const openModal = (modal: ModalType) => {
        navigate(location.pathname + modal);
    };

    const closeModal = () => {
        navigate(location.pathname);
    };

    useEffect(() => {
        if (isOpen) {
            ScrollHelper.lock();
        } else {
            ScrollHelper.unlock();
            LocalStorageHelper.cleanModalData();
        }
    }, [isOpen]);

    useEffect(() => {
        if (modalName) {
            changeState(() => location.hash === modalName);
        }
    }, [modalName, location]);

    return { openModal, isOpen, closeModal };
};
