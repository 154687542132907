import React from 'react';
import { Field } from 'formik';
import clsx from 'clsx';

import styles from './Checkbox.module.scss';

type CheckboxProps = {
    name: string;
    checked?: boolean;
    label?: string;
    className?: string;
    isDisabled?: boolean;
};

export const Checkbox: React.FC<CheckboxProps> = ({ name, checked, label, className, isDisabled }) => (
    <Field
        name={name}
        type="checkbox"
        checked={checked}
        component={({ field }: { field: any }) => (
            <label
                className={clsx(styles.wrapper, className, {
                    [styles['is-disabled']]: isDisabled,
                })}
            >
                <input {...field} type="checkbox" checked={checked} className={styles.input} />
                <div className={styles.root} />
                {label && <span className={styles.label}>{label}</span>}
            </label>
        )}
    />
);
