import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ThemeContext } from 'shared/providers';

import {
    AuctionDiscountEnum,
    AuctionItemType,
    AuctionProductInfoItemType,
    AuctionTypeEnum,
    CustomizationHomeType,
} from 'shared/types';

import { DetectorHelper, ParseHelper } from 'shared/helpers';
import { useDeviceDetector, useNavigate } from 'shared/hooks';

import { Container } from 'shared/components/grid';
import { Button, Timer, Typography } from 'shared/components/ui';

import ImgBox from './components/ImgBox';

import styles from './HeroMinimal.module.scss';

type HeroSectionProps = {
    data: AuctionItemType | null;
    onAuctionEnds?: () => void;
    isEnded: boolean;
};

export const HeroMinimalSection: React.FC<HeroSectionProps> = ({
    data,
    isEnded,
    onAuctionEnds,
}) => {
    const { device } = useDeviceDetector();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { themeSettings, customization } = useContext(ThemeContext);
    const isPreview = useMemo(() => DetectorHelper.isPreview(), []);

    const homeContent = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationHomeType>(
                customization?.homepage?.settings,
                'hero',
                i18n.language
            ),
        [customization, i18n.language]
    );
    const productContent = useMemo(
        () =>
            ParseHelper.prepareLanguageData<AuctionProductInfoItemType>(
                data?.product.product_info,
                'product_catch_phrase',
                i18n.language
            ),
        [data, i18n.language]
    );

    const deliveryDay = useMemo(() => {
        if (!data) return '';

        const delivery = new Date(data.ends_at);
        delivery.setDate(delivery.getDate() + data.product.days_for_delivery);
        const date = delivery.getDate();
        const month = delivery.getMonth() + 1;

        return `${date < 9 ? `0${date}` : date}.${
            month < 9 ? `0${month}` : month
        }.${delivery.getFullYear()}`;
    }, [data]);

    if (!data) return <div className={styles['empty-section']} />;

    const mainTitle = (
        <Typography.Title
            htmlElement="h2"
            className={clsx(styles.title, 'hero-section__main-title')}
        >
            {productContent?.product_catch_phrase}
        </Typography.Title>
    );
    const uvpTitle = (
        <Typography.Title
            htmlElement="h3"
            className={'hero-section__uvp-title'}
        >
            <div className={clsx(styles.uvp, 'hero-section__uvp-wrap')}>
                {data.type.value === AuctionTypeEnum.RegularAuction &&
                    `UVP ${ParseHelper.removeCents(data.product.uvp)}.-`}
                {data.type.value === AuctionTypeEnum.BuyNow && (
                    <>
                        {isEnded ? `${t('sold-for')} CHF` : 'UVP'}{' '}
                        <span>{ParseHelper.removeCents(data.uvp)}</span>{' '}
                        {data.discount &&
                            ParseHelper.removeCents(
                                ParseHelper.discountedPrice(
                                    data.discount,
                                    data.uvp
                                )
                            )}
                        .-
                    </>
                )}
            </div>
            {productContent?.product_name}
            <Typography.Text className={styles['sub-text']}>
                {t('latest-possible-delivery')} {deliveryDay} <span>/</span>{' '}
                {t('shipping-cost')} CHF {data.product.shipping_cost}.-
            </Typography.Text>
        </Typography.Title>
    );

    const imgBox = <ImgBox data={data} isEnded={isEnded} />;

    const timer = (
        <Timer
            className={clsx(styles.timer, 'hero-section__timer')}
            size={
                device === 'mobile' && themeSettings.template === 'traditional'
                    ? 'small'
                    : 'big'
            }
            showUnits
            endsIn={new Date(data.ends_at)}
            timerEnds={onAuctionEnds}
            title={homeContent?.hero.timerTitle}
        />
    );

    const buyLogic = (
        <>
            {!isEnded && (
                <>
                    <Button
                        className={'hero-section__btn-buy'}
                        size={'big'}
                        {...(!isPreview && {
                            onClick: () =>
                                navigate(
                                    `/${i18n.language}/auction/${data.id}`
                                ),
                        })}
                    >
                        {homeContent?.hero.CTAcopy || data.type.display_name}
                    </Button>
                </>
            )}
            {isEnded && data.discount && (
                <div className={styles['ended-discount']}>
                    -{data.discount.value}{' '}
                    {data.discount.type === AuctionDiscountEnum.PERCENTAGE
                        ? '%'
                        : 'CHF'}
                </div>
            )}
        </>
    );

    const desktopMarkup = (
        <>
            <div className={clsx(styles['left-part'], 'hero-section__left')}>
                {mainTitle}
                <div>{uvpTitle}</div>
            </div>
            <div className={clsx(styles['right-part'], 'hero-section__right')}>
                {imgBox}
                <div className={styles.footer}>
                    {timer}
                    {buyLogic}
                </div>
            </div>
        </>
    );

    const mobileMarkup = (
        <>
            {mainTitle}
            <div className={'hero-content-wrap'}>
                {imgBox}
                {uvpTitle}
                {timer}
                {buyLogic}
            </div>
        </>
    );

    return (
        <section className={'hero-section'}>
            <Container className={styles.container}>
                {device === 'mobile' ? mobileMarkup : desktopMarkup}
            </Container>
        </section>
    );
};
