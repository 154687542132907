import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Typography } from 'shared/components/ui';

import styles from './OrderSuccess.module.scss';

export const OrderSuccessSection = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <Icon name="success" className={styles.icon} />
            <Typography.Title htmlElement="h3" className={styles.title}>{t('order-paid')}</Typography.Title>
        </div>
    )
}