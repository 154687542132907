import React from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { Icon, MoreText, Typography } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import styles from './BidInfo.module.scss';

type BidInfoProps = {
    value: any;
    showAll: boolean;
    showAllBtn: boolean;
    downloadPDF: () => void;
    pdf?: string;
    setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BidInfo: React.FC<BidInfoProps> = ({ value, showAll, showAllBtn, downloadPDF, pdf, setShowAll }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles.text}>
                {typeof value === 'string' ? (
                    <Typography.Text>{value}</Typography.Text>
                ) : (
                    <Typography.Text wysiwyg={stateToHTML(convertFromRaw(value))} />
                )}
                {showAllBtn && (
                    <MoreText className={styles.more} onClick={setShowAll.bind(null, !showAll)}>
                        {showAll ? t('show-less') : t('read-more')}
                    </MoreText>
                )}
            </div>
            {pdf && (
                <div className={styles.download} onClick={() => downloadPDF()}>
                    <Icon name="download" />
                    {t('download-pdf')}
                </div>
            )}
        </>
    );
};
