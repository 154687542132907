import {
    AccountSettingsServerType,
    AccountSettingsType,
    ChangePasswordType,
    GenderServerType,
    GenderType,
    RegistrationType,
    ResponseDataType,
} from 'shared/types';

const toServerRegistrationModel = (data: RegistrationType) => ({
    email: data.email,
    password: data.password,
    re_password: data.passwordRepeat,
    customer_account: {
        first_name_field: data.firstName,
        last_name_field: data.lastName,
        postcode: data.postCode,
        country: data.country,
        city: data.city,
        street: data.street,
    },
});

const toServerChangePasswordModel = ({ uid, password, passwordRepeat, token }: ChangePasswordType) => ({
    uid,
    password,
    token,
    re_password: passwordRepeat,
});

const toServerAccountSettingsModel = ({
    userId,
    city,
    country,
    firstName,
    gender,
    lastName,
    postCode,
    street,
    emailNotification,
}: AccountSettingsType): AccountSettingsServerType => ({
    user_id: userId,
    country,
    first_name_field: firstName,
    last_name_field: lastName,
    gender: toServerGenderModel(gender),
    street,
    city,
    postcode: postCode,
    receive_email_notifications: emailNotification,
});

const toClientAccountSettingsModel = ({
    error,
    data,
    status,
}: ResponseDataType<AccountSettingsServerType[]>): ResponseDataType<AccountSettingsType> => ({
    error,
    status,
    data:
        data && data[0]
            ? {
                  city: data[0].city,
                  country: data[0].country,
                  firstName: data[0].first_name_field,
                  gender: toClientGenderModel(data[0].gender),
                  lastName: data[0].last_name_field,
                  postCode: data[0].postcode,
                  street: data[0].street,
                  userId: data[0].user_id,
                  emailNotification: data[0].receive_email_notifications,
              }
            : null,
});

const toServerGenderModel = (data: GenderType): GenderServerType => {
    switch (data) {
        case 'female':
            return 'F';
        case 'male':
            return 'M';
        case 'none':
            return 'N';
    }
};

const toClientGenderModel = (data: GenderServerType): GenderType => {
    switch (data) {
        case 'F':
            return 'female';
        case 'M':
            return 'male';
        case 'N':
            return 'none';
    }
};

export const AuthMapper = {
    toServerRegistrationModel,
    toServerChangePasswordModel,
    toServerAccountSettingsModel,
    toClientAccountSettingsModel,
    toServerGenderModel,
    toClientGenderModel,
};
