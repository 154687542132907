import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useModal, useNavigate } from 'shared/hooks';

import { AuthContext, ThemeContext } from 'shared/providers';

import { Button, LanguageSwitcher } from 'shared/components/ui';

import { DetectorHelper, ParseHelper } from 'shared/helpers';

import { MenuMocks } from 'shared/mocks';

import styles from './MobileMenu.module.scss';

type MobileMenuProps = {
    isOpen: boolean;
    onLinkClick: () => void;
};

export const MobileMenu: React.FC<MobileMenuProps> = ({
    isOpen,
    onLinkClick,
}) => {
    const { themeSettings } = useContext(ThemeContext);
    const isPreview = useMemo(() => DetectorHelper.isPreview(), []);
    const { t, i18n } = useTranslation();
    const { isAuthorized } = useContext(AuthContext);
    const navigate = useNavigate();
    const { openModal } = useModal();

    const onRedirect = (url: string) => {
        navigate(url);
        onLinkClick();
    };

    return (
        <div
            className={clsx(styles.root, { [styles['is-open']]: isOpen })}
            {...(themeSettings.colors && {
                style: {
                    backgroundColor: themeSettings.colors.background.secondary,
                },
            })}
        >
            <nav className={styles.menu}>
                <ul>
                    {MenuMocks.prepareHeaderLinks(i18n.language).map(
                        (item, index) => (
                            <li
                                key={`Mobile-menu-item-${index}`}
                                style={ParseHelper.prepareStyles({
                                    color: themeSettings.colors?.text
                                        .heroAndNavigation,
                                    fontFamily:
                                        themeSettings.typography?.paragraphBody
                                            .fontFamily,
                                })}
                                {...(!isPreview && {
                                    onClick: onRedirect.bind(null, item.url),
                                })}
                            >
                                {t(item.text)}
                            </li>
                        )
                    )}
                </ul>
            </nav>
            <LanguageSwitcher className={styles.switcher} />
            {!isAuthorized && (
                <>
                    <div className={styles.spacer} />
                    <div
                        className={styles.login}
                        onClick={openModal.bind(null, '#login')}
                        style={ParseHelper.prepareStyles({
                            color: themeSettings.colors?.text.heroAndNavigation,
                            fontFamily:
                                themeSettings.typography?.paragraphBody
                                    .fontFamily,
                        })}
                    >
                        {t('login')}
                    </div>
                    <Button
                        className={clsx(
                            styles['register-btn'],
                            'register-button'
                        )}
                        type="black"
                        onClick={openModal.bind(null, '#registration')}
                    >
                        {t('register')}
                    </Button>
                </>
            )}
        </div>
    );
};
