import React, { useContext } from 'react';
import { AuthContext, ThemeContext } from 'shared/providers';
import { Helmet } from 'react-helmet';
import styles from './EstateHeader.module.scss';
import { Container } from '../Container/Container';
import { Button, LanguageSwitcher, Logo, UserHeaderPanel } from 'shared/components/ui';
import { useModal } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export const EstateHeader = () => {
    const { themeSettings, isWhiteHeader } = useContext(ThemeContext);
    const { isAuthorized } = useContext(AuthContext);
    const { openModal } = useModal();
    const { t } = useTranslation();

    return (
        <>
            {themeSettings.favicon && (
                <Helmet>
                    <link rel="icon" href={themeSettings.favicon.src} />
                </Helmet>
            )}
            <header className={styles.root}>
                <Container className={clsx(styles.header, isWhiteHeader && styles.whiteHeader)}>
                    <div className={styles.logo}>
                        <Logo src={themeSettings.logo?.src} />
                    </div>
                    <div className={styles.rightSide}>
                        <LanguageSwitcher
                            showFlags={false}
                            dropdownIcon="dropdown-arrow"
                            fullNames
                            className={styles.languageSwitcher}
                        />
                        {!isAuthorized && (
                            <Button className={styles.button} onClick={openModal.bind(null, '#login')}>
                                {t('account-header-button')}
                            </Button>
                        )}
                        {isAuthorized && <UserHeaderPanel showIcons={false} />}
                    </div>
                </Container>
            </header>
        </>
    );
};
