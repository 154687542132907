import React, { useContext } from 'react';
import clsx from 'clsx';

import { ThemeContext } from 'shared/providers';

import styles from './MobileHamburger.module.scss';

type MobileHamburgerProps = {
    className?: string;
    onClick: () => void;
    isOpen: boolean;
};

export const MobileHamburger: React.FC<MobileHamburgerProps> = ({ onClick, isOpen, className }) => {
    const { themeSettings } = useContext(ThemeContext);

    if (themeSettings.template === 'estate') return null;

    return (
        <div
            className={clsx(styles.root, className, { [styles['is-open']]: isOpen })}
            onClick={onClick}
            {...(themeSettings.colors &&
                isOpen && { style: { backgroundColor: themeSettings.colors.buttons.secondary } })}
        >
            <Line isOpen={isOpen} />
            <Line isOpen={isOpen} />
            <Line isOpen={isOpen} />
        </div>
    );
};

const Line: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
    const { themeSettings } = useContext(ThemeContext);

    return (
        <div
            className={styles.line}
            {...(themeSettings.colors && {
                style: {
                    backgroundColor: isOpen
                        ? themeSettings.colors.background.primary
                        : themeSettings.colors.buttons.secondary,
                },
            })}
        />
    );
};
