import clsx from 'clsx';
import { IconsId } from 'shared/types/icons';

type IconProps = {
    name: IconsId;
    className?: string;
    style?: React.CSSProperties;
    refProp?: any;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const Icon: React.FC<IconProps> = ({ name, className, onClick, refProp = null, style }) => (
    <i ref={refProp} onClick={onClick} className={clsx(`icon-${name}`, className)} style={style} />
);
