import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'shared/providers';

import { useDeviceDetector, useNavigate } from 'shared/hooks';

import { Button, Typography } from 'shared/components/ui';
import styles from './PageNotFound.module.scss';

export const PageNotFound = () => {
    const { themeSettings } = useContext(ThemeContext);
    const { device } = useDeviceDetector();
    const navigate = useNavigate();
    const [image, setImage] = useState<string | null>(null);
    const { t } = useTranslation();

    const loadImage = useCallback(async() => {
            try {
                const image = await (await import(`assets/images/not-found/${themeSettings.template}.${device !== 'desktop' ? 'tablet' : 'desktop'}.svg`)).default;
                setImage(image);
            } catch(e:any) {
                console.error(e.message);
            }
    }, [themeSettings.template, device])

    useEffect(() => {
        loadImage();
    }, [loadImage])

    if(styles === null) return null;

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                {image !== null && <img className={styles.image} src={image} alt={`${themeSettings.template}-404`}/>}
                <Typography.Text className={styles.text}>{t('patience-text')}</Typography.Text>
            </div>
            <Button className={styles.button} type="black" onClick={() => navigate('/')}>{t('go-back')}</Button>
        </div>
    )
}