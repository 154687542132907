import { createContext, useState } from 'react';

type AppSettingsContextType = {
    shopName: string | null;
    mailChimpConnected?: boolean;
    setMailChimpConnectedStatus: (status: boolean) => void;
    setShopName: (name: string) => void;
    contactEmail: string | null;
    setContactEmail: (email: string) => void;
};

export const AppSettingsContext = createContext<AppSettingsContextType>({
    shopName: null,
    mailChimpConnected: false,
    setShopName: (data: string) => {},
    setMailChimpConnectedStatus: (data: boolean) => {},
    contactEmail: null,
    setContactEmail: () => {},
});

export const AppSettingsProvider: React.FC = ({ children }) => {
    const [shopName, setShopName] = useState<string | null>(null);
    const [contactEmail, setContactEmail] = useState<string | null>(null);
    const [mailChimpConnected, setMailChimpConnectedStatus] = useState<boolean>(false);

    return (
        <AppSettingsContext.Provider
            value={{
                shopName,
                contactEmail,
                setContactEmail,
                mailChimpConnected,
                setShopName,
                setMailChimpConnectedStatus,
            }}
        >
            {children}
        </AppSettingsContext.Provider>
    );
};
