import { createContext, useCallback, useEffect, useState } from 'react';
import { Stripe, loadStripe } from '@stripe/stripe-js';

import { api } from 'api';

import { AuthHelper } from 'shared/helpers';
import { AccountSettingsType } from 'shared/types';

type AuthContextType = {
    settings: AccountSettingsType | null;
    isAuthorized: boolean;
    stripe: null | Stripe;
    changeAuthorization: (state: boolean) => void;
    setSettings: (data: AccountSettingsType | null) => void;
};

export const AuthContext = createContext<AuthContextType>({
    settings: null,
    isAuthorized: false,
    stripe: null,
    changeAuthorization: (state: boolean) => {},
    setSettings: (data: AccountSettingsType | null) => {},
});

export const AuthProvider: React.FC = ({ children }) => {
    const [isAuthorized, changeAuthorization] = useState<boolean>(false);
    const [settings, setSettings] = useState<AccountSettingsType | null>(null);
    const [stripe, setStripe] = useState<Stripe | null>(null);

    const initStripe = useCallback(async (load: boolean) => {
        if (load) {
            const { data } = await api.settingService.getStripeAccountData();
            if (data) {
                const result = await loadStripe(data.publishable_key, {
                    stripeAccount: data.account_id,
                });
                setStripe(result);
            }
        } else {
            setStripe(null);
        }
    }, []);

    useEffect(() => {
        const token = AuthHelper.getAuthToken();
        changeAuthorization(() => !!token);
    }, []);

    useEffect(() => {
        initStripe(isAuthorized);
    }, [isAuthorized, initStripe]);

    return (
        <AuthContext.Provider
            value={{
                isAuthorized,
                settings,
                setSettings,
                changeAuthorization,
                stripe,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
