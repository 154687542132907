import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './Input.module.scss';

type InputProps = {
    haveError?:boolean;
    onlyNumbers?:boolean;
    borderColor?:string;
    inputSize?: 'medium' | 'big';
}

export const Input:React.FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({ className, inputSize = 'medium', haveError, onlyNumbers, borderColor, onChange, ...props }) => {

    const change = (event:React.ChangeEvent<HTMLInputElement>) => {
        if(onlyNumbers) {
            const regEx = /^[0-9\b]+$/;
            const value = event.target.value;

            if (!(regEx.test(value) || value === '')) return
        }

        onChange && onChange(event);
    }

    return (
        <input
            className={clsx(styles.root, styles[`is-${inputSize}`], 'input-text', className, {[styles['have-error']]: haveError})}
            onChange={change}
            {...props}
        />
    )
}