import React, {CSSProperties} from 'react';
import clsx from 'clsx';

import { Icon } from '../Icon/Icon';

import styles from './AuctionLabel.module.scss';

type AuctionLabelProps = {
    className?:string;
    hideIcon?:boolean;
    style?:CSSProperties;
}

const Hero:React.FC<AuctionLabelProps> = ({ hideIcon = false, className, style, children }) => (
    <div className={clsx(styles.hero, 'hero-label', className)} style={style}>
        {!hideIcon && <Icon name='sticker' />}
        {children}
    </div>
)

const Item:React.FC<AuctionLabelProps> = ({ hideIcon = false, className, style, children }) => (
    <div className={clsx(styles.item, 'auction-label', className)} style={style}>
        {!hideIcon && <Icon name='sticker' />}
        {children}
    </div>
)

export const AuctionLabel = {
    Hero,
    Item
}