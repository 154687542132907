import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from 'api';

import { AuthHelper } from 'shared/helpers';

import { Button, Icon, Modal, Typography } from 'shared/components/ui';

import { useModal } from 'shared/hooks';

import styles from './Success.module.scss';
import clsx from 'clsx';
import { ThemeContext } from 'shared/providers';

export const REGISTRATION_SUCCESS_MODAL_NAME = '#success-registration';
export const RESET_SUCCESS_MODAL_NAME = '#success-reset';

type BaseSeccessService = {
    changeResendSuccess: (state: boolean) => void;
};

type BaseSuccessProps = {
    ref?: any;
    title: string;
    text: string;
    bottomText: string;
    className?: string;
    isOpen: boolean;
    onClose: () => void;
    backToLogin: () => void;
    onResend: () => void;
};

const BaseSuccess: React.FC<BaseSuccessProps> = forwardRef(
    ({ isOpen, title, text, bottomText, onClose, backToLogin, onResend, className }, ref) => {
        const [showResend, changeResendState] = useState(false);
        const [resendSuccess, changeResendSuccess] = useState(false);
        const timer = useRef<NodeJS.Timeout | null>(null);
        const [time, setTime] = useState(20);
        const { t } = useTranslation();
        const { themeSettings } = useContext(ThemeContext);

        useImperativeHandle(ref, () => ({
            changeResendSuccess,
        }));

        const startTimer = () => {
            let sec = 20;
            timer.current = setInterval(() => {
                setTime(sec);
                sec--;
                if (sec < 0 && timer.current !== null) {
                    clearInterval(timer.current);
                    changeResendState(() => true);
                }
            }, 1000);
        };

        useEffect(() => {
            if (isOpen) {
                if (timer.current === null) startTimer();
            } else {
                // AuthHelper.removeUserEmail();
                if (timer.current !== null) clearTimeout(timer.current);
                changeResendState(() => false);
                changeResendSuccess(() => false);
            }
        }, [isOpen, timer]);

        return (
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={660}>
                <div className={clsx(styles.root, className)}>
                    <Icon className={styles.icon} name={themeSettings.template === 'estate' ? 'email' : 'success'} />
                    <Typography.Title htmlElement="h4" className={styles.title}>
                        {title}
                    </Typography.Title>
                    <p className={styles['sub-title']}>{text}</p>
                    <Button className={styles.button} onClick={backToLogin}>
                        {t('back-to-login')}
                    </Button>
                    <div className={styles['button-links']}>
                        {!resendSuccess && (
                            <>
                                <span className={clsx(styles.text, 'reset-bottom-text')}>
                                    {bottomText}
                                    {!showResend && ` ${t('resend-in')} ${time} ${t('sec')}.`}
                                </span>
                                {showResend && (
                                    <span className={styles.link} onClick={onResend}>
                                        {t('resend')}
                                    </span>
                                )}
                            </>
                        )}
                        {resendSuccess && <div className={styles.success}>{t('restore-email-notification')}</div>}
                    </div>
                </div>
            </Modal>
        );
    },
);

const Registration = () => {
    const baseComponent = useRef<BaseSeccessService | null>(null);
    const { isOpen, closeModal, openModal } = useModal(REGISTRATION_SUCCESS_MODAL_NAME);
    const { t } = useTranslation();

    const userEmail = AuthHelper.getUserEmail() || 'example@gmail.com';

    const onResent = async () => {
        try {
            await api.authService.resend(userEmail);
            baseComponent.current?.changeResendSuccess(true);
        } catch (e) {}
    };

    return (
        <BaseSuccess
            ref={baseComponent}
            title={`${t('sent-activation-to')} ${userEmail}!`}
            text={t('follow-link')}
            bottomText={t('no-link')}
            onClose={closeModal}
            isOpen={isOpen}
            backToLogin={openModal.bind(null, '#login')}
            onResend={onResent}
        />
    );
};

const Reset = () => {
    const baseComponent = useRef<BaseSeccessService | null>(null);
    const { isOpen, closeModal, openModal } = useModal(RESET_SUCCESS_MODAL_NAME);
    const { t } = useTranslation();

    const userEmail = AuthHelper.getUserEmail();

    const onResent = async () => {
        await api.authService.resetPassword(userEmail);
        baseComponent.current?.changeResendSuccess(true);
    };

    return (
        <BaseSuccess
            ref={baseComponent}
            title={t('password-reset-notice')}
            text={t('password-reset-notification')}
            bottomText={t('no-link')}
            onClose={closeModal}
            isOpen={isOpen}
            backToLogin={openModal.bind(null, '#login')}
            onResend={onResent}
            className={clsx('modal-success-reset')}
        />
    );
};

export const SuccessModal = {
    Reset,
    Registration,
};
