import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useDeviceDetector } from 'shared/hooks';

import styles from './Timer.module.scss';

const formatValue = (value: number): string => (value <= 9 ? `0${value}` : value.toString());

const calculateTimeLeft = (endsIn: Date, timerEnds?: () => void) => {
    let timeLeft = {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
    };

    if (!endsIn) return timeLeft;

    const difference = +endsIn - +new Date();

    if (difference > 0) {
        timeLeft = {
            days: formatValue(Math.floor(difference / (1000 * 60 * 60 * 24))),
            hours: formatValue(Math.floor((difference / (1000 * 60 * 60)) % 24)),
            minutes: formatValue(Math.floor((difference / 1000 / 60) % 60)),
            seconds: formatValue(Math.floor((difference / 1000) % 60)),
        };
    } else {
        timerEnds && timerEnds();
    }

    return timeLeft;
};

type TimerProps = {
    className?: string;
    endsIn: Date;
    size?: 'small' | 'big';
    timerEnds?: () => void;
    showUnits?: boolean;
    title?: string;
};

export const Timer: React.FC<TimerProps> = ({ className, size = 'small', endsIn, showUnits, timerEnds, title }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endsIn));
    const date = useMemo(() => new Date(endsIn), [endsIn]);
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(
                calculateTimeLeft(endsIn, () => {
                    clearInterval(timer);
                    timerEnds && timerEnds();
                }),
            );
        }, 1000);
        return () => clearInterval(timer);
    }, [endsIn, timerEnds]);

    const isEnded =
        timeLeft.seconds === '00' && timeLeft.minutes === '00' && timeLeft.hours === '00' && timeLeft.days === '00';

    const timer = (
        <div className={clsx(styles.timer, 'timer timer-boxes')}>
            {Number(timeLeft.days) > 0 && (
                <>
                    <NumberBox value={timeLeft.days} showUnits={showUnits} unit={t('days')} />
                    <Spacer />
                </>
            )}
            <NumberBox value={timeLeft.hours} showUnits={showUnits} unit={t('hrs')} />
            <Spacer />
            <NumberBox value={timeLeft.minutes} showUnits={showUnits} unit={t('min')} />
            <Spacer />
            <NumberBox value={timeLeft.seconds} showUnits={showUnits} unit={t('sec')} />
        </div>
    );

    const isEndedTimer = (
        <div className={clsx(styles.timer, 'date-boxes')}>
            <NumberBox value={formatValue(date.getDate())} />
            <div className={styles.spacer}>.</div>
            <NumberBox value={formatValue(date.getMonth() + 1)} />
            <div className={styles.spacer}>.</div>
            <NumberBox isYear value={date.getFullYear()} />
        </div>
    );

    return (
        <div className={clsx(styles.root, className, styles[`is-${size}`], 'timer')}>
            <div className={clsx(styles.title, 'timer-title')}>{isEnded ? t('ended') : title || t('ends-in')}</div>
            {isEnded ? isEndedTimer : timer}
        </div>
    );
};

const Spacer = () => <div className={clsx(styles.spacer, 'timer-spacer')}>:</div>;

type NumberBoxProps = {
    value: string | number;
    unit?: string;
    isYear?: boolean;
    showUnits?: boolean;
};

const NumberBox: React.FC<NumberBoxProps> = ({ value, showUnits, unit, isYear }) => {
    const { device } = useDeviceDetector();
    return (
        <div className={styles.number}>
            <div
                className={clsx(
                    styles.value,
                    {
                        [styles['is-year']]: isYear,
                        [styles['is-wide']]: !isYear && value > 100,
                    },
                    'number-value',
                )}
            >
                {value}
            </div>
            {device !== 'mobile' && showUnits && <div className={clsx(styles.unit, 'unit')}>{unit}</div>}
        </div>
    );
};
