import React, { createContext, useCallback, useEffect, useState } from 'react';

import { ToastItem, ToastItemProps, ToastType } from 'shared/components/ui';

//type ToastPositionType = 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left';

type ToastContextType = {
  open: (type:ToastType) => void;
};

export const ToastContext = createContext<ToastContextType>({
  open: (type:ToastType) => {}
});

//const POSITION:ToastPositionType = 'top-right';
const DELETE_AUTO:boolean = true;
const DISMISS_TIME:number = 5000;

export const ToastProvider: React.FC = ({ children }) => {
  const [list, setList] = useState<ToastItemProps[]>([]);

  const deleteToast = useCallback((id:number) => {
    setList(data => data.filter((_, index:number) => index !== id));
  }, [])

  const removeToastFromList = useCallback((id:number) => {
    setList(data => data.map(item => item.id === id ? { ...item, ...{ hideAnimation: true }} : item));
    setTimeout(deleteToast.bind(null, id), 500);
  }, [deleteToast])

  useEffect(() => {
    const interval = setInterval(() => {
        if (DELETE_AUTO && list.length) removeToastFromList(list[0].id);
    }, DISMISS_TIME);
    
    return () => clearInterval(interval);
}, [list, removeToastFromList]);

  const open = (type:ToastType) => {
      const item:ToastItemProps = {
        id: list.length,
        type,
        hideAnimation: false,
        onClose: removeToastFromList
      }

      setList([...list, ...[item]]);
  }

  return (
    <ToastContext.Provider value={{ open }}>
        <>
            {list.map((item, index) => <ToastItem key={`Toast-${index}`} {...item} />)}
            {children}
        </>
    </ToastContext.Provider>
  )
};