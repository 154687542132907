import React, { CSSProperties } from 'react';
import clsx from 'clsx';

/* import styles from './Container.module.scss'; */

type ContainerProps = {
    htmlElement?: 'div' | 'section' | 'footer' | 'header';
    className?: string;
    style?:CSSProperties;
};

export const Container: React.FC<ContainerProps> = ({ children, className, style, htmlElement = 'div' }) => (
    React.createElement(htmlElement, {className: clsx('container', className), style}, children)
);