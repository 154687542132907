import { formatPrice } from 'api/helpers';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Timer, Typography } from 'shared/components/ui';
import { AuctionItemType, AuctionProductInfoItemType } from 'shared/types';
import { BidBox } from '../BidBox/BidBox';
import { ProductInfoTable } from '../ProductInfoTable/ProductInfoTable';
import styles from './BidBlock.module.scss';
import { useTranslation } from 'react-i18next';
import { ParseHelper } from 'shared/helpers';

type BidBlockProps = {
    data: AuctionItemType;
    className?: string;
    onPlaceBid: (bid: number) => void;
};
export const BidBlock: React.FC<BidBlockProps> = ({ data, className, onPlaceBid }) => {
    const { highest_bid, winner, offer_minimum } = data;
    const { i18n } = useTranslation();

    const bid = winner ? highest_bid?.next_bid : offer_minimum;

    const productContent = useMemo(
        () =>
            ParseHelper.prepareLanguageData<AuctionProductInfoItemType>(
                data?.product.product_info,
                'product_name',
                i18n.language,
            ),
        [data, i18n.language],
    );

    return (
        <div className={clsx(styles['bid-block'], className)}>
            <Typography.Title htmlElement="h3" className={styles.price}>
                {bid && formatPrice(bid)}
            </Typography.Title>
            <Typography.Title htmlElement="h2" className={styles.title}>
                {productContent?.product_name}
            </Typography.Title>
            <Timer className={styles.timer} endsIn={new Date(data.ends_at)} />
            <BidBox
                auctionId={data.id}
                nextBid={highest_bid?.next_bid || 0}
                highestBid={highest_bid?.bid || 0}
                myBidWinning={data.highest_bid?.is_it_me || false}
                onPlaceBid={bid => onPlaceBid(bid)}
                endsIn={data.ends_at}
            />
            <ProductInfoTable product={data.product} />
        </div>
    );
};
