import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Typography } from 'shared/components/ui';
import { useModal } from 'shared/hooks';
import styles from './FinancialProofPendingModal.module.scss';

export const FINANCIAL_PROOF_PENDING_MODAL_NAME = '#financial-proof-pending';

export const FinancialProofPendingModal = () => {
    const { t } = useTranslation();
    const { isOpen, closeModal } = useModal(FINANCIAL_PROOF_PENDING_MODAL_NAME);

    return (
        <Modal
            className={styles.pendingModal}
            isOpen={isOpen}
            onClose={closeModal.bind(null, FINANCIAL_PROOF_PENDING_MODAL_NAME)}
            maxWidth={660}
        >
            <Icon name="proof-pending" />
            <Typography.Title htmlElement="h3" className={styles.title}>
                {t('financial-proof-pending-title')}
            </Typography.Title>
            <Typography.Text className={styles.text}>{t('financial-proof-pending-body')}</Typography.Text>
            <Button onClick={closeModal}>{t('financial-proof-pending-button')}</Button>
        </Modal>
    );
};
