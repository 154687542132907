import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { api } from 'api';
import { Typography, Preloader, Icon, NoData, Modal, Button } from '../../ui';
import { Container } from '../../grid';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AuctionLastFinancialProofType } from '../../../types';
import styles from './FinancialProofs.module.scss';

dayjs.extend(relativeTime);

const TITLES = ['object', 'document', 'status', 'date', 'remove'];

export const FinancialProofs = () => {
    const { t, i18n } = useTranslation();
    const [proofs, setProofs] = useState<AuctionLastFinancialProofType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<AuctionLastFinancialProofType | null>(null);

    const getMyProofs = () => {
        api.orderService
            .getMyProofs()
            .then(res => {
                if (res.data) setProofs(res.data);
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getMyProofs();
    }, []);

    const removeProof = () => {
        currentItem &&
            api.orderService
                .removeMyProof(currentItem?.id)
                .then(res => {
                    if (res.status === 204) {
                        getMyProofs();
                    }
                })
                .catch(err => console.log(err))
                .finally(() => setShowModal(false));
    };

    const openModal = (item: AuctionLastFinancialProofType) => {
        setCurrentItem(item);
        setShowModal(true);
    };

    if (loading) return <Preloader.Page />;

    return (
        <div className={styles.section}>
            <Container>
                <Typography.Title htmlElement="h1" className={styles.title}>
                    {t('financial-proofs')}
                </Typography.Title>
                {!proofs.length ? (
                    <NoData icon="fin-proof" title={t('proofs-empty-title')} text={t('proofs-empty-text')} />
                ) : (
                    <>
                        <div className={clsx(styles.row, styles.titles)}>
                            {TITLES.map(title => (
                                <div key={title} className={clsx(styles.tableTitle, styles[title])}>
                                    {t(`proofs-table-${title}`)}
                                </div>
                            ))}
                        </div>
                        <div className={styles.infoList}>
                            {proofs.map((item: any) => {
                                return (
                                    <div
                                        className={clsx(styles.row, styles.infoRow, {
                                            [styles.rowDeclined]: item.status === 'DECLINED',
                                        })}
                                        key={item.id}
                                    >
                                        <div className={styles.object}>
                                            {item.auction.product.product_info[i18n.language]?.product_name}
                                        </div>
                                        <div className={styles.document} title={item.file?.split('/').slice(-1)}>
                                            {item.file?.split('/').slice(-1)}
                                        </div>
                                        <div
                                            className={clsx(styles.status, {
                                                [styles.declined]: item.status === 'DECLINED',
                                                [styles.pending]: item.status === 'PENDING',
                                                [styles.accepted]: item.status === 'ACCEPTED',
                                                [styles.deleted]: item.status === 'DELETED',
                                            })}
                                        >
                                            {item.status}
                                        </div>
                                        <div className={styles.date}>{dayjs(item.uploaded_at).fromNow()}</div>
                                        <div className={styles.remove}>
                                            {item.status !== 'PENDING' && (
                                                <Icon
                                                    name={'trash'}
                                                    className={styles.removeIcon}
                                                    onClick={() => openModal(item)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
                <ConfirmModal
                    fileName={currentItem?.file?.split('/')?.slice(-1) as any}
                    isOpen={showModal}
                    onConfirm={removeProof}
                    onCancel={() => setShowModal(false)}
                />
            </Container>
        </div>
    );
};

type ConfirmModalProps = {
    isOpen: boolean;
    fileName?: string;
    onCancel: () => void;
    onConfirm: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, fileName, onCancel, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Modal className={styles.modal} isOpen={isOpen} onClose={onCancel} maxWidth={660} closeable={false}>
            <Icon name={'warning'} className={styles.modalIcon} />
            <Typography.Title htmlElement="h3" className={styles.modalTitle}>
                {t('proofs-modal-title')} {fileName}
            </Typography.Title>
            <div className={styles.actions}>
                <Button type="black" className={styles.outlineButton} onClick={onCancel}>
                    {t('proofs-modal-cancel')}
                </Button>
                <Button onClick={onConfirm}>{t('proofs-modal-confirm')}</Button>
            </div>
        </Modal>
    );
};
