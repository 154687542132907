import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import image from 'assets/images/logo.svg';
import styles from './Logo.module.scss';
import { useNavigate } from 'shared/hooks';

type LogoProps = {
    className?:string;
    src?: string;
}

export const Logo:React.FC<LogoProps> = ({ className, src }) => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <img onClick={() => navigate(`/${i18n.language}`)} className={clsx(styles.root, className)} src={src || image} alt="logo"/>
    )
}