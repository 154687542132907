import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import clsx from 'clsx';

import { Icon } from '../Icon/Icon';

import { ProductImageType } from 'shared/types';

import styles from './GalleryModal.module.scss';
import { useDeviceDetector } from '../../../hooks';

type GalleryModalProps = {
    data: ProductImageType[];
    isOpen: boolean;
    onClose: () => void;
};

export const GalleryModal: React.FC<GalleryModalProps> = ({ onClose, data, isOpen }) => {
    const mainSliderRef = useRef<null | Slider>(null);
    const previewSliderRef = useRef<null | Slider>(null);
    const mainSliderWrapperRef = useRef<null | HTMLDivElement>(null);

    const [slideParams, setSlideParams] = useState<null | { width: string; height: string }>(null);
    const [arrowsState, setArrowsState] = useState({ prev: false, next: true });
    const { device } = useDeviceDetector();
    const isMobile = device === 'mobile';

    useEffect(() => {
        if (mainSliderWrapperRef.current) {
            const { width, height } = mainSliderWrapperRef.current?.getBoundingClientRect();

            setSlideParams({
                width: `${width}px`,
                height: `${height}px`,
            });
        }
    }, [mainSliderWrapperRef]);

    const onSlideChange = (currentSlide: number, nextSlide: number) => {
        setArrowsState({
            prev: nextSlide >= 1,
            next: nextSlide < data.length - 1,
        });
    };

    return (
        <div className={clsx(styles.root, { [styles['is-open']]: isOpen })}>
            <Icon name="cross" className={styles.close} onClick={onClose} />
            <div className={styles['image-wrapper']} ref={mainSliderWrapperRef}>
                {slideParams && (
                    <Slider ref={mainSliderRef} className={styles['big-slider']} arrows={false} slidesToShow={1} fade>
                        {data.map(({ image, id }) => (
                            <img src={image} alt="product" key={`Gallery-slide-${id}`} className={styles.slide} />
                        ))}
                    </Slider>
                )}
            </div>
            <div className={styles['preview-wrapper']}>
                <div
                    className={clsx(styles['icon-left'], { [styles['is-disabled']]: !arrowsState.prev })}
                    onClick={() => arrowsState.prev && previewSliderRef.current?.slickPrev()}
                >
                    <Icon name="arrow-left" />
                </div>
                <Slider
                    className={styles['preview-slider']}
                    ref={previewSliderRef}
                    arrows={false}
                    slidesToShow={5}
                    infinite={true}
                    swipeToSlide
                    variableWidth={isMobile}
                    beforeChange={onSlideChange}
                    asNavFor={mainSliderRef.current || undefined}
                    dots={false}
                    focusOnSelect
                >
                    {data.map(({ image, id }) => (
                        <img
                            src={image}
                            alt="product"
                            className={styles['preview-slide']}
                            key={`Gallery-preview-slider-${id}`}
                        />
                    ))}
                </Slider>
                <div
                    className={clsx(styles['icon-right'], { [styles['is-disabled']]: !arrowsState.next })}
                    onClick={() => arrowsState.next && previewSliderRef.current?.slickNext()}
                >
                    <Icon name="arrow-right" />
                </div>
            </div>
            <div className={styles.background}></div>
        </div>
    );
};
