import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import styles from './Pagination.module.scss';

const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

const DOTS = '...';

type PaginationProps = {
    className?: string;
    totalCount: number;
    pageSize: number;
    siblingCount?: number;
    onPageChange: (page: number) => void;
};

export const Pagination: React.FC<PaginationProps> = ({
    totalCount,
    pageSize,
    siblingCount = 1,
    className,
    onPageChange,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPageCount = useMemo(
        () => Math.ceil(totalCount / pageSize),
        [totalCount, pageSize]
    );

    const paginationRange = useMemo(() => {
        const totalPageNumbers = siblingCount + 5;

        if (totalPageNumbers >= totalPageCount) return range(1, totalPageCount);

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }

        return [];
    }, [siblingCount, currentPage, totalPageCount]);

    if (currentPage === 0 || paginationRange.length < 2) return null;

    const onNext = () => {
        if (totalPageCount - 1 >= currentPage) {
            const value = currentPage + 1;
            onPageChange(value);
            setCurrentPage(value);
        }
    };

    const onPrevious = () => {
        if (currentPage >= 2) {
            const value = currentPage - 1;
            onPageChange(value);
            setCurrentPage(value);
        }
    };

    const onChangePage = (page: number) => {
        setCurrentPage(page);
        onPageChange(page);
    };

    return (
        <div className={clsx(styles.root, 'pagination', className)}>
            <div
                className={clsx(styles.left, 'pagination__control', {
                    [styles['is-active']]: currentPage >= 2,
                })}
                onClick={onPrevious}
            >
                <div className={styles['left-icon']} />
            </div>
            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS)
                    return (
                        <div
                            key={`pagination-number-${index}`}
                            className={clsx(styles.dot, 'pagination-dots')}
                        >
                            {pageNumber}
                        </div>
                    );

                return (
                    <div
                        key={`pagination-number-${index}`}
                        className={clsx(
                            styles.number,
                            {
                                [styles['is-active']]:
                                    currentPage === pageNumber,
                                'is-active-number': currentPage === pageNumber,
                            },
                            'pagination-number'
                        )}
                        onClick={() => onChangePage(pageNumber as number)}
                    >
                        {pageNumber}
                    </div>
                );
            })}
            <div
                className={clsx(styles.right, 'pagination__control', {
                    [styles['is-active']]: totalPageCount - 1 >= currentPage,
                })}
                onClick={onNext}
            >
                <div className={styles['right-icon']} />
            </div>
        </div>
    );
};
