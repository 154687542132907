import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Slider from 'react-slick';

import { AuctionItemType, AuctionProductInfoItemType } from 'shared/types';

import { GalleryModal } from 'shared/components/ui';
import { Container } from 'shared/components/grid';

import { LocalStorageHelper, ParseHelper, ScrollHelper } from 'shared/helpers';

import styles from './EstateAuctionDetail.module.scss';
import { BidBlock } from './_components/BidBlock/BidBlock';
import { BidInfo } from './_components/BidInfo/BidInfo';
import { AuthContext, ThemeContext } from 'shared/providers';

import { useDeviceDetector, useModal } from 'shared/hooks';
import {
    FinancialProofModal,
    FinancialProofPendingModal,
    FirstTimeBidModal,
    InfoModal,
} from 'shared/components/modals';
import { IconsId } from 'shared/types/icons';
import { formatPrice } from 'api/helpers';
import { api } from 'api';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';

type EstateAuctionDetailSectionProps = {
    data: AuctionItemType;
};

const sliderSettings = {
    className: styles.slider,
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
};
type InfoModalProps = {
    icon?: IconsId;
    color?: string;
    title?: string;
    text?: string;
    onConfirm?: () => void;
    onClose?: () => void;
};

export const EstateAuctionDetailSection: React.FC<EstateAuctionDetailSectionProps> = ({ data: auctionData }) => {
    const { t, i18n } = useTranslation();
    const { device } = useDeviceDetector();
    const sliderRef = useRef<null | Slider>(null);
    const [data, setData] = useState(auctionData);
    const [activeSlide, setActiveSlide] = useState(0);
    const [showAll, setShowAll] = useState(false);
    const [showAllBtn, setShowAllBtn] = useState(false);
    const [openGallery, setOpenGallery] = useState(false);
    const [modalData, setModalData] = useState<InfoModalProps>({
        icon: 'warning',
        color: '#F2A844',
        title: '',
        text: '',
        onConfirm: () => checkIfFirstTimeBid(),
        onClose: () => {},
    });
    // const [currentBid, setCurrentBid] = useState(0);
    const currentBid = useRef(0);
    const [placeBidLoading, setPlaceBidLoading] = useState(false);
    const { isAuthorized } = useContext(AuthContext);
    const { setIsWhiteHeader } = useContext(ThemeContext);
    const { openModal } = useModal();
    const isMobile = device === 'mobile';
    const cookies = new Cookies();

    useEffect(() => {
        if (openGallery) {
            ScrollHelper.lock();
        } else {
            ScrollHelper.unlock();
        }
        // Set header to white color
        setIsWhiteHeader(true);
        return () => setIsWhiteHeader(false);
    }, [openGallery, setIsWhiteHeader]);

    useEffect(() => {
        setData(auctionData);
    }, [auctionData]);

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(activeSlide);
        }
    }, [activeSlide]);

    const images = useMemo(() => {
        if (data && data.product && data.product.images) {
            return data.product.images.slice(0, 4);
        }

        return null;
    }, [data]);

    const productContent = useMemo(
        () =>
            ParseHelper.prepareLanguageData<AuctionProductInfoItemType>(
                data?.product.product_info,
                'product_name',
                i18n.language,
            ),
        [data, i18n.language],
    );

    const value = useMemo(() => {
        const unparsed = productContent?.description.map(block => block.description_body)[0] || '';
        let value: any = unparsed.includes('[{') ? JSON.parse(unparsed) : unparsed;

        if (!showAll && typeof value !== 'string') {
            const fistBlock = value.blocks[0];

            if (fistBlock.type === 'unstyled') {
                if (fistBlock.text.length > 500) {
                    const text = fistBlock.text.slice(0, 500).split(' ').slice(0, -1).join(' ') + '...';
                    fistBlock.text = text;
                    setShowAllBtn(true);
                } else {
                    if (value.blocks.length > 1) setShowAllBtn(true);
                }

                value.blocks = [fistBlock];
            }

            if (fistBlock.type === 'unordered-list-item') {
                const lessBlocks: any = [];

                value.blocks.forEach((block: any, index: number) => {
                    if (block.type === 'unordered-list-item' && index < 3) lessBlocks.push(block);
                });

                if (value.blocks.length > lessBlocks.length) setShowAllBtn(true);

                value.blocks = lessBlocks;
            }
        }

        if (!showAll && typeof value === 'string' && value.length > 500) {
            setShowAllBtn(true);
            return value.slice(0, 500) + '...';
        }

        return value;
    }, [showAll, productContent?.description]);

    // Download PDF logic. If not logged in, open login modal
    const downloadPDF = () => {
        if (!isAuthorized) {
            openModal('#bid-login');
            return;
        }
        if (data.product.pdf) {
            window.open(data.product.pdf, '_blank');
        }
    };

    // ON FINANCIAL PROOF UPLOADED SUCCESS
    const financialProofUploaded = () => {
        setTimeout(() => {
            openModal('#financial-proof-pending');
            reloadAuction();
        }, 300);
    };

    // PLACING THE BID VERIFICATION LOGIC
    const verifyBid = (bid: number) => {
        // If user is not authorized show a login modal
        if (!isAuthorized) {
            openModal('#bid-login');
            return;
        }
        // If user has no financial proof show a modal to upload his financial proof
        // Or if his financial proof is declined ask to upload a new one
        if (!data.last_financial_proof || data.last_financial_proof.status === 'DECLINED') {
            openModal('#financial-proof');
            return;
        }
        // If user's financial proof is pending show a modal informing him that it's pending
        if (data.last_financial_proof.status === 'PENDING') {
            openModal('#financial-proof-pending');
            return;
        }

        // setCurrentBid(bid); // Save current bid amount to internal state for future use
        currentBid.current = bid;

        const resetInfoModal = () => {
            setTimeout(() => {
                setModalData({
                    ...modalData,
                    onConfirm: () => checkIfFirstTimeBid(),
                    onClose: undefined,
                });
            }, 300);
        };

        if (data.last_financial_proof.amount < bid && data.highest_bid && bid >= data.highest_bid?.next_bid * 2) {
            setModalData({
                ...modalData,
                title: formatPrice(bid),
                text: t('bid-is-a-lot-bigger-then-minimum', { price: formatPrice(bid) }),
                onConfirm: () => {
                    setTimeout(() => {
                        setModalData({
                            ...modalData,
                            title: formatPrice(bid),
                            text: t('bid-is-bigger-then-allowed', { price: formatPrice(bid) }),
                            onConfirm: () => checkIfFirstTimeBid(),
                            onClose: resetInfoModal,
                        });
                    }, 300);

                    setTimeout(() => {
                        openModal('#info-modal');
                    }, 350);
                },
                onClose: resetInfoModal,
            });
            setTimeout(() => {
                openModal('#info-modal');
            }, 300);
            return;
        }
        // If users bid exceeds his financial proof amout show a modal for him to confirm the bid
        if (data.last_financial_proof.amount < bid) {
            setModalData({
                ...modalData,
                title: formatPrice(bid),
                text: t('bid-is-bigger-then-allowed', { price: formatPrice(bid) }),
            });
            openModal('#info-modal');
            return;
        }
        // If the bid is 2 times more than the current bid show a modal for him to confirm the bid
        if (data.highest_bid && bid >= data.highest_bid?.next_bid * 2) {
            setModalData({
                ...modalData,
                title: formatPrice(bid),
                text: t('bid-is-a-lot-bigger-then-minimum', { price: formatPrice(bid) }),
            });
            openModal('#info-modal');
            return;
        }

        checkIfFirstTimeBid(); // If all the verifications are passed, check if first time bidding
    };

    // Check if user is bidding for the first time on this auction
    // If so show a modal that asks him to confirm he understands all the rules
    // If not proceed with the bidding
    const checkIfFirstTimeBid = () => {
        if (data.first_time_bidding) {
            setTimeout(() => {
                openModal('#first-time-bid');
            }, 300);
        }
        if (!data.first_time_bidding)
            setTimeout(() => {
                placeBid();
            }, 300);
    };

    // Place an  actual bid after all verifications are passed
    const placeBid = async () => {
        if (placeBidLoading) return;
        setPlaceBidLoading(true);
        api.auctionService
            .bidPrice({
                amount: currentBid.current.toString(),
                auction: data.id,
            })
            .then(result => {
                if (result.data) {
                    const { is_winner, is_successful, is_equal_previous_bid, send_in, current_price } = result.data;
                    LocalStorageHelper.saveModalData(current_price);

                    if (is_successful && !is_equal_previous_bid) {
                        if (!is_winner) {
                            const expirationDate = dayjs()
                                .add(send_in ?? 0, 'second')
                                .toDate();
                            cookies.set('show_winning_message', currentBid.current, {
                                expires: expirationDate,
                            });
                            cookies.set('show_winning_message_id', data.id, {
                                expires: expirationDate,
                            });
                        }
                        openModal('#bid-winning');
                    } else {
                        openModal('#bid-losing');
                    }

                    reloadAuction();
                }
            })
            .catch(err => {
                openModal('#bid-losing');
            })
            .finally(() => {
                setPlaceBidLoading(false);
            });
    };

    const reloadAuction = async () => {
        const result = await api.auctionService.getSingle(data.id);
        if (result.data) setData(result.data);
    };

    return (
        <>
            {images && (
                <Slider {...sliderSettings} ref={sliderRef}>
                    {images.map((image, index) => (
                        <img className={styles.image} key={`slide-${index}`} src={image.image} alt="Estate" />
                    ))}
                </Slider>
            )}
            <Container className={styles.body}>
                <div className={styles.description}>
                    {images && (
                        <div className={styles['slider-preview']}>
                            {images.map((item, index) => (
                                <img
                                    className={clsx(styles.preview, { [styles['is-active']]: activeSlide === index })}
                                    src={item.image}
                                    key={`preview-${index}`}
                                    alt="Preview"
                                    onClick={setActiveSlide.bind(null, index)}
                                />
                            ))}
                            {data.product.images.length > 4 && (
                                <div className={styles['default-preview']} onClick={setOpenGallery.bind(null, true)}>
                                    +{data.product.images.length - images.length}
                                </div>
                            )}
                        </div>
                    )}
                    {!isMobile && (
                        <BidInfo
                            pdf={data.product.pdf}
                            showAll={showAll}
                            setShowAll={setShowAll}
                            showAllBtn={showAllBtn}
                            value={value}
                            downloadPDF={downloadPDF}
                        />
                    )}
                </div>
                <BidBlock data={data} className={styles['bid-block']} onPlaceBid={bid => verifyBid(bid)} />
                {isMobile && (
                    <BidInfo
                        pdf={data.product.pdf}
                        showAll={showAll}
                        setShowAll={setShowAll}
                        showAllBtn={showAllBtn}
                        value={value}
                        downloadPDF={downloadPDF}
                    />
                )}
            </Container>
            {data.product.images && (
                <GalleryModal
                    isOpen={openGallery}
                    data={data.product.images}
                    onClose={setOpenGallery.bind(null, false)}
                />
            )}
            {(!data.last_financial_proof || data.last_financial_proof.status === 'DECLINED') && (
                <FinancialProofModal auctionId={data.id} onUpload={financialProofUploaded} />
            )}
            <FinancialProofPendingModal />
            <InfoModal {...modalData} />
            <FirstTimeBidModal onClose={placeBid} />
        </>
    );
};
