import React, { useContext, useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ParseHelper } from "shared/helpers";

import { useModal } from "shared/hooks";

import { ThemeContext } from "shared/providers";

import {AuctionItemType, CustomizationShopModalsType} from "shared/types";

import { Button, Icon, Modal, Typography } from "shared/components/ui";

import styles from './ConfirmOfferModal.module.scss';

export const CONFIRM_BID_MODAL_NAME = '#confirm-bid';

type ConfirmOfferModalProps = {
    data?:string | null;
    onConfirm?:() => void;
    auction?: AuctionItemType | null;
}

export const ConfirmOfferModal:React.FC<ConfirmOfferModalProps> = ({ data, auction, onConfirm }) => {
    const { isOpen, closeModal } = useModal(CONFIRM_BID_MODAL_NAME);
    const [ icon, setIcon ] = useState<string>('');

    const { i18n } = useTranslation();
    const { customization } = useContext(ThemeContext);

    const content = useMemo(() => ParseHelper.prepareLanguageData<CustomizationShopModalsType>(customization?.shopModals?.settings, 'offerConfirmation.title', i18n.language), [customization, i18n.language]);

    useEffect(() => {
        (async () => {
            if(content) {
                const imageData = content.offerConfirmation.confirmButtonIcon;
                if(imageData) setIcon(await ParseHelper.perpareImageData(imageData));
            }
        })();
    }, [content])

    return (
        <Modal className={styles.root} isOpen={isOpen} onClose={closeModal} maxWidth={660}>
            {icon && <img src={icon} alt=""/>}
            {!icon && <Icon name="success" className={styles.icon} />}
            <Typography.Title className={styles.title} htmlElement="h2">
                {content?.offerConfirmation?.title || 'Thank you for your offer'}
            </Typography.Title>
            <Typography.Text className={styles.text} wysiwyg={(content?.offerConfirmation?.subtitle || 'We are about to register your offer to pay max. CHF {price}.- for<br /> PS5 DualSense-Controller').replace('{price}', data || '400').replace('{product}', auction?.product.name || 'Product Name' )} />
            <div className={styles.binding}>
                {content?.offerConfirmation?.bindingNotification || 'Your offer is binding'}
            </div>
            <Button className={styles.button} size="big" onClick={onConfirm}>{content?.offerConfirmation?.button || 'Confirm offer'}</Button>
        </Modal>
    )
}