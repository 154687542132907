import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/ui';
import { DetectorHelper, ParseHelper } from 'shared/helpers';
import { useNavigate } from 'shared/hooks';
import { ThemeContext } from 'shared/providers';
import { CustomizationHomeType } from 'shared/types';
import { IconsId } from 'shared/types/icons';
import { Container } from '../Container/Container';
import styles from './EstateFooter.module.scss';

export const EstateFooter = () => {
    const { t, i18n } = useTranslation();
    const { customization } = useContext(ThemeContext);
    const navigation = useNavigate();
    const isPreview = useMemo(() => DetectorHelper.isPreview(), []);

    const footerContent = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationHomeType>(
                customization?.homepage?.settings,
                'footer',
                i18n.language,
            ),
        [customization, i18n.language],
    );
    const links = useMemo(() => footerContent?.footer.staticLinks, [footerContent]);
    const socialLinks = useMemo(() => footerContent?.footer.socialLinks, [footerContent]);

    const openLink = (url: string) => {
        if (isPreview) return;
        const isExternal = DetectorHelper.isExternalLink(url);
        isExternal ? window.open(url, '_blank') : navigation(`/${i18n.language}${url}`);
    };

    return (
        <footer className={styles.root}>
            <Container className={styles.footerContent}>
                <div className={styles.madeBy}>
                    {t('made-with')} <Icon name="heart" /> {t('by-mindnow')}
                </div>
                <div className={styles.links}>
                    <ul>
                        {links?.disclaimer.url && (
                            <li onClick={() => openLink(links.disclaimer.url)}>
                                {links.disclaimer.name || 'Impressum'}
                            </li>
                        )}
                        {links?.termsAndConditions.url && (
                            <li onClick={() => openLink(links.termsAndConditions.url)}>
                                {links.termsAndConditions.name || 'Terms and conditions'}
                            </li>
                        )}
                        {links?.dataProtection.url && (
                            <li onClick={() => openLink(links.dataProtection.url)}>
                                {links.dataProtection.name || 'Data protection'}
                            </li>
                        )}
                    </ul>
                </div>
                <div className={styles.socialLinks}>
                    {socialLinks &&
                        Object.entries(socialLinks).map(
                            ([key, value], i) =>
                                value.isActive && (
                                    <div key={`footer-sociallink-${i}`} onClick={() => openLink(value.url)}>
                                        <Icon name={key as IconsId} />
                                    </div>
                                ),
                        )}
                </div>
            </Container>
        </footer>
    );
};
