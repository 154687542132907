import React from 'react';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { AccountSettingsType } from 'shared/types';

import { SettingsForm } from 'shared/components/forms';
import { Container } from 'shared/components/grid';
import { Typography } from 'shared/components/ui';

import styles from './Settings.module.scss';

type SettingsSectionProps = {
    initialValues: AccountSettingsType | null;
    onSubmit: (data: AccountSettingsType, helpers: FormikHelpers<AccountSettingsType>) => void;
};

export const SettingsSection: React.FC<SettingsSectionProps> = ({ initialValues, onSubmit }) => {
    const { t } = useTranslation();

    return (
        <Container className={styles.root}>
            <Typography.Title htmlElement="h2" className={styles.title}>
                {t('account-settings')}
            </Typography.Title>
            <SettingsForm
                onSubmit={onSubmit}
                {...(initialValues !== null && {
                    initialValues: initialValues,
                })}
                buttonText={t('save-changes')}
            />
        </Container>
    );
};
