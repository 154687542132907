import { http } from "api/http";

import { ResponseDataType, Subscription, SubscriptionResponse } from "shared/types";

const subscribe = async (data:Subscription):Promise<ResponseDataType<SubscriptionResponse>> => {
    return http.post<SubscriptionResponse>('/subscribers/subscribe', data);
}

export const createSubscribeService = () => ({
    subscribe
})