import React from 'react';
import clsx from "clsx";
// import { useTranslation } from 'react-i18next';

// import {Icon, Tooltip} from "../../../ui";

import styles from "../AuctionDetail.module.scss";

type OfferStatusBlockType = {
    isCurrent?:boolean;
    title:string;
    color:string;
    value:string | null;
    progressWidth:string;
}

const OfferStatusBlock:React.FC<OfferStatusBlockType> = ({ title, isCurrent = false, color, value, progressWidth }) => {
    // const { t } = useTranslation();

    return (
        <div className={styles['status-block']}>
            <div className={styles['status-block-top']}>
                <div className={styles['status-block-title']}>
                    {title}
                    {/*{isCurrent && (*/}
                    {/*    <Tooltip className={styles.tooltip} text={t('offer-tooltip')} width={210}>*/}
                    {/*        <Icon  className={styles['tooltip-icon']} name="info" />*/}
                    {/*    </Tooltip>*/}
                    {/*)}*/}
                </div>
                <div className={styles['status-block-value']}>{value} CHF.-</div>
            </div>
            <div className={clsx(styles['status-block-line'], 'status-block-line')}>
                <div className={clsx(styles['status-block-progress'], 'status-block-progress')} style={{ backgroundColor: color, width: progressWidth }} />
            </div>
        </div>
    )
}

export default OfferStatusBlock;