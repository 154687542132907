import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { useDeviceDetector, useModal } from 'shared/hooks';

import { AuthContext, ThemeContext } from 'shared/providers';

import { Container } from '../Container/Container';
import { Button, Logo, LanguageSwitcher, UserHeaderPanel } from 'shared/components/ui';

import { DetectorHelper, ParseHelper } from 'shared/helpers';

import { MenuMocks } from 'shared/mocks';

import styles from './Header.module.scss';
import { Helmet } from 'react-helmet';
import { EstateHeader } from '../EstateHeader/EstateHeader';

const hideInUrls = ['change-password'];

const onlyLogoUrls = ['order/'];

export const Header = () => {
    const { themeSettings } = useContext(ThemeContext);
    const menuRef = useRef<HTMLElement>(null);
    const location = useLocation();
    const { device } = useDeviceDetector();
    const { openModal } = useModal();
    const { t, i18n } = useTranslation();
    const { isAuthorized } = useContext(AuthContext);

    const [indicatorPosition, setIndicatorPosition] = useState(0);

    const isPreview = useMemo(() => DetectorHelper.isPreview(), []);
    const hide = useMemo(() => !!hideInUrls.find(url => location.pathname.includes(url)), [location]);
    const onlyLogo = useMemo(() => !!onlyLogoUrls.find(url => location.pathname.includes(url)), [location]);

    useEffect(() => {
        if (menuRef.current && device !== 'mobile') {
            const activeItem = menuRef.current.getElementsByClassName('active-menu-link')[0];
            const menuParams = menuRef.current.getBoundingClientRect();
            if (activeItem) {
                const { width, left } = activeItem.getBoundingClientRect();
                const position = Math.round(width / 2 - 3 + (left - menuParams.left));
                setIndicatorPosition(position);
            } else {
                setIndicatorPosition(0);
            }
        }
    }, [location, device]);

    if (hide) return null;

    // If theme is Estate, then show Estate header
    if (themeSettings.template === 'estate') return <EstateHeader />;

    return (
        <>
            {themeSettings.favicon && (
                <Helmet>
                    <link rel="icon" href={themeSettings.favicon.src} />
                </Helmet>
            )}
            <header
                className={clsx(styles.root, styles[`is-${themeSettings.template}`], {
                    [styles['only-logo']]: onlyLogo,
                })}
            >
                <Container
                    className={clsx(styles.container, {
                        [styles['not-authorized']]: !isAuthorized,
                    })}
                >
                    {onlyLogo && <Logo src={themeSettings.logo?.src} />}
                    {!onlyLogo && (
                        <>
                            <div className={styles['left-part']}>
                                {device !== 'mobile' && (
                                    <nav ref={menuRef} className={styles.menu}>
                                        <ul>
                                            {MenuMocks.prepareHeaderLinks(i18n.language).map((item, index) => (
                                                <li key={`Header-menu-item-${index}`}>
                                                    <NavLink
                                                        exact
                                                        activeClassName="active-menu-link"
                                                        to={item.url}
                                                        style={ParseHelper.prepareStyles({
                                                            color: themeSettings.colors?.text.heroAndNavigation,
                                                            fontFamily:
                                                                themeSettings.typography?.paragraphBody.fontFamily,
                                                            fontSize: themeSettings.typography?.paragraphBody.fontSize,
                                                        })}
                                                        {...(isPreview && {
                                                            onClick: e => e.preventDefault(),
                                                        })}
                                                    >
                                                        {t(item.text)}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                        {indicatorPosition !== 0 && (
                                            <div
                                                className={clsx(styles['nav-indicator'])}
                                                style={{
                                                    left: indicatorPosition,
                                                    borderBottom: `6px solid ${themeSettings.colors?.text.heroAndNavigation}`,
                                                }}
                                            />
                                        )}
                                    </nav>
                                )}
                                <Logo src={themeSettings.logo?.src} />
                            </div>
                            {device !== 'mobile' && (
                                <div className={styles['right-part']}>
                                    <LanguageSwitcher />
                                    {!isAuthorized && (
                                        <>
                                            <div
                                                className={styles.login}
                                                onClick={openModal.bind(null, '#login')}
                                                style={ParseHelper.prepareStyles({
                                                    color: themeSettings.colors?.text.heroAndNavigation,
                                                    fontFamily: themeSettings.typography?.paragraphBody.fontFamily,
                                                    fontSize: themeSettings.typography?.paragraphBody.fontSize,
                                                })}
                                            >
                                                {t('login')}
                                            </div>
                                            <Button
                                                className={clsx(styles['register-btn'], 'register-button')}
                                                type="black"
                                                onClick={openModal.bind(null, '#registration')}
                                            >
                                                {t('register')}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            )}
                            {isAuthorized && <UserHeaderPanel />}
                        </>
                    )}
                </Container>
            </header>
        </>
    );
};
