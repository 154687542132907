import jwt_decode from "jwt-decode";

const TOKEN_STORE_NAME = "authorization";
const NEW_USER_STORE_NAME = "new-user";

const setAuthToken = (token:string) => {
    localStorage.setItem(TOKEN_STORE_NAME, token);
}

const getAuthToken = () => localStorage.getItem(TOKEN_STORE_NAME);

const removeAuthToken = () => {
    localStorage.removeItem(TOKEN_STORE_NAME);
}

const isExpired = (token:string) => {
    if(!token) return true;

    const { exp } = decodeToken(token);

    if (exp && Date.now() >= exp * 1000) return false;

    return true;
}

const saveUserEmail = (email:string) => {
    localStorage.setItem(NEW_USER_STORE_NAME, email);
}

const removeUserEmail = () => {
    localStorage.removeItem(NEW_USER_STORE_NAME);
}

const getUserEmail = ():string => localStorage.getItem(NEW_USER_STORE_NAME) || '';

const decodeToken = (token:string):{ exp: number } => jwt_decode(token);

export const AuthHelper = {
    removeAuthToken,
    getAuthToken,
    setAuthToken,
    decodeToken,
    isExpired,
    saveUserEmail,
    getUserEmail,
    removeUserEmail
}