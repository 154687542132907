import React from 'react';

import { AuctionDiscountEnum, AuctionDiscountType, CustomizationImage } from 'shared/types';

import { DetectorHelper } from './detector';

const queryString = (data: string) => {
    const params = new URLSearchParams(data);
    return Object.fromEntries(params.entries());
};

const toTimerDate = (date: string, time: string) => new Date(`${date}T${time}.000Z`);

const discountedPrice = ({ value, type }: AuctionDiscountType, price: string) => {
    const data = Number(price);
    const discount = Number(value);
    let result: string = '';

    if (type === AuctionDiscountEnum.PERCENTAGE) {
        result = (Number(data) * ((100 - Number(discount)) / 100)).toFixed(2).toString();
    } else {
        result = (data - discount).toString();
    }

    const haveCoins = result.split('.')[1];

    return result + (haveCoins ? '' : '.00');
};

const prepareStyles = (styles: React.CSSProperties): React.CSSProperties => {
    const result: any = {};

    Object.entries(styles).forEach(([key, value]) => {
        if (value) result[key] = key === 'fontSize' && !DetectorHelper.isMobile() ? value + 'px' : value;
    });

    return result;
};

const removeCents = (value: any): string => {
    if (typeof value === 'string') {
        const cents = value.split('.')[1];
        if (Number(cents) > 0) return value;
        return value.split('.')[0];
    }
    return value;
};

const perpareImageData = async (image?: CustomizationImage): Promise<string> => {
    if (!image) return '';

    if (image instanceof File) return await fileToImage(image);

    return image.src;
};

const fileToImage = (image: File): Promise<string> =>
    new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => resolve((reader.result as string) || '');
        reader.readAsDataURL(image);
    });

const prepareLanguageData = <T>(data: any, path: string, lang: string): T | null => {
    if (!data) return null;

    if (DetectorHelper.isPreview()) return data[lang];

    const expected = `${lang}.${path}`.split('.').reduce((p, c) => p && (p[c] || null), data);

    if (expected) {
        return data[lang];
    } else {
        return (
            (Object.values(data).find(
                (langData: any) => !!path.split('.').reduce((p, c) => p && (p[c] || null), langData),
            ) as T) || null
        );
    }
};

const getInitials = (firstName: string, lastName: string) => {
    if (!firstName || !lastName) return null;

    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};

export const ParseHelper = {
    queryString,
    toTimerDate,
    discountedPrice,
    prepareStyles,
    removeCents,
    perpareImageData,
    prepareLanguageData,
    getInitials,
};
