import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { api } from 'api';

import { ThemeContext } from 'shared/providers';

import { CustomizationShopModalsType, ResetPasswordType } from 'shared/types';

import { AuthHelper, ParseHelper, ValidationHelper } from 'shared/helpers';

import { useModal } from 'shared/hooks';

import { Button, FormError, Input, InputError, InputWrapper, Modal, Typography } from 'shared/components/ui';

import styles from './ResetPassword.module.scss';

export const RESET_PASSWORD_MODAL_NAME = '#reset-password';

const formInitialValues = {
    email: ''
}

export const ResetPasswordModal = () => {
    const { isOpen, closeModal, openModal } = useModal(RESET_PASSWORD_MODAL_NAME);
    const [ error, setError ] = useState<null | string>(null);

    const { t, i18n } = useTranslation();
    const { customization } = useContext(ThemeContext);

    const content = useMemo(() => ParseHelper.prepareLanguageData<CustomizationShopModalsType>(customization?.shopModals?.settings, 'resetPassword.title', i18n.language), [customization, i18n.language]);

    const onSubmit = async(data:ResetPasswordType, helpers:FormikHelpers<ResetPasswordType>) => {
        const { status, error } = await api.authService.resetPassword(data.email);

        if(status === 200) {
            AuthHelper.saveUserEmail(data.email);
            helpers.resetForm();
            openModal('#success-reset');
        } else {
            if(error !== null) {
                setError(error.email[0]);
            } else {
                setError(t('reset-password-error'));
            }
        }
    }

    useEffect(() => {
        setError(() => null);
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} onClose={closeModal.bind(null, RESET_PASSWORD_MODAL_NAME)} maxWidth={660}>
            <Formik
                initialValues={formInitialValues}
                isInitialValid={false}
                validationSchema={ValidationHelper.schemas.onlyEmail}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, handleChange, handleBlur, values, isValid, errors, touched }) => (
                    <form className={clsx(styles.root, 'modal-reset-password')} onSubmit={handleSubmit}>
                        <Typography.Title htmlElement="h2">{content?.resetPassword?.title || 'Reset password'}</Typography.Title>
                        {error && <FormError>{error}</FormError>}
                        <InputWrapper label={t('email-address')}>
                            <Input
                                placeholder={t('enter-your-email')}
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                haveError={!!(errors.email && touched.email)}
                            />
                            {errors.email && touched.email && <InputError>{`${t('email-address')} ${t(errors.email)}`}</InputError>}
                        </InputWrapper>
                        <Button className={styles.button} size={'big'} htmlType="submit" isDisabled={!isValid}>{content?.resetPassword?.button || 'Reset password'}</Button>
                        <div className={clsx(styles['bottom-text'], 'modal-link')} onClick={openModal.bind(null, '#login')}>{t('login')}</div>
                    </form>
                )}
            </Formik>
        </Modal>
    )
}