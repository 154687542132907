import { AuthHelper } from 'shared/helpers';
import { ResponseDataType } from 'shared/types';
import { getHeaders, HeadersType } from './helpers';

type MethodsType = 'GET' | 'POST' | 'PUT' | 'DELETE';

type OptionsType = {
    method: MethodsType;
    headers: HeadersType;
    body?: any;
};

export const getClient = async <T>(url: string, method: MethodsType, body?: any) => {
    const options: OptionsType = {
        method: method,
        headers: getHeaders(),
    };

    if (body) {
        if (body instanceof FormData) {
            options['body'] = body;
        } else {
            options['body'] = JSON.stringify(body);
        }
    }

    const response = await fetch(url, options);
    let data = null;

    try {
        data = await response.json();
    } catch (e) {}

    const result: ResponseDataType<T> = {
        status: response.status,
        error: null,
        data,
    };

    if (result.status === 404 || result.status === 400) {
        result.error = data;
        result.data = null;
    }

    if (result.status === 401) {
        AuthHelper.removeAuthToken();
        document.location.href = '/';
    }

    return result;
};
