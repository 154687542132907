import React, { useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Container } from 'shared/components/grid';
import {
    AuctionItem,
    Button,
    Icon,
    OrderItem,
    Pagination,
    Typography,
} from 'shared/components/ui';
import { OrderItemType, PaginationApiType } from 'shared/types';

import styles from './Orders.module.scss';
import { ThemeContext } from 'shared/providers';
import { useNavigate } from 'shared/hooks';

type OrdersSectionProps = {
    data: PaginationApiType<OrderItemType>;
    onPageChange: (page: number) => void;
};

export const OrdersSection: React.FC<OrdersSectionProps> = ({
    data,
    onPageChange,
}) => {
    const { t } = useTranslation();
    const { themeSettings } = useContext(ThemeContext);
    const navigate = useNavigate();

    const emptyBox = (
        <div className={styles['empty-box']}>
            <div className={clsx(styles['icon-row'], 'empty-icon-row')}>
                <Icon className={styles.icon} name={'handbag'} />
            </div>
            <Typography.Title
                htmlElement="h4"
                className={styles['empty-title']}
            >
                {t('no-orders-title')}
            </Typography.Title>
            <Typography.Text
                className={clsx(styles['empty-text'], 'empty-text')}
            >
                {t('no-orders-text')}
            </Typography.Text>
            <Button
                onClick={() => navigate('/')}
                className={styles.button}
                size="big"
            >
                {t('to-auctions')}
            </Button>
        </div>
    );

    return (
        <section className={clsx(styles.section, 'orders-section')}>
            <Container className="orders-container">
                <Typography.Title htmlElement="h2" className={styles.title}>
                    {t('your-orders')} ({data.count})
                </Typography.Title>
                {!data.results.length ? (
                    emptyBox
                ) : (
                    <>
                        <div className={clsx(styles.list, 'orders-list')}>
                            {data.results.map((item, index) =>
                                themeSettings.template === 'minimal' ? (
                                    <AuctionItem
                                        order={item}
                                        orderCard={true}
                                        data={item.auction_detail}
                                    />
                                ) : (
                                    <OrderItem
                                        data={item}
                                        key={`order-item-${index}`}
                                    />
                                )
                            )}
                        </div>
                        <Pagination
                            className={styles.pagination}
                            totalCount={data.count}
                            pageSize={6}
                            onPageChange={onPageChange}
                        />
                    </>
                )}
            </Container>
        </section>
    );
};
