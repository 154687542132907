import { AuctionItemType } from './auction';

export enum OrderStatusEnum {
    Fulfilled = 1,
    Unfulfilled = 2,
    Cancelled = 3,
}

export enum OrderPaymentStatusEnum {
    Paid = 1,
    Unpaid = 2,
    Blocked = 3,
    Refund = 4,
}

export type StripePaymentDataType = {
    id: string;
};

export type OrderType = {
    auction: AuctionItemType;
    payment: string | null;
    amount: string;
    created_at: string;
    id: string;
    status: OrderStatusEnum;
    status_changed_at: string | null;
    product: string;
    user: string;
};

export type PaymentType = {
    amount: string;
    client_email: string;
    created_at: string;
    external_id: string;
    id: number;
    status: OrderPaymentStatusEnum;
};

export type OrderItemType = {
    amount: string;
    auction: string;
    auction_detail: AuctionItemType;
    created_at: string;
    id: number;
    payment: PaymentType;
    product: string;
    status: OrderStatusEnum;
    status_changed_at: string;
    user: string;
    invoice_file: string;
};
