import React from 'react';
import { IconsId } from 'shared/types/icons';
import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import styles from './NoData.module.scss';

type NoDataType = {
    icon?: IconsId;
    title?: string;
    text?: string;
};

export const NoData: React.FC<NoDataType> = ({ icon, title, text }) => {
    return (
        <section className={styles.noData}>
            {icon && <Icon name={icon} />}
            {title && (
                <Typography.Title htmlElement="h5" className={styles.title}>
                    {title}
                </Typography.Title>
            )}
            {text && <Typography.Text className={styles.text}>{text}</Typography.Text>}
        </section>
    );
};
