import {AuctionBidStatusType, CustomizationProductDetailType} from "../../../../types";
import { useTranslation } from 'react-i18next';
import React, {useCallback, useContext, useMemo} from "react";
import OfferStatusBlock from "./OfferStatusBlock";
import { ThemeContext } from 'shared/providers';
import styles from "../AuctionDetail.module.scss";
import { ParseHelper } from "shared/helpers";

type OfferStatusProps = {
    data?:AuctionBidStatusType | null;
    interval:string | null;
}

const OfferStatus:React.FC<OfferStatusProps> = ({ data, interval }) => {
    const { i18n } = useTranslation();
    const { customization } = useContext(ThemeContext);
    const content = useMemo(() => ParseHelper.prepareLanguageData<CustomizationProductDetailType>(customization?.productDetailPage?.settings, 'hero.graph1.title', i18n.language), [customization, i18n.language]);

    const getPercentFromNumber = useCallback((a:number, b:number) => {
        return Math.abs((Math.abs(Number(a) - Number(b)) - Number(b)) / Number(b) * 100);
    }, [])

    const currentPriceProgress = () => {
        if(data && data.user_greatest_offer) {
            const price = Number(data.user_greatest_offer);
            const previous = Number(data.current_price);

            if(price < previous) {
                return '100%';
            } else {
                return getPercentFromNumber(previous, price) + '%';
            }
        }
        return '0%';
    }

    const yourMaxOfferProgress = () => {
        if(data && data.user_greatest_offer) {
            const price = Number(data.user_greatest_offer);
            const previous = Number(data.current_price);

            if(previous < price) {
                return '100%';
            } else {
                return getPercentFromNumber(price, previous) + '%';
            }
        }

        return '0%';
    }

    if(!data) return null;

    return (
        <div className={styles.status}>
            <OfferStatusBlock
                title={content?.hero.graph1.title || 'Current price'}
                color={content?.hero.graph1.color || '#000'}
                value={data.current_price} progressWidth={currentPriceProgress()} isCurrent />
            <OfferStatusBlock
                title={content?.hero.graph2.title || 'Your max offer'}
                color={content?.hero.graph2.color || '#FF4C4C'}
                value={data.user_greatest_offer}
                progressWidth={yourMaxOfferProgress()} />
        </div>
    )
}

export default OfferStatus;