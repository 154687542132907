import React, {
    forwardRef,
    useImperativeHandle,
    useMemo,
    useState,
    useContext,
} from 'react';
import { FormikHelpers } from 'formik';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { Container } from 'shared/components/grid';

import { Subscription } from 'shared/types';

import { AppSettingsContext } from 'shared/providers';

import SuccessModal from './components/SuccessModal';
import Form from './components/Form';
import ContentBox from './components/ContentBox';

import styles from './Subscription.module.scss';

export type SubscriptionSectionService = {
    setIsSuccess: (state: boolean) => void;
};

type SubscriptionSectionProps = {
    ref?: any;
    onSubscribe?: (
        values: Subscription,
        helpers: FormikHelpers<Subscription>
    ) => void;
};

const hideInUrls = ['change-password', 'confirm-email', 'settings', 'order'];

export const SubscriptionSection: React.FC<SubscriptionSectionProps> =
    forwardRef(({ onSubscribe }, ref) => {
        const { mailChimpConnected } = useContext(AppSettingsContext);
        const location = useLocation();

        const [isSuccess, setIsSuccess] = useState(false);

        const hide = useMemo(
            () => !!hideInUrls.find((url) => location.pathname.includes(url)),
            [location]
        );

        useImperativeHandle(ref, () => ({
            setIsSuccess,
        }));

        if (hide || !mailChimpConnected) return null;

        return (
            <section className={clsx(styles.section, 'subscription-section')}>
                <SuccessModal isSuccess={isSuccess} />
                <Container className="subscription-container">
                    <ContentBox />
                    <Form onSubscribe={onSubscribe} />
                </Container>
            </section>
        );
    });
