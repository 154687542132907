import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import clsx from 'clsx';

import { api } from 'api';

import { AuthHelper, ParseHelper, ValidationHelper } from 'shared/helpers';

import { useModal, useNavigate } from 'shared/hooks';

import { ThemeContext } from 'shared/providers';

import { CustomizationShopModalsType, RegistrationType } from 'shared/types';

import {
    Button,
    FormError,
    Icon,
    Input,
    InputError,
    InputRequirementPopup,
    InputWrapper,
    Modal,
    Typography,
} from 'shared/components/ui';

import { RequirementsData } from 'shared/data';

import styles from './Registration.module.scss';

export const REGISTRATION_MODAL_NAME = '#registration';

const formInitialValues: RegistrationType = {
    email: '',
    password: '',
    passwordRepeat: '',
    city: '',
    country: '',
    firstName: '',
    lastName: '',
    postCode: '',
    street: '',
};

/* const selectYears = () => {
    const startYear = new Date().getFullYear();
    const years = [];
    for (let i = startYear; i > startYear - 90; i--) {
        years.push({ value: i.toString(), label: i.toString() });
    }
    return years;
}; */

const fullValidatorForSchema = async (values: RegistrationType): Promise<any> => {
    try {
        await ValidationHelper.schemas.registration.validate(values, {
            abortEarly: false,
            strict: false,
        });
    } catch ({ inner }) {
        return ValidationHelper.prepareFormErrors(inner);
    }
};

const prepareErrors = (errors: any): string[] => {
    if (Array.isArray(errors)) return errors;
    return [];
};

export const RegistrationModal = () => {
    const [emailExist, setEmailExist] = useState(false);
    const [showPassword, changeShowPassword] = useState(false);
    const { isOpen, closeModal, openModal } = useModal(REGISTRATION_MODAL_NAME);
    const windowWidth = useMemo(() => window.innerWidth, []);
    const { themeSettings } = useContext(ThemeContext);

    const { t, i18n } = useTranslation();
    const { customization } = useContext(ThemeContext);
    const navigate = useNavigate();

    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationShopModalsType>(
                customization?.shopModals?.settings,
                'register.title',
                i18n.language,
            ),
        [customization, i18n.language],
    );

    const onSubmit = async (data: RegistrationType, helpers: FormikHelpers<RegistrationType>) => {
        const { status } = await api.authService.registration(data);

        if (status !== 400) {
            AuthHelper.saveUserEmail(data.email);
            helpers.resetForm();
            if (themeSettings.template === 'estate') {
                navigate(`/${i18n.language}/email-validation`);
            } else {
                openModal('#success-registration');
            }
        }

        if (status === 400) {
            setEmailExist(true);
        }
    };

    useEffect(() => {
        setEmailExist(() => false);
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={closeModal.bind(null, REGISTRATION_MODAL_NAME)} maxWidth={660}>
            <Formik
                initialValues={formInitialValues}
                isInitialValid={false}
                validate={fullValidatorForSchema}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, handleChange, handleBlur, values, isValid, errors, touched }) => (
                    <form className={styles.root} onSubmit={handleSubmit}>
                        <Typography.Title htmlElement="h2">
                            {content?.register?.title || 'Registration'}
                        </Typography.Title>
                        {content && content.register.subtitle && (
                            <Typography.Text className={styles.subtitle} wysiwyg={content.register.subtitle} />
                        )}
                        {emailExist && <FormError>{t('register-form-error')}</FormError>}
                        <InputWrapper label={t('email-address')}>
                            <Input
                                name="email"
                                placeholder={`${t('enter-your')} ${t('email-address').toLowerCase()}`}
                                type="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                haveError={!!(errors.email && touched.email)}
                            />
                            {errors.email && touched.email && (
                                <InputError>{`${t('email-address')} ${t(errors.email)}`}</InputError>
                            )}
                        </InputWrapper>
                        <InputWrapper label={t('password')}>
                            <InputRequirementPopup
                                title={t('password-requirements')}
                                isVisible={values.password.length > 0 && !!errors.password}
                                list={RequirementsData.password}
                                errors={prepareErrors(errors.password)}
                                positionRight={windowWidth > 960 ? -200 : -50}
                            >
                                <Input
                                    className={styles.password}
                                    name="password"
                                    placeholder={`${t('enter-your')} ${t('password').toLowerCase()}`}
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    haveError={!!(errors.password && touched.password)}
                                />
                            </InputRequirementPopup>
                            <Icon
                                className={clsx(styles.eye, 'password-eye')}
                                name={showPassword ? 'close-eye' : 'eye'}
                                onClick={changeShowPassword.bind(null, !showPassword)}
                            />
                            {errors.password &&
                                touched.password &&
                                errors.password[0] !== 'uppercase' &&
                                errors.password[0] !== 'lowercase' &&
                                errors.password[0] !== 'min' &&
                                errors.password[0] !== 'special' &&
                                errors.password[0] !== 'number' && <InputError>{errors.password[0]}</InputError>}
                        </InputWrapper>
                        <InputWrapper label={t('repeat-password')}>
                            <Input
                                placeholder={t('repeat-password-placeholder')}
                                type={showPassword ? 'text' : 'password'}
                                name="passwordRepeat"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.passwordRepeat}
                                haveError={!!(errors.passwordRepeat && touched.passwordRepeat)}
                            />
                            {errors.passwordRepeat && touched.passwordRepeat && (
                                <InputError>{t(errors.passwordRepeat)}</InputError>
                            )}
                        </InputWrapper>
                        <InputWrapper label={t('first-name')}>
                            <Input
                                name="firstName"
                                placeholder={t('first-name-placeholder')}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                                haveError={!!(errors.firstName && touched.firstName)}
                            />
                            {errors.firstName && touched.firstName && (
                                <InputError>{`${t('first-name')} ${t(errors.firstName)}`}</InputError>
                            )}
                        </InputWrapper>
                        <InputWrapper label={t('last-name')}>
                            <Input
                                name="lastName"
                                placeholder={t('last-name-placeholder')}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                                haveError={!!(errors.lastName && touched.lastName)}
                            />
                            {errors.lastName && touched.lastName && (
                                <InputError>{`${t('last-name')} ${t(errors.lastName)}`}</InputError>
                            )}
                        </InputWrapper>
                        <InputWrapper label={t('country')}>
                            <Input
                                name="country"
                                placeholder={t('country-placeholder')}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.country}
                                haveError={!!(errors.country && touched.country)}
                            />
                            {errors.country && touched.country && (
                                <InputError>{`${t('country')} ${t(errors.country)}`}</InputError>
                            )}
                        </InputWrapper>
                        <div className={styles.double}>
                            <InputWrapper className={styles['double-wrapper']} label={t('city')}>
                                <Input
                                    name="city"
                                    placeholder={t('city-placeholder')}
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.city}
                                    haveError={!!(errors.city && touched.city)}
                                />
                                {errors.city && touched.city && (
                                    <InputError>{`${t('city')} ${t(errors.city)}`}</InputError>
                                )}
                            </InputWrapper>
                            <InputWrapper className={styles['double-wrapper']} label={t('post-code')}>
                                <Input
                                    name="postCode"
                                    placeholder={t('post-code-placeholder')}
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.postCode}
                                    haveError={!!(errors.postCode && touched.postCode)}
                                />
                                {errors.postCode && touched.postCode && (
                                    <InputError>{`${t('post-code')} ${t(errors.postCode)}`}</InputError>
                                )}
                            </InputWrapper>
                        </div>
                        <InputWrapper label={t('street')}>
                            <Input
                                name="street"
                                placeholder={t('street-placeholder')}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.street}
                                haveError={!!(errors.street && touched.street)}
                            />
                            {errors.street && touched.street && (
                                <InputError>{`${t('street')} ${t(errors.street)}`}</InputError>
                            )}
                        </InputWrapper>
                        {/*                         <InputWrapper
                            label={t('birth-year')}
                            subtitle={t('birth-year-subtitle')}
                        >
                            <FormikSelect
                                name="birthYear"
                                placeholder={t('birth-year-placeholder')}
                                value={{
                                    label: values.birthYear,
                                    value: values.birthYear,
                                }}
                                options={selectYears()}
                            />
                            {errors.birthYear && touched.birthYear && (
                                <InputError>{t(errors.birthYear)}</InputError>
                            )}
                        </InputWrapper>
                        <InputWrapper
                            label={t('gender')}
                            htmlElement="div"
                            className={styles['input-wrapper']}
                        >
                            <div className={styles['genders-options']}>
                                <InputWrapper
                                    className={styles['radio-wrapper']}
                                >
                                    <Radio
                                        type="radio"
                                        name="gender"
                                        value="male"
                                        checked={values.gender === 'male'}
                                        onChange={handleChange}
                                    >
                                        {t('male')}
                                    </Radio>
                                </InputWrapper>
                                <InputWrapper
                                    className={styles['radio-wrapper']}
                                >
                                    <Radio
                                        type="radio"
                                        name="gender"
                                        value="female"
                                        checked={values.gender === 'female'}
                                        onChange={handleChange}
                                    >
                                        {t('female')}
                                    </Radio>
                                </InputWrapper>
                                <InputWrapper
                                    className={styles['radio-wrapper']}
                                >
                                    <Radio
                                        type="radio"
                                        name="gender"
                                        value="none"
                                        checked={values.gender === 'none'}
                                        onChange={handleChange}
                                    >
                                        {t('not-choose')}
                                    </Radio>
                                </InputWrapper>
                            </div>
                        </InputWrapper> */}
                        <Button className={styles.button} size="big" htmlType="submit" isDisabled={!isValid}>
                            {content?.register?.button || 'Register'}
                        </Button>
                        <div className={styles['bottom-text']}>
                            <span className={clsx(styles.gray, 'no-account')}>{t('already-got-account')}</span>
                            <span className={clsx(styles.link, 'modal-link')} onClick={openModal.bind(null, '#login')}>
                                {t('login')}
                            </span>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};
