import { createAuthService } from './services/auth';
import { createThemeService } from './services/theme';
import { createSubscribeService } from './services/subscribe';
import { createAuctionService } from './services/auction';
import { createOrderService } from './services/order';
import { createSettingService } from './services/setting';

const createApi = () => ({
    authService: createAuthService(),
    themeService: createThemeService(),
    subscribeService: createSubscribeService(),
    auctionService: createAuctionService(),
    orderService: createOrderService(),
    settingService: createSettingService(),
});

export const api = createApi();
