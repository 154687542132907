import React, { CSSProperties } from 'react';
import clsx from 'clsx';

import styles from './DropdownTriangle.module.scss';

type DropdownTriangleProps = {
    className?:string;
    bgColor?:string;
    style?:CSSProperties;
    position?: 'top' | 'right' | 'bottom' | 'left';
}

export const DropdownTriangle:React.FC<DropdownTriangleProps> = ({ className, bgColor = '#fff', style, position = 'top' }) => (
    <div className={clsx(styles.root, className, styles[position])} style={style}>
        <svg width="19" height="7" viewBox="0 0 19 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.86377 6.76691L9.50013 0.751953L18.1365 6.76691L0.86377 6.76691Z" fill={bgColor} />
            <path d="M2.51046e-07 7L9 2L18 7L2.51046e-07 7Z" fill={bgColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.5 -3.61518e-07L19 6.76683L3.39757e-07 6.76683L9.5 -3.61518e-07ZM2.37037 6.0265L16.6296 6.0265L9.5 0.948082L2.37037 6.0265Z" fill="#E6E6E6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.03906 6.01498L17.9474 6.01498L19 6.76685L0 6.76685L1.03906 6.01498Z" fill={bgColor} />
        </svg>
    </div>
)