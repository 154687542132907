import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Modal, Typography } from 'shared/components/ui';

import styles from './SettingSuccess.module.scss';

type SettingSuccessModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const SettingSuccessModal: React.FC<SettingSuccessModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            maxWidth={660}
            onClose={onClose}
            className={styles.root}
        >
            <Icon name="success" className={styles.icon} />
            <Typography.Title htmlElement="h3" className={styles.title}>
                {t('account-saved')}
            </Typography.Title>
            <Button className={styles.button} onClick={onClose}>
                {t('to-account')}
            </Button>
        </Modal>
    );
};
