import React from 'react';
import clsx from 'clsx';

import styles from './LoadingIcon.module.scss';

type LoadingIconProps = {
    color?: string;
    className?: string;
};

export const LoadingIcon: React.FC<LoadingIconProps> = ({
    className,
    color,
}) => (
    <div className={clsx(styles.root, className)}>
        <div className={styles.dot} style={{ backgroundColor: color }} />
        <div className={styles.dot} style={{ backgroundColor: color }} />
        <div className={styles.dot} style={{ backgroundColor: color }} />
    </div>
);
