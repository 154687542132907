import React from 'react';
import clsx from 'clsx';

import styles from './SliderArrow.module.scss';

type ArrowProps = {
    className?:string;
    size: 'big' | 'small';
    onClick?: (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

type BaseArrowPrps = {
    type: 'left' | 'right';
}

const BaseArrow:React.FC<BaseArrowPrps & ArrowProps> = ({ type, size, className, ...props }) => (
    <div className={clsx(styles.root, 'slick-slider-arrow', styles[type], styles[size], className)} {...props} />
)

const Left:React.FC<ArrowProps> = ({ ...props }) => <BaseArrow type="left" {...props} />

const Right:React.FC<ArrowProps> = ({ ...props }) => <BaseArrow type="right" {...props} />

export const SliderArrow = {
    Left,
    Right
}