import { UserType } from './user';

export enum AuctionStatusEnum {
    Draft = 1,
    Scheduled = 2,
    Active = 3,
    Ended = 4,
}

export enum AuctionDiscountEnum {
    CURRENCY = 1,
    PERCENTAGE = 2,
}

export enum AuctionTypeEnum {
    BuyNow = 1,
    RegularAuction = 2,
}

export type AuctionType = {
    value: AuctionTypeEnum;
    display_name: string;
};

export type AuctionStatusType = {
    value: AuctionStatusEnum;
    display_name: string;
};

export type ProductImageType = {
    id: string;
    product_id: string;
    image: string;
};

export type AuctionItemType = {
    id: string;
    product: AuctionProductType;
    type: AuctionType;
    status: AuctionStatusType;
    is_archived: boolean;
    starts_at: string;
    ends_at: string;
    created_at: string;
    updated_at: string;
    uvp: string;
    offer_minimum: null | string;
    offer_interval: null | string;
    minimum_product_pcs: null | string;
    discount: AuctionDiscountType | null;
    minimum_order_price: string | null;
    minimal_winner_bid: string | null;
    highest_bid?: AuctionHighestBidType;
    first_time_bidding?: boolean;
    last_financial_proof?: AuctionLastFinancialProofType;
    winner?: string;
};

export type AuctionLastFinancialProofType = {
    amount: number;
    auction: AuctionItemType;
    declined_reason: string;
    file: string;
    id: number;
    status: 'ACCEPTED' | 'DECLINED' | 'PENDING' | 'DELETED';
    uploaded_at: string;
    user: UserType;
};

export type AuctionHighestBidType = {
    bid: number;
    bidder: number;
    is_it_me: boolean;
    next_bid: number;
};

export type AuctionDiscountType = {
    value: string;
    type: AuctionDiscountEnum;
};

export type AuctionProductType = {
    id: string;
    product_info: AuctionProductInfoType;
    images: ProductImageType[];
    published: boolean;
    name: string;
    quantity: number;
    uvp: string;
    shipping_cost: string;
    days_for_delivery: number;
    company: string;
    address?: string;
    availability?: string;
    available_at?: string;
    construction_year?: string;
    deleted_at?: string;
    floor?: number;
    gross_yield?: number;
    living_space?: number;
    number_of_rooms?: number;
    parking?: boolean;
    pdf?: string;
    plot_size?: number;
    property_type?: string;
    rental_income_per_year?: number;
    street_and_number?: string;
    city?: string;
    post_code?: string;
};

export type AuctionProductInfoType = {
    [key in string]?: AuctionProductInfoItemType;
};

export type AuctionProductInfoItemType = {
    SEO: AuctionProductSeoType;
    USP: AuctionProductUspType;
    description: AuctionProductDescriptionType[];
    product_catch_phrase: string;
    product_name: string;
};

export type AuctionProductSeoType = {
    page_body: string;
    page_title: string;
};

export type AuctionProductUspType = {
    USP_1: string;
    USP_2: string;
    USP_3: string;
};

export type AuctionProductDescriptionType = {
    description_body: string;
    description_type: string;
};

export type AuctionResponseType = {
    count: number;
    next: string | null;
    previous: string | null;
    results: AuctionItemType[];
};

export type AuctionPriceBidType = {
    price: string;
};

export type AuctionBidType = {
    amount: string;
    auction: string;
};

export type AuctionBidStatusType = {
    current_price: string;
    is_winner: boolean;
    is_successful: boolean;
    send_in?: number;
    is_equal_previous_bid?: boolean;
    user_greatest_offer: string;
};

export type ThemeCardsNumber = {
    traditional: number;
    modern: number;
    minimal: number;
    funky: number;
    classic: number;
    estate: number;
};

// AUCTION HISTORY TYPES
export type AuctionHistoryResponseType = {
    count: number;
    next: string | null;
    previous: string | null;
    results: AuctionHistoryItemType[];
    error?: string;
};

export type AuctionHistoryItemType = {
    amount: string;
    auction: string;
    bid_raise: number;
    created_at: string;
    id: number;
    is_successful: boolean;
    is_winner: boolean;
    user: UserType;
    user_cover_name: string;
};

export type AuctionHeroImage = {
    id: string;
    image: string;
    key: string;
};
