import React from 'react';
import clsx from 'clsx';

import { LoadingIcon } from '../LoadingIcon/LoadingIcon';
import styles from './Button.module.scss';

type ButtonProps = {
    className?: string;
    htmlType?: 'button' | 'submit';
    type?: 'primary' | 'black';
    size?: 'medium' | 'big';
    isDisabled?: boolean;
    isLoading?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const Button: React.FC<ButtonProps> = ({
    children,
    htmlType = 'button',
    type = 'primary',
    size = 'medium',
    isDisabled = false,
    isLoading = false,
    onClick,
    className,
}) => {
    return (
        <button
            className={clsx(
                className,
                'button',
                styles.button,
                styles[type],
                styles[`is-${size}`],
                { [styles['is-disabled']]: isDisabled }
            )}
            type={htmlType}
            disabled={isDisabled}
            onClick={onClick}
        >
            {isLoading && (
                <LoadingIcon color={type === 'primary' ? '#fff' : '#000'} />
            )}
            {!isLoading && children}
        </button>
    );
};
