import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuctionProductType } from 'shared/types';
import styles from './ProductInfoTable.module.scss';
import { formatPrice } from '../../../../../../api/helpers';
import dayjs from 'dayjs';

type ProductInfoTableProps = {
    product: AuctionProductType;
};

type ProductType = AuctionProductType & {
    [key: string]: any;
};

const getTypeDate = (type?: string) => {
    switch (type) {
        case 'Investment Property':
            return ['living_space', 'construction_year', 'rental_income_per_year', 'gross_yield'];
        case 'Condominium':
            return ['living_space', 'construction_year', 'floor', 'number_of_rooms'];
        case 'Single Family House':
            return ['plot_size', 'construction_year', 'living_space', 'number_of_rooms'];
        default:
            return [];
    }
};

export const ProductInfoTable: React.FC<ProductInfoTableProps> = ({ product }) => {
    const { t } = useTranslation();

    // Get the translated string for the availability
    const getAvailabilityString = (name: string) => {
        switch (name) {
            case 'now':
                return t('availability-now');
            case 'agreement':
                return t('availability-agreement');
            case 'planned':
                return t('availability-planned');
            default:
                return '';
        }
    };

    const productData = getTypeDate(product.property_type);
    const formatData = (key: string) => {
        const value = (product as ProductType)[key];
        switch (key) {
            case 'living_space':
            case 'plot_size':
                return `${value} m2`;
            case 'gross_yield':
                return `${value} %`;
            case 'rental_income_per_year':
                return formatPrice(value);
            default:
                return value;
        }
    };
    return (
        <div className={styles.info}>
            <table>
                <tbody>
                    <tr>
                        <td>{t('address')}</td>
                        <td>
                            {product.street_and_number}, {product.post_code} {product.city}
                        </td>
                    </tr>

                    {productData.map(item => {
                        return (
                            <tr key={item}>
                                <td>{t(item.replaceAll('_', '-'))}</td>
                                <td>{formatData(item)}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td>{t('availability')}</td>
                        <td>
                            {product.available_at
                                ? `${t('plannedAt')} ${dayjs(product.available_at).format('DD.MM.YYYY')}`
                                : product.availability
                                ? getAvailabilityString(product.availability)
                                : ''}
                        </td>
                    </tr>
                    {product.property_type === 'Condominium' && (
                        <tr>
                            <td>{t('parking')}</td>
                            <td>{product.parking ? t('available') : t('notAvailable')}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
