import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ParseHelper } from 'shared/helpers';

import { ThemeContext } from 'shared/providers';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

import styles from './ToastItem.module.scss';

export type ToastType = 'success';

export type ToastItemProps = {
    hideAnimation: boolean;
    type: ToastType;
    id: number;
    onClose: (id: number) => void;
};

export const ToastItem: React.FC<ToastItemProps> = ({ type, id, hideAnimation, onClose }) => {
    const { themeSettings } = useContext(ThemeContext);
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.root, { [styles['hide']]: hideAnimation }, styles[type])}>
            <Icon name="success" className={styles['success-icon']} />
            <Icon name="cross" className={styles['cross-icon']} onClick={onClose.bind(null, id)} />
            <div className={styles.body}>
                <Typography.Title
                    htmlElement="h6"
                    className={styles.title}
                    style={ParseHelper.prepareStyles({
                        fontFamily: themeSettings.typography?.heading.fontFamily,
                    })}
                >
                    {t('success')}!
                </Typography.Title>
                <Typography.Text
                    style={ParseHelper.prepareStyles({
                        fontFamily: themeSettings.typography?.paragraphBody.fontFamily,
                    })}
                >
                    {t('activation-link-text')}
                </Typography.Text>
            </div>
        </div>
    );
};
