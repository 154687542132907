import { formatPrice } from 'api/helpers';
import clsx from 'clsx';
import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Timer, Typography } from 'shared/components/ui';
import { ParseHelper } from 'shared/helpers';
import { ThemeContext } from 'shared/providers';
import { AuctionItemType, AuctionProductInfoItemType, CustomizationHomeType } from 'shared/types';
import styles from './EstateAuctionItem.module.scss';

type EstateAuctionsItemProps = {
    data: AuctionItemType;
    showBid?: boolean;
};

type PropertyType = 'Investment Property' | 'Condominium' | 'Single Family House';
type LanguageType = 'en' | 'de' | 'fr' | 'it';

const typeTranslations = {
    'Investment Property': {
        en: 'Investment Property',
        de: 'Renditeobjekt',
        fr: 'Immeuble de rapport',
        it: 'Investimento immobiliare',
    },
    Condominium: {
        en: 'Condominium',
        de: 'Eigentumswohnung',
        fr: 'Copropriété',
        it: 'Appartamento di proprietà',
    },
    'Single Family House': {
        en: 'Single Family House',
        de: 'Einfamilienhaus',
        fr: 'Maison individuelle',
        it: 'Casa indipendente',
    },
};

export const EstateAuctionItem: React.FC<EstateAuctionsItemProps> = ({ data, showBid }) => {
    const { customization } = useContext(ThemeContext);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationHomeType>(
                customization?.homepage?.settings,
                'hero.timerTitle',
                i18n.language,
            ),
        [customization, i18n.language],
    );

    const productContent = useMemo(
        () =>
            ParseHelper.prepareLanguageData<AuctionProductInfoItemType>(
                data?.product.product_info,
                'product_name',
                i18n.language,
            ),
        [data, i18n.language],
    );
    const bid = data.winner ? data.highest_bid?.bid : data.offer_minimum;
    if (!data) return null;

    return (
        <div className={styles.auctionItem}>
            <div className={styles.auctionItemImage}>
                <div className={styles.propertyType}>
                    {typeTranslations[data.product.property_type as PropertyType][i18n.language as LanguageType]}
                </div>
                <img src={data.product.images[0]?.image} alt={data.product.product_info[i18n.language]?.product_name} />
            </div>
            <div className={styles.auctionItemContent}>
                {showBid ? (
                    <div className={styles.lastBid}>
                        <div className={clsx(!data.highest_bid?.is_it_me && styles.userPrice)}>
                            {bid && formatPrice(bid)}
                        </div>
                        <span className={styles.lastBidText}>{t('current-price-offered')}</span>
                        <span className={styles.lastBidUser}>
                            {!data.highest_bid?.is_it_me && ` ${t('current-price-user')} ${data.highest_bid?.bidder}`}
                        </span>
                        <span>{data.highest_bid?.is_it_me && ` ${t('current-price-me')}`}</span>
                    </div>
                ) : (
                    <div className={styles.auctionPrice}>
                        {data.highest_bid
                            ? formatPrice(data.highest_bid.next_bid)
                            : formatPrice(data.minimal_winner_bid)}
                    </div>
                )}

                <Typography.Title htmlElement="h3">
                    {productContent?.product_name}
                    {/* {data.product.product_info[i18n.language]?.product_name} */}
                </Typography.Title>
                <PropertyDetails
                    city={data.product.city}
                    living_space={data.product.living_space}
                    rooms={data.product.number_of_rooms}
                    investment={data.product.property_type === 'Investment Property'}
                    price={data.product.rental_income_per_year}
                />

                <Timer
                    className={clsx(styles.timer, 'auction-item__timer')}
                    endsIn={new Date(data.ends_at)}
                    title={content?.hero.timerTitle || 'Ends in'}
                />
                <Button className={styles.button} onClick={() => history.push(`/${i18n.language}/auction/${data.id}`)}>
                    {content?.productCards.CTAText || t('see-details-button')}
                </Button>
            </div>
        </div>
    );
};

type PropertyDetailsProps = {
    city?: string;
    living_space?: number;
    rooms?: number;
    price?: number;
    investment?: boolean;
};

export const PropertyDetails: React.FC<PropertyDetailsProps> = ({
    city,
    living_space,
    rooms,
    investment = false,
    price,
}) => {
    return (
        <div className={styles.details}>
            {investment && (
                <>
                    <div className={styles.detailItem}>
                        <Icon name="area" />
                        {living_space} m2
                    </div>
                    <div className={styles.detailItem}>
                        <Icon name="investment" />
                        {formatPrice(price)}
                    </div>
                </>
            )}
            {!investment && (
                <>
                    <div className={styles.detailItem}>
                        <Icon name="location" />
                        {city}
                    </div>
                    <div className={styles.detailItem}>
                        <Icon name="area" />
                        {living_space} m2
                    </div>
                    <div>
                        <Icon name="rooms" />
                        {rooms}
                    </div>
                </>
            )}
        </div>
    );
};
