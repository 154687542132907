import React from 'react';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import { Icon, Typography } from '../../../ui';

import styles from '../Subscription.module.scss';

type SuccessModalProps = {
    isSuccess: boolean;
};

const SuccessModal: React.FC<SuccessModalProps> = ({ isSuccess }) => {
    const { t } = useTranslation();

    return (
        <div
            className={clsx(styles.success, {
                [styles['is-success']]: isSuccess,
            })}
        >
            <Icon name="success" />
            <Typography.Title htmlElement={'h4'}>
                {t('in-touch')}
            </Typography.Title>
        </div>
    );
};

export default SuccessModal;
