import React, { useContext } from 'react';
import clsx from 'clsx';

import { ParseHelper } from 'shared/helpers';

import { ThemeContext } from 'shared/providers';

import styles from './FormError.module.scss';

type FormErrorPropr = {
    className?:string;
}

export const FormError:React.FC<FormErrorPropr> = ({ className, children }) => {
    const { themeSettings } = useContext(ThemeContext);

    return (
        <div className={clsx(styles.root, className)} style={ParseHelper.prepareStyles({
            fontFamily: themeSettings.typography?.paragraphBody.fontFamily,
            fontSize: themeSettings.typography?.paragraphBody.fontSize,
        })}>{children}</div>
    )
}