import React, {memo, useRef} from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {useDeviceDetector} from "../../../hooks";

import { Icon } from '../Icon/Icon';
import { DropdownTriangle } from '../DropdownTriangle/DropdownTriangle';

import styles from './InputRequirementPopup.module.scss';

type InputRequirementPopupProps = {
    className?:string;
    isVisible: boolean;
    title:string;
    list: {key:string; name:string}[];
    errors: string[];
    positionRight?:number;
}

export const InputRequirementPopup:React.FC<InputRequirementPopupProps> = memo(({ errors, list, isVisible, title, positionRight = 0, className, children }) => {
    const wrapper = useRef<HTMLDivElement | null>(null);
    const { device } = useDeviceDetector();
    const { t } = useTranslation();

    return (
        <div ref={wrapper} className={clsx(styles.root, className, { [styles['is-visible']]: isVisible })}>
            {children}
            {wrapper.current && (
                <div className={clsx(styles.body, 'requirement-popup')} style={device !== 'mobile' ? { left: wrapper.current?.getBoundingClientRect().width - 264 - positionRight, top: wrapper.current?.getBoundingClientRect().height + 15} : {right: -8, top: 46}}>
                    <DropdownTriangle className={styles.triangle} style={{ right: device !== 'mobile' ? 20 - positionRight : 16  }} />
                    <div className={styles.title}>{title}</div>
                    <ul>
                        {list.map(({ key, name }) => {
                            const valid = errors.includes(key);
                            return (
                                <li className={clsx({[styles['is-invalid']]: valid})} key={key}>
                                    <Icon name={valid ? 'cross' : 'check'} />{t(name)}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
        </div>
    )
})