import React, { CSSProperties, useContext, useMemo } from 'react';
import clsx from 'clsx';

import { ThemeContext } from 'shared/providers';

import styles from './Typography.module.scss';
import { useTranslation } from 'react-i18next';

type TypographyProps = {
    className?: string;
    style?: CSSProperties;
};

type TitleProps = {
    htmlElement: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    lang?: boolean;
};

type TextProps = {
    wysiwyg?: string;
};

const Title: React.FC<TypographyProps & TitleProps> = ({ className, htmlElement, children, style, lang = false }) => {
    const { themeSettings } = useContext(ThemeContext);
    const { i18n } = useTranslation();

    return React.createElement(
        htmlElement,
        {
            className: clsx(htmlElement, styles.title, styles[`is-${themeSettings.template}`], className),
            style,
            lang: lang ? i18n.language : undefined,
        },
        children,
    );
};

const Text: React.FC<TypographyProps & TextProps> = ({ className, children, wysiwyg, style }) => {
    const { themeSettings } = useContext(ThemeContext);
    const classNames = useMemo(
        () => clsx(styles.text, styles[`is-${themeSettings.template}`], className),
        [className, themeSettings.template],
    );

    if (wysiwyg)
        return (
            <div
                className={clsx(classNames, styles.wysiwyg)}
                dangerouslySetInnerHTML={{ __html: wysiwyg }}
                style={style}
            />
        );

    return (
        <p className={classNames} style={style}>
            {children}
        </p>
    );
};

export const Typography = {
    Title,
    Text,
};
