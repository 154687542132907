import React from 'react';
import { UserName } from './UserName';
import { formatPrice } from '../../../../api/helpers';
import dayjs from 'dayjs';
import { AuctionHistoryItemType } from 'shared/types';
import styles from './EstateBiddingHistory.module.scss';

export const MobileHistoryTable: React.FC<{ data: AuctionHistoryItemType[] }> = ({ data }) => {
    return (
        <>
            {data.map(bid => {
                return (
                    <div className={styles.row}>
                        <div className={styles.innerRow}>
                            <UserName user={bid.user} userCoverName={bid.user_cover_name} />
                            <div>{formatPrice(bid.amount)}</div>
                        </div>
                        <div className={styles.innerRow}>
                            <div>{dayjs(bid.created_at).fromNow()}</div>
                            <div>+ {formatPrice(bid.bid_raise)}</div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};
