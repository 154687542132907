import React, { useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Formik, FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { LOGIN_MODAL_NAME } from 'shared/components/modals';

import { api } from 'api';

import { ChangePasswordFormValuesType } from 'shared/types';
import { useNavigate } from 'shared/hooks';
import { ValidationHelper } from 'shared/helpers';

import {
    Button,
    FormError,
    Icon,
    Input,
    InputError,
    InputRequirementPopup,
    InputWrapper,
    Logo,
    Typography,
} from 'shared/components/ui';

import illustration from 'assets/images/change-password.svg';
import illustrationModern from 'assets/images/change-password-modern.svg';
import illustrationMinimal from 'assets/images/change-password-minimal.svg';
import illustrationEstate from 'assets/images/change-password-estate.svg';

import { RequirementsData } from 'shared/data';

import { ThemeContext } from '../../../providers';
import { Container } from '../../grid';
import styles from './ChangePassword.module.scss';

const formInitialValues = {
    password: '',
    passwordRepeat: '',
};

const fullValidatorForSchema = async (
    values: ChangePasswordFormValuesType
): Promise<any> => {
    try {
        await ValidationHelper.schemas.changePassword.validate(values, {
            abortEarly: false,
            strict: false,
        });
    } catch ({ inner }) {
        return ValidationHelper.prepareFormErrors(inner);
    }
};

const prepareErrors = (errors: any): string[] => {
    if (Array.isArray(errors)) return errors;
    return [];
};

export const ChangePasswordSection = () => {
    const { themeSettings } = useContext(ThemeContext);
    const [error, setError] = useState(false);
    const [showPassword, changeShowPassword] = useState(false);
    const { t, i18n } = useTranslation();
    const { token, uid } = useParams<{ token: string; uid: string }>();
    const windowWidth = useMemo(() => window.innerWidth, []);
    const navigate = useNavigate();

    const illustrationSrc = useMemo(() => {
        if(themeSettings.template === 'modern') return illustrationModern;
        if(themeSettings.template === 'minimal') return illustrationMinimal;
        if(themeSettings.template === 'estate') return illustrationEstate;

        return illustration
    }, [themeSettings])

    const onSubmit = async (
        data: ChangePasswordFormValuesType,
        helpers: FormikHelpers<ChangePasswordFormValuesType>
    ) => {
        const { status } = await api.authService.changePassword({
            ...data,
            ...{ token, uid },
        });

        if (status !== 400) {
            helpers.resetForm();
            navigate(`/${i18n.language}${LOGIN_MODAL_NAME}`);
        } else {
            setError(() => true);
        }
    };

    return (
        <div className={clsx(styles.page, 'section-change-password')}>
            <Container className={clsx(styles['logo-wrapper'], 'logo-wrapper')}>
                <Logo src={themeSettings.logo?.src} />
            </Container>
            <Container className={styles.main}>
                <div className={clsx(styles.left, 'left')}>
                    <img
                        src={illustrationSrc}
                        alt=""
                    />
                </div>
                <div className={clsx(styles.right, 'right')}>
                    <Formik
                        initialValues={formInitialValues}
                        isInitialValid={false}
                        validate={fullValidatorForSchema}
                        onSubmit={onSubmit}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            isValid,
                            errors,
                            touched,
                        }) => (
                            <form
                                className={styles.body}
                                onSubmit={handleSubmit}
                            >
                                <Typography.Title htmlElement="h2">
                                    {t('enter-new-password')}
                                </Typography.Title>
                                {error && (
                                    <FormError>{t('link-expired')}</FormError>
                                )}
                                <InputWrapper label={t('new-password')}>
                                    <InputRequirementPopup
                                        title={t('password-requirements')}
                                        isVisible={
                                            values.password.length > 0 &&
                                            !!errors.password
                                        }
                                        list={RequirementsData.password}
                                        errors={prepareErrors(errors.password)}
                                        positionRight={
                                            windowWidth > 1300 ? -50 : -20
                                        }
                                    >
                                        <Input
                                            name="password"
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            className={styles.password}
                                            placeholder={t(
                                                'enter-new-password'
                                            )}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            haveError={
                                                !!(
                                                    errors.password &&
                                                    touched.password
                                                )
                                            }
                                        />
                                    </InputRequirementPopup>
                                    <Icon
                                        className={clsx(
                                            styles.eye,
                                            'password-eye'
                                        )}
                                        name={
                                            showPassword ? 'close-eye' : 'eye'
                                        }
                                        onClick={changeShowPassword.bind(
                                            null,
                                            !showPassword
                                        )}
                                    />
                                    {errors.password &&
                                        touched.password &&
                                        errors.password[0] !== 'uppercase' &&
                                        errors.password[0] !== 'lowercase' &&
                                        errors.password[0] !== 'min' &&
                                        errors.password[0] !== 'special' &&
                                        errors.password[0] !== 'number' && (
                                            <InputError>
                                                {errors.password[0]}
                                            </InputError>
                                        )}
                                </InputWrapper>
                                <InputWrapper label={t('repeat-password')}>
                                    <Input
                                        name="passwordRepeat"
                                        placeholder={t('password-once-more')}
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.passwordRepeat}
                                        haveError={
                                            !!(
                                                errors.passwordRepeat &&
                                                touched.passwordRepeat
                                            )
                                        }
                                    />
                                    {errors.passwordRepeat &&
                                        touched.passwordRepeat && (
                                            <InputError>
                                                {t(errors.passwordRepeat)}
                                            </InputError>
                                        )}
                                </InputWrapper>
                                <Button
                                    isDisabled={!isValid}
                                    size="big"
                                    className={styles.button}
                                    htmlType="submit"
                                >
                                    {t('confirm')}
                                </Button>
                                <div
                                    className={styles.login}
                                    onClick={() =>
                                        navigate(`/${i18n.language}#login`)
                                    }
                                >
                                    {t('to-login')}
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </Container>
        </div>
    );
};
