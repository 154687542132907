import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'shared/providers';

import { Container } from '../../grid';
import UspSectionItem from './USPSectionItem';

import styles from './USPSection.module.scss';

import { IntroductionMocks } from './USPSection.mocks';
import { ParseHelper } from 'shared/helpers';
import { CustomizationHomeType } from 'shared/types';

export const UspSection = () => {
    const { customization } = useContext(ThemeContext);
    const { i18n } = useTranslation();

    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationHomeType>(
                customization?.homepage?.settings,
                'usp.usp1.title',
                i18n.language,
            ),
        [customization, i18n.language],
    );

    if (content?.usp ? !content.usp.isActive : false) return null;

    return (
        <section className={clsx(styles.section, 'usp-section')}>
            <Container>
                <div className={clsx(styles['list'], 'usp-list')}>
                    <UspSectionItem index={1} data={content?.usp?.usp1} defaultData={IntroductionMocks.list[0]} />
                    <UspSectionItem index={2} data={content?.usp?.usp2} defaultData={IntroductionMocks.list[1]} />
                    <UspSectionItem index={3} data={content?.usp?.usp3} defaultData={IntroductionMocks.list[2]} />
                </div>
            </Container>
        </section>
    );
};
