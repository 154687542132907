import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import { Input } from '../Input/Input';

import styles from './Radio.module.scss';

export const Radio: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
    children,
    className,
    ...props
}) => (
    <div className={clsx(styles.root, className)}>
        <Input className={styles.input} {...props} />
        <div className={clsx(styles.radio, 'radio-button')} />
        <span
            className={clsx(
                styles['radio-label'],
                props.checked && styles.checked
            )}
        >
            {children}
        </span>
    </div>
);
