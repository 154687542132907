import clsx from 'clsx';
import { formatPrice } from 'api/helpers';
import React, { useRef, useMemo, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { Button, Icon, InvertedText, Typography } from 'shared/components/ui';
import { ParseHelper } from 'shared/helpers';
import { useNavigate } from 'shared/hooks';
import { ThemeContext } from 'shared/providers';
import { AuctionItemType, AuctionHeroImage, CustomizationHomeType, AuctionProductInfoItemType } from 'shared/types';
import styles from './HeroEstate.module.scss';

type HeroEstateProps = {
    data: AuctionItemType[] | null;
    heroImages?: AuctionHeroImage[] | null;
};

export const HeroEstate: React.FC<HeroEstateProps> = ({ data: heroAuctions, heroImages }) => {
    const slider: any = useRef();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { customization, setIsWhiteHeader } = useContext(ThemeContext);
    const [activeSlide, setActiveSlide] = useState(0);
    const [currentData, setCurrentData] = useState<AuctionProductInfoItemType | null>(null);

    useEffect(() => {
        setIsWhiteHeader(true);
        return () => setIsWhiteHeader(false);
    }, [setIsWhiteHeader]);

    const homeContent = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationHomeType>(
                customization?.homepage?.settings,
                'hero',
                i18n.language,
            ),
        [customization, i18n.language],
    );

    const data: any = heroAuctions?.length ? heroAuctions : heroImages;

    useEffect(() => {
        if (data) {
            const productContent = ParseHelper.prepareLanguageData<AuctionProductInfoItemType>(
                data[activeSlide]?.product?.product_info,
                'product_name',
                i18n.language,
            );
            setCurrentData(productContent);
        }
    }, [data, activeSlide, i18n.language]);

    if (!data || !data.length) return null;

    const isAuctionItem = data[0].product;
    const isOneAuction = isAuctionItem && data?.length === 1;

    const sliderSettings = {
        ref: slider,
        className: styles.slider,
        arrows: false,
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        beforeChange: (prev: any, next: any) => setActiveSlide(isOneAuction ? 0 : next),
    };

    const next = () => slider.current.slickNext();
    const prev = () => slider.current.slickPrev();
    const bid = data[activeSlide].winner
        ? data[activeSlide].all_bids !== 1
            ? data[activeSlide].highest_bid
            : data[activeSlide].minimal_winner_bid
        : data[activeSlide].offer_minimum;
    const showSliderControls = data.length > 1 || data[0].product?.images?.length > 1;

    return (
        <div className={styles.hero}>
            {isAuctionItem && (
                <div className={styles.heroText}>
                    <Typography.Title htmlElement="h3">{bid && formatPrice(bid)}</Typography.Title>
                    <InvertedText className={styles.invertedText} text={currentData?.product_name || ''} />
                    <div>
                        <Button
                            className={styles.heroButton}
                            onClick={() => navigate(`/${i18n.language}/auction/${data[activeSlide].id}`)}
                        >
                            {homeContent?.hero.CTAcopy || t('see-details-button')}
                        </Button>
                    </div>
                </div>
            )}

            <div className={clsx(styles.heroImage, { [styles.imageSlider]: !isAuctionItem })}>
                <div className={styles.backdrop} />
                <Slider {...sliderSettings}>
                    {isOneAuction
                        ? data[0].product.images.map((image: any) => (
                              <img key={`slide-${image.id}`} src={image.image} alt="Estate" />
                          ))
                        : data.map((item: any) => (
                              <img
                                  key={`slide-${item.id}`}
                                  src={isAuctionItem ? item.product.images[0].image : item.image}
                                  alt="Estate"
                              />
                          ))}
                </Slider>
                {showSliderControls && (
                    <div className={styles.actions}>
                        <div onClick={prev}>
                            <Icon name="arrow-left" />
                        </div>
                        <div onClick={next}>
                            <Icon name="arrow-right" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
