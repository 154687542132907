import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ParseHelper } from 'shared/helpers';

import { ThemeContext } from 'shared/providers';
import { OrderItemType } from 'shared/types';

import { Typography } from '../Typography/Typography';

import styles from './OrderItem.module.scss';

type OrderItemProps = {
    data: OrderItemType;
};

export const OrderItem: React.FC<OrderItemProps> = ({ data }) => {
    const { themeSettings } = useContext(ThemeContext);
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.root, 'order-root')}>
            <div className={clsx(styles.body, 'order-item')}>
                <div className={clsx(styles.status, 'order-label')}>
                    {data.payment ? t('paid') : t('unpaid')}
                </div>
                <div className={styles['image-wrapper']}>
                    <img
                        className={styles.image}
                        src={data.auction_detail.product.images[0].image}
                        alt=""
                    />
                </div>
                <div
                    className={styles.sold}
                    style={ParseHelper.prepareStyles({
                        fontFamily:
                            themeSettings.typography?.heading.fontFamily,
                        color: themeSettings.colors?.text.salePrive,
                    })}
                >
                    {t('sold-for')} CHF {data.amount}-
                </div>
                <Typography.Title htmlElement="h4">
                    {data.auction_detail.product.name}
                </Typography.Title>
                {/* <div
                    className={styles.invoice}
                    style={ParseHelper.prepareStyles({
                        fontFamily:
                            themeSettings.typography?.heading.fontFamily,
                        color: themeSettings.colors?.text.salePrive,
                    })}
                >
                    Download invoice
                </div> */}
            </div>
        </div>
    );
};
