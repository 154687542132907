import { CustomizationHomeUspItemType } from "shared/types"

const list:CustomizationHomeUspItemType[] = [
    {
        title: 'Awesome USP 1',
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
        icon: {
            id: '0',
            src: 'handbag'
        }
    },
    {
        title: 'Awesome USP 2',
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
        icon: {
            id: '0',
            src: 'wallet'
        }
    },
    {
        title: 'Awesome USP 3',
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
        icon: {
            id: '0',
            src: 'gift'
        }
    }
]

export const IntroductionMocks = {
    list
}