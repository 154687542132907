import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Typography } from 'shared/components/ui';
import { useModal } from 'shared/hooks';
import styles from './FirstTimeBid.module.scss';

export const FIRST_TIME_BID_MODAL_NAME = '#first-time-bid';

type FirstTimeBidModalProps = {
    onClose: () => void;
};

export const FirstTimeBidModal: React.FC<FirstTimeBidModalProps> = ({ onClose }) => {
    const { t } = useTranslation();
    const { isOpen, closeModal } = useModal(FIRST_TIME_BID_MODAL_NAME);

    const closingModal = () => {
        closeModal();
        onClose();
    };

    return (
        <Modal
            className={styles.firstTimeBid}
            isOpen={isOpen}
            onClose={closeModal.bind(null, FIRST_TIME_BID_MODAL_NAME)}
            maxWidth={660}
        >
            <Icon name="warning" />
            <Typography.Title htmlElement="h2" className={styles.title}>
                {t('first-time-bid-title')}
            </Typography.Title>
            <Typography.Text>{t('first-time-bid-body')}</Typography.Text>
            <Button onClick={() => closingModal()}>{t('first-time-bid-button')}</Button>
        </Modal>
    );
};
