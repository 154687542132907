import React, { useContext, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { AuctionItemType, ThemeCardsNumber } from 'shared/types';

import { Container } from 'shared/components/grid';
import { AuctionItem, SliderArrow, Typography } from 'shared/components/ui';

import { useDeviceDetector } from '../../../hooks';
import styles from './RelatedAuctions.module.scss';
import { ThemeContext } from 'shared/providers';
import { useParams } from 'react-router';

type RelatedAuctionsSectionProps = {
    data?: AuctionItemType[] | null;
};

const numberOfCards: ThemeCardsNumber = {
    traditional: 3,
    modern: 2,
    minimal: 3,
    funky: 2,
    classic: 2,
    estate: 2,
};

export const RelatedAuctionsSection: React.FC<RelatedAuctionsSectionProps> = ({
    data,
}) => {
    const { t } = useTranslation();
    const { themeSettings } = useContext(ThemeContext);
    const slider = useRef<Slider>(null);
    const { device } = useDeviceDetector();
    const [index, setIndex] = useState<number>(0);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        setIndex(0);
    }, [id]);

    if (!data) return null;

    const beforeChangeHandler = (prev: number, next: number) => {
        setIndex(next);
    };

    if (!data.length) return null;

    return (
        <section className={clsx(styles.section, 'related-auctions-section')}>
            <Container className={styles.root}>
                <div
                    className={clsx(
                        styles['title-wrap'],
                        'related-auctions-section__title-wrap'
                    )}
                >
                    <Typography.Title className={styles.title} htmlElement="h2">
                        {t('related-products')}
                    </Typography.Title>
                    {device !== 'mobile' && (
                        <div className={styles.arrows}>
                            <SliderArrow.Left
                                className={clsx(
                                    styles.arrow,
                                    index === 0 && styles.disabled
                                )}
                                size="big"
                                onClick={() => slider.current?.slickPrev()}
                            />
                            <SliderArrow.Right
                                className={clsx(
                                    styles.arrow,
                                    (index ===
                                        data.length -
                                            numberOfCards[
                                                themeSettings.template ||
                                                    'traditional'
                                            ] ||
                                        data.length <=
                                            numberOfCards[
                                                themeSettings.template ||
                                                    'traditional'
                                            ]) &&
                                        styles.disabled
                                )}
                                size="big"
                                onClick={() => slider.current?.slickNext()}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.slider}>
                    <Slider
                        key={id}
                        className="with-overflow"
                        ref={slider}
                        variableWidth
                        infinite={false}
                        arrows={false}
                        slidesToShow={
                            numberOfCards[
                                themeSettings.template || 'traditional'
                            ]
                        }
                        slidesToScroll={1}
                        lazyLoad="progressive"
                        speed={450}
                        dots={device === 'mobile'}
                        beforeChange={beforeChangeHandler}
                        rows={1}
                    >
                        {data.map((item) => (
                            <AuctionItem
                                className={styles.item}
                                key={item.id}
                                data={item}
                            />
                        ))}
                    </Slider>
                </div>
            </Container>
        </section>
    );
};
