const password = [
    {
        key: 'min',
        name: 'password-requirement-length'
    },
    {
        key: 'uppercase',
        name: 'password-requirement-uppercase'
    },
    {
        key: 'lowercase',
        name: 'password-requirement-lowercase'
    },
    {
        key: 'number',
        name: 'password-requirement-number'
    },
    {
        key: 'special',
        name: 'password-requirement-special'
    }
]

export const RequirementsData = {
    password
}