export type CustomizationImage = File | CustomizationImageType | null;

export type CustomizationConfigResponseType = {
    settings:CustomizationConfigType;
}

export type CustomizationConfigType = {
    homepage?: CustomizationPageType<CustomizationPageSettingsType<CustomizationHomeType>>;
    productDetailPage?: CustomizationPageType<CustomizationPageSettingsType<CustomizationProductDetailType>>;
    missedAuctions?: CustomizationPageType<CustomizationPageSettingsType<CustomizationMissedAuctionsType>>;
    shopModals?: CustomizationPageType<CustomizationPageSettingsType<CustomizationShopModalsType>>;
};

export type CustomizationPageType<T> = {
    isPublished:boolean;
    settings:T;
}

export type CustomizationImageType = {
    id:string;
    src:string;
}

export type CustomizationTextLinkType = {
    isActive:boolean;
    name:string;
    target:string;
}

export type CustomizationPageSettingsType<S> = {
    [lng in CompanyLanguages]?: S;
}

export enum CompanyLanguages {
    de = 'de',
    en = 'en',
    fr = 'fr',
    it = 'it',
}

export type CustomizationMissedAuctionsType = {
    general: {
        newsletterOptIn: boolean;
        title: string;
    }
}

export type CustomizationProductDetailType = {
    hero: CustomizationProductDetailHeroType;
    newsletterOptIn: {
        isActive:boolean;
    }
}

export type CustomizationProductDetailHeroType = {
    CTAcopy:string;
    conversationSticker: { isActive:boolean; }
    graph1:CustomizationProductDetailHeroGraphType;
    graph2:CustomizationProductDetailHeroGraphType
}

export type CustomizationProductDetailHeroGraphType = {
    color:string;
    title:string;
}

export type CustomizationHomeType = {
    hero: CustomizationHomeHeroType;
    newsletterOption: CustomizationHomeSubscriptionType;
    usp: CustomizationHomeUspType;
    textBlock: CustomizationHomeTextBlockType;
    productCards: CustomizationHomeCardsType;
    footer: CustomizationHomeFooterType;
}

export type CustomizationHomeHeroType = {
    CTAcopy:string;
    timerTitle:string;
    conversationSticker: {
        color:string;
        icon:CustomizationImage;
        active:boolean;
    }
}

export type CustomizationHomeSubscriptionType = {
    fieldPlaceholder:string;
    isActive:boolean;
    subTitle:string;
    title:string;
    icon: CustomizationImage;
}

export type CustomizationHomeUspItemType = {
    description:string;
    title:string;
    icon: CustomizationImage;
}

export type CustomizationHomeUspType = {
    isActive:boolean;
    usp1:CustomizationHomeUspItemType;
    usp2:CustomizationHomeUspItemType;
    usp3:CustomizationHomeUspItemType;
}

export type CustomizationHomeTextBlockType = {
    description:string;
    isActive:boolean;
    picture: CustomizationImage;
    renderMode: 'picture' | 'title';
    title: string;
    textLink: CustomizationTextLinkType;
}

export type CustomizationHomeCardsType = {
    CTAText:string;
    loadMoreLink:string;
    sectionTitle:string;
}

export type CustomizationHomeFooterType = {
    title:string;
    staticLinks:CustomizationHomeFooterStaticLinkType;
    footerLinks:CustomizationHomeFooterLinkType[];
    socialLinks:CustomizationHomeFooterSocialLinksType;
}

export type CustomizationHomeFooterLinkType = {
    name:string;
    url:string;
}

export type CustomizationHomeFooterStaticLinkType = {
    dataProtection:{
        name: string;
        url: string;
    };
    disclaimer:{
        name: string;
        url: string;
    };
    termsAndConditions:{
        name: string;
        url: string;
    };
}

export type CustomizationHomeFooterSocialLinksType = {
    facebook:CustomizationHomeFooterSocialLinkType;
    instagram: CustomizationHomeFooterSocialLinkType;
    linkedin: CustomizationHomeFooterSocialLinkType;
    snapchat: CustomizationHomeFooterSocialLinkType;
    tiktok: CustomizationHomeFooterSocialLinkType;
    twitter: CustomizationHomeFooterSocialLinkType;
}

export type CustomizationHomeFooterSocialLinkType = {
    isActive:boolean;
    url:string;
}

export type CustomizationShopModalsType = {
    login1:CustomizationLoginShopModalType;
    login2:CustomizationLoginShopModalType;
    offerConfirmation:CustomizationOfferConfirmationShopModalType;
    offerDenial:CustomizationOfferDenialShopModalType;
    offerWon:CustomizationOfferWonShopModalType;
    register:CustomizationRegisterShopModalType;
    resetPassword:CustomizationResetPasswordShopModalType;
}

export type CustomizationLoginShopModalType = {
    button: string;
    subtitle: string;
    title: string;
}

export type CustomizationOfferConfirmationShopModalType = {
    bindingNotification: string;
    button: string;
    confirmButtonIcon: CustomizationImage;
    subtitle:string;
    title:string;
}

export type CustomizationOfferDenialShopModalType = {
    denyButtonIcon: CustomizationImage;
    button: string;
    subtitle:string;
    title:string;
}

export type CustomizationOfferWonShopModalType = {
    winButtonIcon: CustomizationImage;
    button: string;
    subtitle:string;
    title:string;
}

export type CustomizationRegisterShopModalType = {
    button: string;
    subtitle: string;
    title: string;
}

export type CustomizationResetPasswordShopModalType = {
    button: string;
    title: string;
}