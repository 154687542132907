import React, { useContext } from 'react';
import clsx from "clsx";
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'shared/providers';

import {AuctionLabel} from "../../../ui";
import {AuctionDiscountEnum, AuctionItemType, AuctionTypeEnum, CompanyLanguages} from "../../../../types";

import styles from "../Hero.module.scss";

type ImgBoxProps = {
    data:AuctionItemType | null;
    isEnded:boolean;
}

const ImgBox:React.FC<ImgBoxProps> = ({data, isEnded}) => {
    const { customization } = useContext(ThemeContext);
    const { t, i18n } = useTranslation();

    if(!data) return null;

    return (
        <div className={clsx(styles['img-box'], 'hero-section__img-box')}>
            {!isEnded && customization?.homepage?.settings[i18n.language as CompanyLanguages]?.hero.conversationSticker.active && (
                <AuctionLabel.Hero className={styles.label} hideIcon={data.type.value === AuctionTypeEnum.BuyNow}>
                    {data.type.value === AuctionTypeEnum.BuyNow && data.discount && (
                        <>
                            <span className={styles.discount}>-{data.discount.value} {data.discount.type === AuctionDiscountEnum.PERCENTAGE ? '%' : 'CHF'}</span>
                            {t('discount').toUpperCase()}
                        </>
                    )}
                    {data.type.value === AuctionTypeEnum.RegularAuction && (
                        <>
                            {t('the-highest')}<br />{t('offer-wins')}
                        </>
                    )}
                </AuctionLabel.Hero>
            )}
            <img src={data.product.images[0].image} alt="" />
        </div>
    );
};

export default ImgBox;