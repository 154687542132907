import React from 'react';
import clsx from 'clsx';

import styles from './InputWrapper.module.scss';
import InfoIcon from 'assets/images/icons/info-icon.svg';

type InputWrapperProps = {
    className?: string;
    label?: string;
    htmlElement?: 'label' | 'div';
    subtitle?: React.ReactNode;
};

export const InputWrapper: React.FC<InputWrapperProps> = ({
    className,
    label,
    subtitle,
    htmlElement = 'label',
    children,
}) =>
    React.createElement(
        htmlElement,
        { className: clsx(styles.root, className) },
        <>
            {label && (
                <div className={clsx(styles.label, 'input-label')}>
                    {label}
                    {subtitle && (
                        <>
                            <img
                                className={styles.icon}
                                height={14}
                                width={14}
                                src={InfoIcon}
                                alt="info icon"
                            />
                            <span className={styles.subtitle}>{subtitle}</span>
                        </>
                    )}
                </div>
            )}

            {children}
        </>
    );
