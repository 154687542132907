import {
    AccountSettingsServerType,
    AccountSettingsType,
    ChangePasswordType,
    ConfirmEmailType,
    LoginResponseType,
    LoginType,
    RegistrationType,
    ResponseDataType,
} from 'shared/types';

import { AuthMapper } from 'api/mappers';

import { http } from '../http';

const registration = async (data: RegistrationType): Promise<ResponseDataType<null>> => {
    const values = AuthMapper.toServerRegistrationModel(data);
    return http.post('/auth/signup/', values, true);
};

const login = async (data: LoginType): Promise<ResponseDataType<LoginResponseType>> => {
    return http.post<LoginResponseType>('/auth/signin/', data, true);
};

const resend = async (email: string): Promise<ResponseDataType<null>> => {
    return http.post('/auth/user-activation/resend/', { email }, true);
};

const confirmEmail = async (data: ConfirmEmailType): Promise<ResponseDataType<null>> => {
    return http.post('/auth/user-activation/', data, true);
};

const resetPassword = async (email: string): Promise<ResponseDataType<null>> => {
    return http.post('/auth/password/reset-confirmation/', { email }, true);
};

const changePassword = async (data: ChangePasswordType): Promise<ResponseDataType<null>> => {
    const values = AuthMapper.toServerChangePasswordModel(data);
    return http.post('/auth/password/reset/', values, true);
};

const saveAccountSettings = async (data: AccountSettingsType): Promise<ResponseDataType<AccountSettingsServerType>> => {
    const values = AuthMapper.toServerAccountSettingsModel(data);
    return http.post('/users/customer_account/create/', values);
};

const getAccountSettings = async (): Promise<ResponseDataType<AccountSettingsType>> => {
    const result = await http.get<AccountSettingsServerType[]>('/users/customer_account/list/');

    return AuthMapper.toClientAccountSettingsModel(result);
};

const updateAccountSettings = async (
    data: AccountSettingsType,
    userId: string,
): Promise<ResponseDataType<AccountSettingsServerType>> => {
    const values = AuthMapper.toServerAccountSettingsModel(data);
    return http.put(`/users/customer_account/${userId}/`, values);
};

export const createAuthService = () => ({
    registration,
    login,
    resend,
    confirmEmail,
    resetPassword,
    changePassword,
    saveAccountSettings,
    getAccountSettings,
    updateAccountSettings,
});
