import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Input, InputWrapper, Modal, Typography } from 'shared/components/ui';
import { toast } from 'react-toastify';

import styles from './BidsHistoryPassword.module.scss';

type BidsHistoryModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    onSubmit: (secret: string) => Promise<void>;
};
export const BidsHistoryPasswordModal: React.FC<BidsHistoryModalProps> = ({ isOpen, closeModal, onSubmit }) => {
    const { t } = useTranslation();

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Modal className={styles.bidsHistoryPasswordModal} isOpen={isOpen} onClose={closeModal} maxWidth={600}>
            <Typography.Title htmlElement="h3" className={styles.title}>
                {t('bid-history-password-title')}
            </Typography.Title>

            <InputWrapper label={t('password')}>
                <Input
                    id="bid-history-password"
                    name="bid-history-password"
                    autoComplete="new-bid-history-password"
                    className={styles.input}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('password-placeholder')}
                    onChange={e => {
                        setPassword(e.target.value);
                    }}
                />
                <Icon
                    className={styles.passwordEyeIcon}
                    name={showPassword ? 'close-eye' : 'eye'}
                    onClick={() => setShowPassword(value => !value)}
                />
            </InputWrapper>

            <div className={styles.buttons}>
                <Button className={styles.cancel} onClick={closeModal}>
                    {t('cancel')}
                </Button>
                <Button
                    htmlType="button"
                    onClick={async () => {
                        try {
                            setIsLoading(true);
                            await onSubmit(password);
                            closeModal();
                        } catch (err) {
                            toast(t('bid-history-password-error'), { type: 'error' });
                        } finally {
                            setIsLoading(false);
                        }
                    }}
                    isDisabled={!password}
                    isLoading={isLoading}
                >
                    {t('submit')}
                </Button>
            </div>
        </Modal>
    );
};
