const BLOCK_SCROLL_CLASS_NAME = 'block-scroll';

const lock = () => {
    document.body.classList.add(BLOCK_SCROLL_CLASS_NAME);
}

const unlock = () => {
    document.body.classList.remove(BLOCK_SCROLL_CLASS_NAME);
}

export const ScrollHelper = {
    lock,
    unlock
}