import { IconsId } from 'shared/types/icons';

const social: IconsId[] = ['instagram', 'facebook', 'twitter', 'tiktok' /* 'linkedin', 'snapchat' */];

const payments: IconsId[] = ['visa', 'mastercard', 'amex', 'apple', 'android'];

const menu = [
    {
        name: 'See our full sortiment',
        url: '#',
    },
    {
        name: 'Our shop',
        url: '#',
    },
    {
        name: 'About us',
        url: '#',
    },
];

export const FooterMocks = {
    menu,
    social,
    payments,
};
