import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Icon, Typography } from '../../../ui';
import { ThemeContext } from 'shared/providers';
import { CompanyLanguages } from 'shared/types';
import { ParseHelper } from 'shared/helpers';

import styles from '../Subscription.module.scss';

const ContentBox = () => {
    const { customization } = useContext(ThemeContext);
    const { i18n } = useTranslation();
    const [picture, setPicture] = useState<string | null>(null);

    const data = useMemo(() => {
        if (
            customization &&
            customization.homepage &&
            customization.homepage.settings[i18n.language as CompanyLanguages]
        ) {
            return customization.homepage?.settings[
                i18n.language as CompanyLanguages
            ]?.newsletterOption;
        }

        return undefined;
    }, [customization, i18n.language]);

    useEffect(() => {
        (async () => {
            if (data) {
                const imageData = data.icon;
                if (imageData)
                    setPicture(await ParseHelper.perpareImageData(imageData));
            }
        })();
    }, [data]);

    return (
        <div className={clsx(styles['text-wrapper'], 'text-wrapper')}>
            <div className="icon-box">
                {data && picture && <img src={picture} alt="" />}
                {!data && <Icon name="megaphone-traditional" />}
            </div>

            <div className={clsx(styles['text-box'], 'text-box')}>
                <Typography.Title htmlElement="h4">
                    {data?.title || 'Keep in touch'}
                </Typography.Title>
                <Typography.Text>
                    {data?.subTitle ||
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
                </Typography.Text>
            </div>
        </div>
    );
};

export default ContentBox;
