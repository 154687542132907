import React from 'react';
import ReactDOM from 'react-dom';

import { DetectorHelper } from 'shared/helpers';

import './index.scss';

import reportWebVitals from './reportWebVitals';

if (DetectorHelper.isPreview()) {
    import('PreviewPreboot').then(module => bootApp(module.PreviewPreboot));
} else {
    import('StoreFrontPreboot').then(module => bootApp(module.StoreFrontPreboot));
}

const bootApp = (App: React.FC) => {
    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root'),
    );

    reportWebVitals();
};
