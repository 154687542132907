import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useDeviceDetector } from 'shared/hooks';

import { AuthHelper, ParseHelper } from 'shared/helpers';

import { AuthContext, ThemeContext } from 'shared/providers';

import { DropdownTriangle } from '../DropdownTriangle/DropdownTriangle';
import { Icon } from '../Icon/Icon';

import avatar from 'assets/images/avatar.png';

import styles from './UserHeaderPanel.module.scss';

type UserHeaderPanelProps = {
    showIcons?: boolean;
};

export const UserHeaderPanel: React.FC<UserHeaderPanelProps> = ({ showIcons = true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { themeSettings } = useContext(ThemeContext);
    const { changeAuthorization, settings } = useContext(AuthContext);
    const { t, i18n } = useTranslation();
    const { device } = useDeviceDetector();
    const wrapper: any = useRef(null);

    const signOut = () => {
        AuthHelper.removeAuthToken();
        changeAuthorization(false);
    };

    const onClickLink = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.stopPropagation();
        setIsOpen(false);
    };

    // Close dropdown on click outside
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (wrapper.current && !wrapper.current.contains(event.target)) setIsOpen(false);
        }
        if (device === 'mobile') document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [wrapper, device]);

    // TODO: refactor and make separate components
    return (
        <div
            {...(device !== 'mobile' && {
                onMouseEnter: setIsOpen.bind(null, true),
                onMouseLeave: setIsOpen.bind(null, false),
            })}
            {...(device === 'mobile' && {
                onClick: setIsOpen.bind(null, !isOpen),
            })}
            className={clsx(styles.root, { [styles['is-open']]: isOpen })}
        >
            {themeSettings.template === 'minimal' || 'estate' ? (
                settings?.firstName ? (
                    <div className={clsx(styles.initials, 'avatar-initials')}>
                        {ParseHelper.getInitials(settings.firstName, settings.lastName)}
                    </div>
                ) : (
                    <img className={styles.avatar} src={avatar} alt="User avatar" />
                )
            ) : (
                <img className={styles.avatar} src={avatar} alt="User avatar" />
            )}
            {themeSettings.template === 'estate' && device !== 'mobile' && (
                <div className={styles.name}>
                    {`${settings?.firstName} ${settings?.lastName}`} <Icon name="dropdown-arrow" />
                </div>
            )}

            <div className={clsx(styles.dropdown, 'user-header-dropdown')} ref={wrapper}>
                <DropdownTriangle
                    className={styles.triangle}
                    {...(themeSettings.colors && {
                        bgColor: themeSettings.colors.background.primary,
                    })}
                />
                <ul>
                    <li>
                        <Link to={`/${i18n.language}/settings`} onClick={onClickLink}>
                            {showIcons && <Icon name="settings" />}
                            {t('settings')}
                        </Link>
                    </li>
                    {themeSettings.template !== 'estate' && (
                        <li>
                            <Link to={`/${i18n.language}/orders`} onClick={onClickLink}>
                                {showIcons && <Icon name="orders" />}
                                {t('my-offers')}
                            </Link>
                        </li>
                    )}
                    {themeSettings.template === 'estate' && (
                        <>
                            <li>
                                <Link to={`/${i18n.language}/my-bids`} onClick={onClickLink}>
                                    {t('my-bids')}
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${i18n.language}/financial-proofs`} onClick={onClickLink}>
                                    {t('financial-proofs')}
                                </Link>
                            </li>
                        </>
                    )}
                    <li className={styles.separator} />
                    <li onClick={signOut}>
                        {showIcons && <Icon name="sign-out" />}
                        {t('sign-out')}
                    </li>
                </ul>
            </div>
        </div>
    );
};
