import { createContext, useEffect, useState } from 'react';
import { LoaderHelper } from 'shared/helpers';

import { CustomizationConfigType, ThemeSettingsType } from 'shared/types';

export const initialThemeConfigs: ThemeSettingsType = {
    template: '',
    colors: null,
    favicon: null,
    logo: null,
    typography: null,
};

type ThemeContextType = {
    themeSettings: ThemeSettingsType;
    customization: CustomizationConfigType | null;
    setThemeSettings: (data: ThemeSettingsType) => void;
    setCustomization: (data: CustomizationConfigType) => void;
    setIsWhiteHeader: (data: boolean) => void;
    isWhiteHeader: boolean;
};

const addThemeVariables = (data: any) => {
    const result: [] = [];
    const returnObjKey = (data: any, prev: string = '') => {
        Object.keys(data).forEach(item => {
            const currentItem = data[item];
            const variableName = prev ? `${prev}-${item}` : item;
            if (typeof currentItem === 'object') {
                returnObjKey(currentItem, variableName);
            } else {
                // @ts-ignore
                result.push(`--${variableName}: ${currentItem}`);
            }
        });
        return result;
    };
    return returnObjKey(data);
};

export const ThemeContext = createContext<ThemeContextType>({
    themeSettings: initialThemeConfigs,
    customization: null,
    setThemeSettings: (data: ThemeSettingsType) => {},
    setCustomization: (data: CustomizationConfigType) => {},
    setIsWhiteHeader: (data: boolean) => {},
    isWhiteHeader: false,
});

export const ThemeProvider: React.FC = ({ children }) => {
    const [theme, setData] = useState<ThemeSettingsType>(initialThemeConfigs);
    const [customization, setCustomization] = useState<CustomizationConfigType | null>(null);
    const [isWhiteHeader, setIsWhiteHeader] = useState(false);

    useEffect(() => {
        if (theme.typography) {
            LoaderHelper.fonts([
                `${theme.typography.heading.fontFamily}:400,700`,
                `${theme.typography.paragraphBody.fontFamily}:400,700`,
            ]);
        }

        if (theme.colors && theme.typography) {
            const themeVariables = addThemeVariables({ color: theme.colors, typography: theme.typography });
            const styles = document.getElementById('root-variables');
            const stylesContent = `html:root{${themeVariables.join(';')}}`;
            if (styles) {
                styles.innerHTML = stylesContent;
            } else {
                document.head.insertAdjacentHTML('afterbegin', `<style id="root-variables">${stylesContent}</style>`);
            }
        }
    }, [theme]);

    return (
        <ThemeContext.Provider
            value={{ themeSettings: theme, setThemeSettings: setData, setCustomization, customization, setIsWhiteHeader, isWhiteHeader }}
        >
            {children}
        </ThemeContext.Provider>
    );
};
