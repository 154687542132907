import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Typography } from 'shared/components/ui';
import { useModal } from 'shared/hooks';
import { IconsId } from 'shared/types/icons';
import styles from './InfoModal.module.scss';

type InfoModalProps = {
    icon?: IconsId;
    color?: string;
    title?: string;
    text?: string;
    onConfirm?: () => void;
    onClose?: () => void;
};

export const INFO_MODAL_NAME = '#info-modal';

export const InfoModal: React.FC<InfoModalProps> = ({ icon, color, title, text, onConfirm, onClose }) => {
    const { t } = useTranslation();
    const { isOpen, closeModal } = useModal(INFO_MODAL_NAME);

    // If no text and no title, then don't show the modal
    // This is to avoid showing modal by forcing it open by typing it's hash in url
    useEffect(() => {
        if (isOpen && !text && !title) closeModal();
    }, [isOpen, closeModal, text, title]);

    const confirm = () => {
        if (onConfirm) onConfirm();
        closeModal();
    };

    return (
        <Modal
            className={styles.infoModal}
            isOpen={isOpen}
            onClose={() => {
                closeModal();
                if (onClose) onClose();
            }}
            maxWidth={660}
            closeable={false}
        >
            {icon && <Icon name={icon} style={{ color: color }} />}
            {title && (
                <Typography.Title htmlElement="h3" className={styles.title}>
                    {title}
                </Typography.Title>
            )}
            {text && <Typography.Text>{text}</Typography.Text>}
            <div className={styles.actions}>
                <Button
                    type="black"
                    className={styles.outlineButton}
                    onClick={() => {
                        closeModal();
                        if (onClose) onClose();
                    }}
                >
                    {t('bid-cancel')}
                </Button>
                <Button onClick={() => confirm()}>{t('bid-confirm')}</Button>
            </div>
        </Modal>
    );
};
