import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ThemeContext } from 'shared/providers';

import { LocalStorageHelper, ParseHelper } from 'shared/helpers';

import { useModal, useNavigate } from 'shared/hooks';

import { CustomizationShopModalsType } from 'shared/types';

import { Button, Icon, Modal, Typography } from 'shared/components/ui';

import styles from './BidStatus.module.scss';
import { formatPrice } from 'api/helpers';

export const BID_LOSING_MODAL_NAME = '#bid-losing';

const Losing = () => {
    const navigate = useNavigate();
    const { isOpen, closeModal } = useModal(BID_LOSING_MODAL_NAME);

    const [icon, setIcon] = useState<string>('');

    const { t, i18n } = useTranslation();
    const { customization, themeSettings } = useContext(ThemeContext);

    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationShopModalsType>(
                customization?.shopModals?.settings,
                'offerDenial.title',
                i18n.language,
            ),
        [customization, i18n.language],
    );

    useEffect(() => {
        (async () => {
            if (content) {
                const imageData = content.offerDenial.denyButtonIcon;
                if (imageData) setIcon(await ParseHelper.perpareImageData(imageData));
            }
        })();
    }, [content]);

    return (
        <Modal isOpen={isOpen} onClose={closeModal.bind(null, BID_LOSING_MODAL_NAME)} maxWidth={700}>
            <div className={clsx(styles.root, 'modal-losing')}>
                {icon && <img src={icon} alt="" />}
                {!icon && <Icon name="losing" className={styles.icon} />}
                <Typography.Title className={styles.title} htmlElement="h2">
                    {content?.offerDenial?.title || t('offer-not-accepted')}
                </Typography.Title>
                <Typography.Text
                    className={styles.text}
                    wysiwyg={
                        content?.offerDenial?.subtitle || t('your-offer-was-denied')
                    }
                />
                <div className="modal-losing-buttons">
                    <Button className={styles.button} size="big" onClick={closeModal}>
                        {content?.offerDenial?.button || t('offer-more')}
                    </Button>
                    <Button
                        className={clsx(
                            styles.button,
                            styles.cancel,
                            themeSettings.template === 'minimal' && styles.transparent,
                        )}
                        size="big"
                        type="black"
                        onClick={() => navigate('/')}
                    >
                        {t('cancel')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export const BID_WINNING_MODAL_NAME = '#bid-winning';

const Winning = () => {
    const { isOpen, closeModal } = useModal(BID_WINNING_MODAL_NAME);
    const [icon, setIcon] = useState<string>('');

    const { i18n } = useTranslation();
    const { customization } = useContext(ThemeContext);

    const { t } = useTranslation();

    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationShopModalsType>(
                customization?.shopModals?.settings,
                'offerWon.title',
                i18n.language,
            ),
        [customization, i18n.language],
    );

    const price = LocalStorageHelper.getModalData();

    useEffect(() => {
        if (!price && isOpen) closeModal();
    }, [price, isOpen, closeModal]);

    useEffect(() => {
        (async () => {
            if (content) {
                const imageData = content.offerWon.winButtonIcon;
                if (imageData) setIcon(await ParseHelper.perpareImageData(imageData));
            }
        })();
    }, [content]);

    return (
        <Modal closeable={false} isOpen={isOpen} onClose={closeModal.bind(null, BID_LOSING_MODAL_NAME)} maxWidth={700}>
            <div className={clsx(styles.root, 'modal-winning')}>
                {icon && <img src={icon} alt="icon" />}
                {!icon && <Icon name="winning" className={styles.icon} />}
                <Typography.Title className={styles.title} htmlElement="h2">
                    {content?.offerWon?.title || t('bid-status-title')}
                </Typography.Title>
                <Typography.Text
                    className={styles.text}
                    wysiwyg={(content?.offerWon?.subtitle || t('bid-status-body') || '').replace(
                        '{price}',
                        formatPrice(price) || '400',
                    )}
                />
                <Button className={styles.button} size="big" onClick={closeModal}>
                    {content?.offerWon?.button || t('bid-status-button')}
                </Button>
            </div>
        </Modal>
    );
};

export const BidStatusModal = {
    Losing,
    Winning,
};
