const MODAL_DATA_STORE_NAME = "modal-data";

const saveModalData = (data?:any) => {
    localStorage.setItem(MODAL_DATA_STORE_NAME, JSON.stringify(data));
}

const cleanModalData = () => {
    localStorage.removeItem(MODAL_DATA_STORE_NAME);
}

const getModalData = ():any => {
    const result = localStorage.getItem(MODAL_DATA_STORE_NAME);
    if(result !== null) return JSON.parse(result);
    return null;
}

export const LocalStorageHelper = {
    saveModalData,
    cleanModalData,
    getModalData
}