import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { api } from 'api';

import { AuthContext, ToastContext } from 'shared/providers';

import { AuthHelper } from 'shared/helpers';
import { useNavigate } from 'shared/hooks';
import { LOGIN_MODAL_NAME } from 'shared/components/modals';

import { Button, Preloader, Typography } from 'shared/components/ui';

import illustration from 'assets/images/mackbook.svg';

import styles from './ConfirmEmail.module.scss';

export const ConfirmEmailSection = () => {
    const { open } = useContext(ToastContext);
    const navigate = useNavigate();
    const resendEmail = useRef<string | null>(null);
    const { token, uid } = useParams<{ token?: string; uid?: string }>();
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { changeAuthorization } = useContext(AuthContext);
    const [resendSuccess, changeResendSuccess] = useState(false);
    const [resendingBlocked, setResendingBlocked] = useState(false);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [time, setTime] = useState(20);

    const activate = useCallback(async () => {
        const { status, error } = await api.authService.confirmEmail({
            token: token || '',
            uid: uid || '',
        });

        if (status === 400 && error && error.detail && error.detail[0] !== null) {
            resendEmail.current = error.detail[0].split('link to ').pop() || null;
        }

        if (status === 200) {
            const authToken = AuthHelper.getAuthToken();
            if (authToken) {
                AuthHelper.removeAuthToken();
                changeAuthorization(false);
            }

            navigate(`/${i18n.language}${LOGIN_MODAL_NAME}`);
        }

        if (status === 500) {
            setResendingBlocked(true);
        }

        setIsLoading(false);
    }, [token, uid, i18n.language, navigate, changeAuthorization]);

    const startTimer = () => {
        let sec = 20;
        timer.current = setInterval(() => {
            setTime(sec);
            sec--;
            if (sec < 0 && timer.current !== null) {
                clearInterval(timer.current);
                changeResendSuccess(() => false);
            }
        }, 1000);
    };

    const onResend = async () => {
        if (resendEmail.current !== null) {
            const { status } = await api.authService.resend(resendEmail.current);

            if (status === 200) {
                changeResendSuccess(true);
                startTimer();
                open('success');
            }
        }
    };

    useEffect(() => {
        activate();
    }, [activate]);

    if (isLoading) return <Preloader.Page />;

    return (
        <div className={styles.root}>
            <div className={styles['image-wrapper']}>
                <img src={illustration} alt="" />
                <Typography.Text className={styles.text} wysiwyg={t('confirm-email-text')} />
            </div>
            {!resendingBlocked && (
                <>
                    <div className={styles['resend-wrapper']}>
                        {!resendSuccess && (
                            <Button className={styles.resend} type="primary" onClick={onResend}>
                                {t('resend-activation')}
                            </Button>
                        )}
                        {resendSuccess && (
                            <div className={styles['resend-text']}>
                                {t('no-link')}
                                {` ${t('resend-in')} ${time} ${t('sec')}.`}
                            </div>
                        )}
                    </div>
                    <div className={styles.or}>{t('or')}</div>
                </>
            )}
            <Button className={styles.button} type="black" onClick={() => navigate('/')}>
                {t('to-home')}
            </Button>
        </div>
    );
};
