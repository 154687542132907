import React, { useContext } from 'react';
import clsx from 'clsx';

import styles from './Modal.module.scss';
import { ThemeContext } from 'shared/providers';

type OverlayProps = {
    isVisible: boolean;
};

const Overlay: React.FC<OverlayProps> = ({ children, isVisible }) => {
    const { themeSettings } = useContext(ThemeContext);

    return (
        <div
            className={clsx(
                styles.overlay,
                { [styles['is-visible']]: isVisible },
                { [styles[`is-${themeSettings.template}`]]: themeSettings.template },
            )}
        >
            {children}
        </div>
    );
};

type ModalCloseIconProps = {
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const ModalCloseIcon: React.FC<ModalCloseIconProps> = ({ onClick }) => (
    <div className={styles.close} onClick={onClick} />
);

type ModalProps = {
    className?: string;
    isOpen: boolean;
    maxWidth: number;
    closeable?: boolean;
    onClose: () => void;
};

export const Modal: React.FC<ModalProps> = ({ className, isOpen, maxWidth, onClose, closeable = true, children }) => {
    if (!isOpen) return null;

    return (
        <Overlay isVisible={isOpen}>
            <div
                className={clsx(styles.modal, 'modal', className, { [styles['is-open']]: isOpen })}
                style={{ maxWidth }}
            >
                {closeable && <ModalCloseIcon onClick={onClose} />}
                {children}
            </div>
        </Overlay>
    );
};
