import { formatPrice } from 'api/helpers';
import React from 'react';
import dayjs from 'dayjs';
import styles from './EstateHistoryTable.module.scss';
import { AuctionHistoryItemType } from 'shared/types';
import { useTranslation } from 'react-i18next';
import { UserName } from './UserName';

// Extend dayjs to use "from now" option
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
// Declare types fro dayjs to include "from now" option
declare module 'dayjs' {
    interface Dayjs {
        fromNow(withoutSuffix?: boolean): string;
    }
}
require('dayjs/locale/de');
require('dayjs/locale/fr');
require('dayjs/locale/it');

type EstateHistoryTableType = {
    bids: AuctionHistoryItemType[];
};

export const EstateHistoryTable: React.FC<EstateHistoryTableType> = ({ bids }) => {
    const { i18n, t } = useTranslation();

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>{t('history-table-user')}</th>
                    <th>{t('history-table-bid')}</th>
                    <th>{t('history-table-raise')}</th>
                    <th>{t('history-table-date')}</th>
                </tr>
            </thead>
            <tbody>
                {bids.map(bid => (
                    <tr key={bid.id}>
                        <td>
                            <UserName user={bid.user} userCoverName={bid.user_cover_name} />
                        </td>
                        <td>{formatPrice(bid.amount)}</td>
                        <td>+ {formatPrice(bid.bid_raise)}</td>
                        <td>
                            {dayjs(bid.created_at)
                                .locale(i18n.language || 'en')
                                .fromNow()}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
