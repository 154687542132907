import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Container } from 'shared/components/grid';
import { AuctionItem, Pagination, Typography } from 'shared/components/ui';

import { ThemeContext } from 'shared/providers';

import {
    AuctionItemType,
    CustomizationMissedAuctionsType,
    PaginationApiType,
    ResponseDataType,
} from 'shared/types';

import { ParseHelper } from 'shared/helpers';

import styles from './MissedAuctions.module.scss';

type MissedAuctionsSectionProps = {
    auctions: ResponseDataType<PaginationApiType<AuctionItemType>>;
    onPageChange: (page: number) => void;
};

export const MissedAuctionsSection: React.FC<MissedAuctionsSectionProps> = ({
    auctions,
    onPageChange,
}) => {
    const { customization } = useContext(ThemeContext);
    const { i18n, t } = useTranslation();

    const content = useMemo(
        () =>
            ParseHelper.prepareLanguageData<CustomizationMissedAuctionsType>(
                customization?.missedAuctions?.settings,
                'general.title',
                i18n.language
            ),
        [customization, i18n.language]
    );

    return (
        <section className={clsx(styles.section, 'section-missed-auctions')}>
            <Container className={styles.container}>
                <Typography.Title htmlElement="h2" className={styles.mainTitle}>
                    {content?.general.title || 'Missed auctions'}
                </Typography.Title>
                <div
                    className={clsx(
                        styles.list,
                        'section-missed-auctions__list'
                    )}
                >
                    {auctions.data?.results &&
                    auctions.data?.results?.length > 0 ? (
                        auctions.data?.results.map((item) => (
                            <AuctionItem data={item} key={item.id} />
                        ))
                    ) : (
                        <div className={styles.label}>{t('no-auctions')}</div>
                    )}
                </div>
                <Pagination
                    className={styles.pagination}
                    totalCount={auctions.data?.count || 0}
                    pageSize={6}
                    onPageChange={onPageChange}
                />
            </Container>
        </section>
    );
};
